import React, { useEffect, useState } from "react";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import HelpSteps from "../../../Components/HelpSteps";
import StudentStories from "../../../Components/StudentStories";
import HelpService from "../../../Components/HelpService";
import AdvantagesOfQuestions from "../../../Components/AdvantagesOfQuestions";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import BonusSection from "../../../Components/BonusSection";
import MatchTheFollowingImg from "../../../Assets/images/background/match-the-following.svg";
import Button from "../../../Components/Buttons/Button";
// import { getAllSubject } from "../../../services/getAllSubject";
// import { getSubcategoryWisePrice } from "../../../services/getSubcategoryWisePrice";
// import { getCategoryWiseSubcategory } from "../../../services/getCategoryWiseSubcategory";
// import { useNavigate } from "react-router";
// import { createQuestionHomeworkHelp } from "../../../services/createQuestionHomeworkHelp";
// import { updateHomeworkHelpQuestion } from "../../../services/updateHomeworkHelpQuestion";
// import Popup from "../../../Components/Popup/Popup";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";

const MatchTheFollowing = () => {
  const subCatagoryID = "65565744b9cbef8acade1d70";
  const [isModelOpen, setIsModelOpen] = useState(false);
  // const [payload, setPayload] = useState({});
  // const [formData, setFormData] = useState({});
  // const navigate = useNavigate();
  // const [isQuestionSubmited, setIsQuestionSubmited] = useState(false);
  // const [error, setError] = useState({});
  // const [isError, setIsError] = useState(false);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
    // setIsQuestionSubmited(false);
    // setPayload({});
  };
  const totalSteps = 3;

  // const fetchHomeworkCategory = async () => {
  //   const response = await getCategoryWiseSubcategory(
  //     "65524e5e3c005ef3b51907c6"
  //   );
  //   const activeSubcategory = response.subCategory?.filter((x) => x.isActive);
  //   const tempData = activeSubcategory?.map((x) => ({
  //     _id: x?._id,
  //     name: x?.name,
  //   }));
  //   setFormData((prevstate) => ({ ...prevstate, subcategory: tempData }));
  //   return response;
  // };

  // const fetchSubjectData = async () => {
  //   const response = await getAllSubject();
  //   setFormData((prevstate) => ({ ...prevstate, subject: response.data }));
  // };

  // const fetchQuestionPricingData = async (id) => {
  //   const response = await getSubcategoryWisePrice(id);
  //   setPayload((prevstate) => ({
  //     ...prevstate,
  //     price: response?.price?.studentPrice,
  //   }));
  // };

  // const handleChange = (event) => {
  //   setPayload((prevstate) => ({
  //     ...prevstate,
  //     [event.target.name]: event.target.value,
  //   }));
  //   if (event.target.value) {
  //     setIsError(false);
  //     setError((prevstate) => ({ ...prevstate, [event.target.name]: "" }));
  //   }
  // };

  // const handlePostQuestion = async () => {
  //   const response = await createQuestionHomeworkHelp(payload);

  //   if (response.isSuccess) {
  //     if (localStorage.getItem("token")) {
  //       const updatedQuestion = await updateHomeworkHelpQuestion(
  //         response.question._id,
  //         {
  //           discountPrice: 0,
  //           promoPrice: 0,
  //           promoCode: "",
  //         }
  //       );
  //       if (updatedQuestion.isSuccess) {
  //         localStorage.removeItem("questionId");
  //         navigate(`/orderStatus/${response.question._id}`);
  //       }
  //     }
  //     setIsQuestionSubmited(true);
  //     localStorage.setItem("questionId", response.question._id);
  //   }
  // };

  // useEffect(() => {
  //   fetchHomeworkCategory();
  //   fetchSubjectData();
  // }, []);

  // useEffect(() => {
  //   if (payload.questionType) {
  //     fetchQuestionPricingData(payload.questionType);
  //   }
  // }, [payload.questionType]);
  return (
    <div>
      <Header />
      <MainBanner id={subCatagoryID} setIsModelOpen={setIsModelOpen} />
      <AskSection
        onSignUp={() => {
          // setIsModelOpen(true);
          // setIsQuestionSubmited(true);
        }}
      />
      {/* <MultipleChoice /> */}
      <AdvantagesOfQuestions
        title="match the following"
        arr={[
          "Great for users who have a lower reading level",
          "Less chance for guessing than other question types",
          "Can cover a large amount of content.",
          "Easy to read",
          "Easy to understand",
          "Easy to grade on paper",
          "Graded automatically online",
          "More engaging for users",
        ]}
        img={MatchTheFollowingImg}
      />
      <HelpService
        helpServiceDescription="Match The Following help services"
        helpServiceTitle="DoubtQ guarantees"
        subCategoryID={subCatagoryID}
      />
      <HelpSteps subCategoryId={subCatagoryID} />
      <BonusSection
        subCategoryId={subCatagoryID}
        onClick={() => {
          setIsModelOpen(true);
        }}
      />
      <div className="example-container">
        <div className="example-img-container">
          <div className="example-title">
            <h2>
              <b>Example as well as sample for students</b>
            </h2>
            <span>Match The Following type questions</span>
          </div>
          <div className="example-card mb-2">
            <h2 className="example-header mb-0">Question</h2>
            <p className="example-question pt-1">
              <span className="py-2 d-block">Match the following:</span>
              <table className="border border-black">
                <tbody className="border border-black">
                  <tr className="border border-black">
                    <td className="border border-black p-2">(1) Velocity</td>
                    <td className="border border-black p-2">(a) Litre</td>
                  </tr>
                  <tr className="border border-black">
                    <td className="border border-black p-2">(2) Area</td>
                    <td className="border border-black p-2">(b) kilogram</td>
                  </tr>
                  <tr className="border border-black">
                    <td className="border border-black p-2">(3) Volume</td>
                    <td className="border border-black p-2">
                      (c) metre/second
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>
            <div className="example-answer">
              <h2>Answer</h2>
              <span className="d-block">1 → a</span>
              <span className="d-block">2 → b</span>
              <span className="d-block">3 → c</span>
            </div>
          </div>
          <Button
            varient="primary"
            className="mt-5"
            text={"Get MCQ Help"}
            onClick={() => {
              setIsModelOpen(true);
            }}
          ></Button>
        </div>
      </div>
      <StudentStories />
      <Footer />
      {/* {isModelOpen ? (
        <Popup
          key={subCatagoryID}
          isOpen={isModelOpen}
          toggle={toggle}
          totalSteps={totalSteps}
          payload={payload}
          handleChange={handleChange}
          formData={formData}
          type="mcqHelp"
          serviceType={"HomeworkHelp"}
          handlePostQuestion={handlePostQuestion}
          isQuestionSubmited={isQuestionSubmited}
          isError={isError}
          setIsError={setIsError}
          error={error}
        />
      ) : (
        <></>
      )} */}
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "HomeworkHelp",
            type: "mcqHelp",
            totalSteps: 3,
          }}
          subCategory={subCatagoryID}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
    </div>
  );
};

export default MatchTheFollowing;
