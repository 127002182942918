import React, { useRef, lazy, Suspense } from "react";
import { PopupTemplate } from "../../constants";
import "./FormUI.scss";
import { getCategoryWiseSubcategory } from "../../services/getCategoryWiseSubcategory";
import "react-quill/dist/quill.snow.css";
import HomeWorkForm1 from "../../Pages/homework-help/Form/HomeWorkForm1";
import HomeWorkForm2 from "../../Pages/homework-help/Form/HomeWorkForm2";
import HomeWorkForm3 from "../../Pages/homework-help/Form/HomeWorkForm3";
// import AssignmentForm1 from "../../Pages/assignment-help/Form/AssignmentForm1";
// import AssignmentFrom2 from "../../Pages/assignment-help/Form/AssignmentFrom2";
// import AssignmentForm3 from "../../Pages/assignment-help/Form/AssignmentForm3";
import LiveForm1 from "../../Pages/live-session-help/Form/LiveForm1";
import LiveForm2 from "../../Pages/live-session-help/Form/LiveForm2";
import LiveForm3 from "../../Pages/live-session-help/Form/LiveForm3";
import AssignmentForm0 from "../../Pages/assignment-help/Form/AssignmentForm0";
import LiveForm0 from "../../Pages/live-session-help/Form/LiveForm0";
import OrderHistory from "../OrderStatusAndBilling/OrderHistory";
import PaymentDetails from "../OrderStatusAndBilling/PaymentDetails";
import LiveForm4 from "../../Pages/live-session-help/Form/LiveForm4";
import CommonForm from "../../Pages/HomePage/Form/CommonForm";

const AssignmentForm1 = lazy(() =>
  import("../../Pages/assignment-help/Form/AssignmentForm1")
);
const AssignmentFrom2 = lazy(() =>
  import("../../Pages/assignment-help/Form/AssignmentFrom2")
);
const AssignmentForm3 = lazy(() =>
  import("../../Pages/assignment-help/Form/AssignmentForm3")
);

const renderSwitch = (
  type,
  index,
  payload,
  handleChange,
  formData,
  rest,
  texteditorRef,
  modules,
  formats,
  handlePaste,
  error,
  isError,
  setIsError,
  question
) => {

  switch (type) {
    case PopupTemplate.TEMPLATE_COMMON:
      <CommonForm />;
    case PopupTemplate.TEMPLATE_MCQ:
      if (index === 1) {
        return (
          <HomeWorkForm1
            texteditorRef={texteditorRef}
            payload={payload}
            modules={modules}
            formats={formats}
            question={question}
            handlePaste={handlePaste}
            handleChange={handleChange}
            error={error}
            isError={isError}
            setIsError={setIsError}
          />
        );
      }
      if (index === 2) {
        return (
          <HomeWorkForm2
            texteditorRef={texteditorRef}
            payload={payload}
            modules={modules}
            formats={formats}
            question={question}
            handlePaste={handlePaste}
            handleChange={handleChange}
            formData={formData}
            error={error}
            isError={isError}
            setIsError={setIsError}
          />
        );
        //   <h1 className="step-header text-center">Select Your Subject</h1>
        //   <Row>
        //     <FormGroup>
        //       <Input
        //         type="select"
        //         name="subject"
        //         value={payload.subject}
        //         className="question-select"
        //         onChange={handleChange}
        //       >
        //         <option value="" disabled selected>
        //           Select your subject
        //         </option>
        //         {formData.subject.map((sub) => {
        //           return (
        //             <option key={sub._id} value={sub._id}>
        //               {sub.questionSubject}
        //             </option>
        //           );
        //         })}
        //       </Input>
        //     </FormGroup>
        //     <FormGroup>
        //       <Input
        //         type="select"
        //         name="questionType"
        //         className="type-select"
        //         value={payload.questionType}
        //         onChange={handleChange}
        //       >
        //         <option value="" disabled selected>
        //           Select your question type
        //         </option>
        //         {formData.subcategory.map((sub) => {
        //           return (
        //             <option key={sub._id} value={sub._id}>
        //               {sub.name}
        //             </option>
        //           );
        //         })}
        //       </Input>
        //     </FormGroup>
        //   </Row>
        // </Container>
        // );
      }
      if (index === 3) {
        return (
          <HomeWorkForm3
            texteditorRef={texteditorRef}
            payload={payload}
            modules={modules}
            formats={formats}
            question={question}
            handlePaste={handlePaste}
            handleChange={handleChange}
            formData={formData}
          />
        );
        //     <h1 className="step-header text-center pb-5">Question Preview</h1>
        //     <Row className="justify-content-center align-items-center flex-column">
        //       <Card className="">
        //         <CardBody>
        //           <CardTitle tag="h5" className="pb-5">
        //             Question Preview
        //           </CardTitle>
        //           <CardText>
        //             <Row>
        //               <Col className="d-flex">Question</Col>
        //               <Col className="d-flex justify-content-end">
        //                 {payload.question
        //                   ? payload.question.length > 15
        //                     ? payload.question?.slice(0, 15) + "..."
        //                     : payload.question
        //                   : ""}
        //               </Col>
        //             </Row>
        //             <Row>
        //               <Col className="d-flex">Subject</Col>
        //               <Col className="d-flex justify-content-end">
        //                 {
        //                   formData.subject.find(
        //                     (x) => x._id === payload.subject
        //                   )?.questionSubject
        //                 }
        //               </Col>
        //             </Row>
        //             <Row>
        //               <Col className="d-flex">Question Type</Col>
        //               <Col className="d-flex justify-content-end">
        //                 {
        //                   formData.subcategory.find(
        //                     (x) => x._id === payload.questionType
        //                   )?.name
        //                 }
        //               </Col>
        //             </Row>
        //             <Row className="pt-5 mt-5">
        //               <hr />
        //             </Row>
        //             <Row>
        //               <Col className="d-flex">Question Price</Col>
        //               <Col className="d-flex justify-content-end">
        //                 <h3>{payload.price} USD</h3>
        //               </Col>
        //             </Row>
        //           </CardText>
        //         </CardBody>
        //       </Card>
        //       <div className="w-50">
        //         <img src={stripeTrust} className="my-4 w-100" />
        //       </div>
        //     </Row>
        //   </Container>
        // );
      }
    case PopupTemplate.TEMPLATE_ASSIGNMENT:
      if (index === 1) {
        return (
          <AssignmentForm0
            payload={payload}
            rest={rest}
            error={error}
            isError={isError}
            setIsError={setIsError}
          />
        );
      }
      if (index === 2) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <AssignmentForm1
              texteditorRef={texteditorRef}
              payload={payload}
              modules={modules}
              formats={formats}
              handlePaste={handlePaste}
              handleChange={handleChange}
              formData={formData}
              rest={rest}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
      if (index === 3) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <AssignmentFrom2
              payload={payload}
              handleChange={handleChange}
              formData={formData}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
      if (index === 4) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <AssignmentForm3
              payload={payload}
              handleChange={handleChange}
              formData={formData}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
    case PopupTemplate.TEMPLATE_LIVESESSION:
      if (index === 1) {
        return (
          <LiveForm0
            payload={payload}
            handleChange={handleChange}
            formData={formData}
            rest={rest}
            error={error}
            isError={isError}
            setIsError={setIsError}
          />
        );
      }
      if (index === 2) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <LiveForm1
              payload={payload}
              handleChange={handleChange}
              formData={formData}
              rest={rest}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
      if (index === 3) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <LiveForm2
              payload={payload}
              handleChange={handleChange}
              formData={formData}
              rest={rest}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
      if (index === 4) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <LiveForm4
              payload={payload}
              handleChange={handleChange}
              formData={formData}
              rest={rest}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
    default:
      break;
  }
};
const FormUI = (
  type,
  index = 1,
  payload,
  handleChange,
  formData,
  rest,
  quillfRef,
  error,
  isError,
  setIsError,
  question1
) => {
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const imageData = items[i].getAsFile();
        handleImagePaste(imageData);
        break;
      }
    }
  };

  const handleImagePaste = (imageData) => {
    // For simplicity, let's convert the image to base64 and insert it into the editor
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Image = e.target.result;
      insertImage(base64Image);
    };
    reader.readAsDataURL(imageData);
  };

  const insertImage = (base64Image) => {
    // Use React Quill's API to insert the image into the editor
    const range = quillfRef.getEditor().getSelection();
    quillfRef
      .getEditor()
      .insertEmbed(range ? range.index : 0, "image", base64Image, "user");
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [2, 3, 4, false] }],
        ["bold", "italic", "underline", "blockquote"],
        [{ color: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
      ],
    },
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "clean",
  ];

  return (
    <div>
      {renderSwitch(
        type,
        index,
        payload,
        handleChange,
        formData,
        rest,
        quillfRef,
        modules,
        formats,
        handlePaste,
        error,
        isError,
        setIsError,
        question1
      )}
    </div>
  );
};

export default FormUI;
