import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Row,
  Col,
  Card,
} from "reactstrap";
import { FaPlus } from "react-icons/fa";

import "../../Components/OrderStatusAndBilling/OrderHistory.scss";
// import rating from "../../Assets/icons/rating.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import { default as CustomButton } from "../../Components/Buttons/Button";
// import Solution from "../../Components/OrderStatusAndBilling/Solution";
import whatsapp from "../../Assets/images/whatsapp.png";
import zoom from "../../Assets/images/zoom.png";
import smiling from "../../Assets/images/smiling.png";
// import ExportFile from "../../Components/OrderStatusAndBilling/Helper";
import axiosInstance from "../../axiosInterceptor";
import { Rating } from "react-simple-star-rating";
import { getLiveSessionQuestionById } from "../../services/getLiveSessionQuestionById";

const Dashboardviewlive = () => {
  const [openAccordion, setOpenAccordion] = useState(["1", "2", "3", "4"]);
  const [ratingValue, setRatingValue] = useState(0);
  const [data, setData] = useState({});
  const { questionId } = useParams();
  const [isRatingSubmitted, setIsRatingSubmitted] = useState(false);
  const [setting, setSetting] = useState([]);

  const toggleAccordion = (id) => {
    setOpenAccordion((prevState) =>
      prevState.includes(id)
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id]
    );
  };

  const fetchData = async () => {
    if (questionId) {
      const response = await getLiveSessionQuestionById(questionId);
      if (response.isSuccess) {
        setData(response.question);
        if (response.question.rating) {
          setIsRatingSubmitted(true);
        }
        setRatingValue(
          response.question.rating ? parseInt(response.question.rating) : 0
        );
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [questionId]);

  const fetchSettingData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSettingData();
  }, []);

  const handleRatingChange = (value) => {
    setRatingValue(value);
  };

  const submitRating = async () => {
    try {
      const res = await axiosInstance.post("student/updateRating", {
        questionId: data?._id,
        paymentSection: data?.paymentSection,
        rating: ratingValue.toString(),
      });
      setIsRatingSubmitted(true);
      fetchData();
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  return (
    <>
      {/* <div>Payment Intent {paymentIntent}</div> */}
      <div className="wrapper">
        <div className="pt-5 content-wrapper">
          <h1 className="fw-bold">Order Number - {data?.displayId}</h1>
          <h1 className="pt-2 pb-5">
            {/* {data?.paymentSection} - {data?.subCategoryId?.name} */}
            Live Session Help - {data?.subCategoryId?.name}
          </h1>
          <Accordion
            open={openAccordion}
            toggle={toggleAccordion}
            className="accordian-Wrapper content-card"
          >
            <AccordionItem>
              <AccordionHeader targetId="1" className="heading">
                Requirement
              </AccordionHeader>
              <AccordionBody accordionId="1">
                <div className="d-flex">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Topic Detail
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle fw-bold text-end">XYZ</span>
                </div>
                <div className="d-flex">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Subject
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle  text-end">
                    {data?.subjectId?.questionSubject}
                  </span>
                </div>

                <div className="d-flex">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Start Time
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle text-end">
                    {formatDate(data?.date)} | {data?.time} {data?.meradian}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Duration
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle text-end">
                    {data?.durationMintues} Min
                  </span>
                </div>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          <div className="bg-white py-4 rounded-4 text-start ps-3 mb-5 content-card">
            <div className="row">
              <div className="col-md-6">
                <h1>Whatsapp Chat</h1>
              </div>
              <div className="col-md-6 com-whatsapp">
                <Link
                  to={`https://api.whatsapp.com/send?phone=${setting.contactNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={whatsapp} alt="whatsapp" width={50} />
                </Link>
              </div>
            </div>
          </div>
          {/* {data?.questionStatus !== "Solved" ? (
                        <Accordion
                            open={openAccordion}
                            toggle={toggleAccordion}
                            className="accordian-Wrapper content-card"
                        >
                            <AccordionItem>
                                <AccordionHeader targetId="2" className="heading">
                                    Tutor Engagement
                                </AccordionHeader>
                                <AccordionBody accordionId="2">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span className="pe-3 greyTitle w-25 text-start">Tutor</span>
                                        <span className="lineSeprator"></span>
                                        <span className="ps-3 subTitle w-50 text-end">
                                            {data?.questionStatus === "Unsolved" ? (
                                                <CustomButton
                                                    text="Not Working"
                                                    disabled
                                                    style={{ backgroundColor: "#FF0000" }}
                                                />
                                            ) : (
                                                <CustomButton
                                                    text="Working"
                                                    disabled
                                                    style={{ backgroundColor: "#1EF644" }}
                                                />
                                            )}
                                        </span>
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    ) : null} */}
          <div className="session">
            <Row>
              <Col md="7" className="p-2">
                <div className="d-md-flex mx-4">
                  <h2 className="mt-4">Session Starts in :</h2>
                  <div className="d-flex justify-content-around">
                    <div className="border-time">
                      <p className="pt-2">1</p>
                      <p className="timer-live">HOURS</p>
                    </div>
                    <div className="border-time">
                      <p className="pt-2">24</p>
                      <p className="timer-live">MINUTES</p>
                    </div>
                    <div className="border-time">
                      <p className="pt-2">05</p>
                      <p className="timer-live">SECONDS</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="5" className="p-2">
                <Button className="live-session">SESSION OVER</Button>
              </Col>
            </Row>
            <div className="content-card-live">
              <div className="mt-4">
                <div
                  className="card-hed w-100 d-xl-flex justify-content-between rounded-top-5 px-4 py-3"
                  style={{ backgroundColor: "#D5D9F9" }}
                >
                  <div className="text-start">
                    <h4>Order Id - #234567 </h4>
                  </div>
                  <div className="d-flex">
                    <h4>Hrs Left - 3 March 2024 | 7:05 AM</h4>
                    <h4></h4>
                  </div>
                </div>
              </div>
              <div className="mt-4 mb-4 p-4 text-start">
                <div className="border-buttom-live">
                  <Row className="">
                    <Col md="3">
                      <div>
                        <h2 className="mt-4">Duration Timer</h2>
                      </div>
                    </Col>
                    <Col md="9">
                      <div className="d-flex">
                        <div className="border-time">
                          <p className="pt-2 text-center">1</p>
                          <p className="timer-live">HOURS</p>
                        </div>
                        <div className="border-time">
                          <p className="pt-2 text-center">24</p>
                          <p className="timer-live">MINUTES</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="border-buttom-live">
                  <Row className="">
                    <Col md="3">
                      <div>
                        <h2 className="mt-4">Whatsapp No :</h2>
                      </div>
                    </Col>
                    <Col md="9">
                      <div className="d-flex">
                        <img
                          className="zoom-whatsapp"
                          src={whatsapp}
                          alt="Whatsapp "
                          width={35}
                          height={35}
                        />
                        <h2 className="mt-4 mx-2">+91 97747 44365</h2>
                      </div>
                    </Col>
                    <h3 className="mt-4 mx-2">
                      Please send your Doubts & Question on above whatsapp
                      Number or by clicking on whatsapp icon
                    </h3>
                  </Row>
                </div>
                <div className="border-buttom-live">
                  <Row className="">
                    <Col md="3">
                      <div>
                        <h2 className="mt-4">Zoom Link:</h2>
                      </div>
                    </Col>
                    <Col md="9">
                      <div className="d-flex">
                        <img
                          className="zoom-whatsapp"
                          src={zoom}
                          alt="Whatsapp "
                          width={35}
                          height={35}
                        />
                        <Link to="#" className="text-decoration-none">
                          <h2 className="mt-4 mx-2 zoommyclass">
                            zoommyclass.in
                          </h2>
                        </Link>
                      </div>
                    </Col>
                    <h3 className="mt-4">
                      Please click on above link to join the discussion room{" "}
                    </h3>
                  </Row>
                </div>
                {/* <div className="">
                                    <Row className="">
                                        <Col md="12">
                                            <h3 className="mt-4 text-start">Session Over, Need more Time:</h3>
                                        </Col>
                                    </Row>
                                    <div className="line-design mb-4">
                                        <div className="text-start">
                                            <Row className="mt-4">
                                                <Col md="3">
                                                    <FaPlus className="plus-icon" />
                                                </Col>
                                                <Col md="3">
                                                    <p >10 Minutes</p>
                                                </Col>
                                                <Col md="3">
                                                    <p >$10</p>
                                                </Col>
                                                <Col md="3">
                                                    <Button className="Form_session_pay_button">Pay</Button>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col md="3">
                                                    <FaPlus className="plus-icon" />
                                                </Col>
                                                <Col md="3">
                                                    <p >20 Minutes</p>
                                                </Col>
                                                <Col md="3">
                                                    <p >$20</p>
                                                </Col>
                                                <Col md="3">
                                                    <Button className="Form_session_pay_button">Pay</Button>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col md="3">
                                                    <FaPlus className="plus-icon" />
                                                </Col>
                                                <Col md="3">
                                                    <p >30 Minutes</p>
                                                </Col>
                                                <Col md="3">
                                                    <p >$30</p>
                                                </Col>
                                                <Col md="3">
                                                    <Button className="Form_session_pay_button">Pay</Button>
                                                </Col>
                                            </Row>
                                            <div className="mt-4">
                                                <h3>After Payment - Please open the new zoom link on the above section</h3>
                                                <div className="d-flex mt-4">
                                                    <h2 className="mt-2 thank-you">THANK YOU </h2>
                                                    <img className="mx-2" src={smiling} alt="thank" width={35} height={35} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>

          <div className="mt-5">
            <Accordion
              open={openAccordion}
              toggle={toggleAccordion}
              className="accordian-Wrapper content-card"
            >
              <AccordionItem>
                <AccordionHeader targetId="4" className="heading">
                  Give us a feedback
                </AccordionHeader>
                <AccordionBody accordionId="4">
                  <Row className="disabled-element">
                    <Col md="6" className="text-start">
                      <span className="pe-3 greyTitle w-25">
                        <span className="pt-1">Rating&nbsp;</span>
                        <Rating
                          onClick={handleRatingChange}
                          initialValue={ratingValue}
                          readonly={isRatingSubmitted}
                        />
                      </span>
                    </Col>
                    <Col md="6" className="text-end mt-2">
                      <span className="ps-3 subTitle fw-bold ">
                        <Button
                          className="fs-3 button button-primary"
                          onClick={submitRating}
                          disabled={isRatingSubmitted}
                        >
                          {isRatingSubmitted ? "Submitted" : "Submit"}
                        </Button>
                      </span>
                    </Col>
                  </Row>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboardviewlive;
