import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import assDash from "../../Assets/icons/assignment-dashboard.svg";
import live from "../../Assets/icons/live.svg";
import Button from "../../Components/Buttons/Button";
// import { Link } from "react-router-dom";
// import tag from "../../Assets/icons/tag.svg";
import arrowRight from "../../Assets/icons/arrowRight.svg";
import { getAllBonusOffer } from "../../services/getAllBonusOffer";
// import HomeworkIcon from "../../Assets/images/HomeworkIcon.png";
// import AssignmetIcon from "../../Assets/images/AssignemtIcon.png";
// import LiveIcon from "../../Assets/images/videoIcon.png";
import CommonForm from "../HomePage/Form/CommonForm";
// import ButtonWithRipple from "../../Components/Buttons/ButtonWithRipple";
import { checkToken } from "../../services/checkToken";
import { getCategories } from "../../services/getCategories";

// const category = [
//   {
//     label: "Homework Help",
//     sublabel: "Ask your doubts here",
//     serviceType: "HomeworkHelp",
//     type: "mcqHelp",
//     totalSteps: 3,
//     icon: HomeworkIcon,
//   },
//   {
//     label: "Assignment Help",
//     sublabel: "Complete your assignments here",
//     serviceType: "AssignmentHelp",
//     type: "assignmentHelp",
//     totalSteps: 4,
//     icon: AssignmetIcon,
//   },
//   {
//     label: "Live Session Help",
//     sublabel: "Solve your questions instantly here",
//     serviceType: "LiveSessionHelp",
//     type: "livesessionHelp",
//     totalSteps: 4,
//     icon: LiveIcon,
//   },
// ];

const DashboardHome = () => {
  const [pageData, setPageData] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [serviceType, setServiceType] = useState("");

  useEffect(() => {
    checkToken();
    return () => {
      const verifyToken = async () => await checkToken();
      const response = verifyToken();
    };
  }, []);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const fetchData = async () => {
    await getAllBonusOffer()
      .then((data) => {
        setPageData(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [categorieData, setCategorieData] = useState([]);
  const fetchDatacategorie = async () => {
    await getCategories()
      .then((data) => {
        setCategorieData(data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchDatacategorie();
  }, []);

  const handlePopup = (serviceType, type, totalSteps) => {
    setIsModelOpen(true);
    setServiceType({ serviceType, type, totalSteps });
    localStorage.setItem("serviceType", serviceType);
  };

  return (
    <>
      <Container className="py-5 pt-0 w-85 dashboard">
        <h1 className="fw-bolder py-5">Get Expert For...</h1>
        <Row>
          <Col className="d-flex justify-content-center mb-4">
            <div
              className="category-Card text-decoration-none"
              onClick={() => handlePopup("AssignmentHelp", "assignmentHelp", 4)}
            >
              <img src={assDash} alt="" />
              <h3 className="mt-5">{categorieData[1]?.name}</h3>
              <h3>{categorieData[1]?.description}</h3>
            </div>
          </Col>
          <Col className="d-flex justify-content-center mb-4">
            <div
              className="category-Card text-decoration-none"
              onClick={() =>
                handlePopup("LiveSessionHelp", "livesessionHelp", 4)
              }
            >
              <img src={live} alt="" />
              <h3 className="mt-5">{categorieData[3]?.name}</h3>
              <h3>{categorieData[3]?.description}</h3>
            </div>
          </Col>
        </Row>

        <Row className="pt-5 justify-content-center">
          <h1 className="fw-bolder">Ask Question from Experts</h1>
          {/* <h3 className="">Ask questions from subject level experts.</h3> */}
          <h3>Short and Final answers</h3>
          <Button
            className="mt-5 w-50 ask-que"
            text={"Ask Question"}
            onClick={() => handlePopup("HomeworkHelp", "mcqHelp", 3)}
          ></Button>
          {/* <ButtonWithRipple>Click</ButtonWithRipple> */}
        </Row>

        {/* <Row className="pt-5">
          <h1 className="fw-bolder pt-4 pb-4">Bonus Offers</h1>
        </Row>

        <Row className="justify-content-center pt-5">
          <Col
            className="d-flex justify-content-center p-5 col-md-8 flex-column align-items-center"
            style={{ backgroundColor: "#E5E4FF", borderRadius: "1rem" }}
          >
            <Card className="home-card">
              <div className="bonus">BONUS</div>
              <h1 className="fw-bolder text-white" style={{ fontSize: "4rem" }}>
                20% <br /> Off
              </h1>
              <div className="arrow-dashboard">
                <img src={arrowRight}></img>
              </div>
            </Card>
            <h1 className="pt-5 dashboard-dic">
              WHEN YOU ADD FUNDS <br /> $50 & $250
            </h1>
          </Col>
        </Row> */}
      </Container>
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={serviceType}
        />
      ) : (
        <></>
      )}
    </>
  );
};

// const BonusOffersCard = ({ details, className = "" }) => {
//   return (
//     <div className={`card ${className}`}>
//       <div className="label">{details.name}</div>
//       <div className="discount-wrapper">
//         <div className="bonus">BONUS</div>
//         <div className="discount">{details.percentage}%</div>
//         <div className="off">OFF</div>
//         <div className="arrow">
//           <img src={arrowRight} alt="arrow"></img>
//         </div>
//       </div>
//       <div className="message">{details.description}</div>
//       <img className="tag1" src={tag} alt="tag"></img>
//       <img className="tag2" src={tag} alt="tag"></img>
//     </div>
//   );
// };

export default DashboardHome;
