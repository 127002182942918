import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Row,
  Col,
} from "reactstrap";

import "../../Components/OrderStatusAndBilling/OrderHistory.scss";
// import rating from "../../Assets/icons/rating.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { default as CustomButton } from "../../Components/Buttons/Button";
import Solution from "../../Components/OrderStatusAndBilling/Solution";
import { getReferenceStyle } from "../../services/getReferenceStyle";
import { getAllLineSpacing } from "../../services/getAllLineSpacing";
import whatsapp from "../../Assets/images/whatsapp.png";
import ExportFile from "../../Components/OrderStatusAndBilling/Helper";
import axiosInstance from "../../axiosInterceptor";
import { Rating } from "react-simple-star-rating";
import { getAssignmentQuestionById } from "../../services/getAssignmentQuestionById";

const Dashboardview = () => {
  const [openAccordion, setOpenAccordion] = useState(["1", "2", "3", "4"]);
  const [ratingValue, setRatingValue] = useState(0);
  const [data, setData] = useState({});
  const { questionId } = useParams();
  const [isRatingSubmitted, setIsRatingSubmitted] = useState(false);
  const [setting, setSetting] = useState([]);
  const [refrenceStyle, setRefrenceStyle] = useState([]);
  const [lineSpacing, setLineSpacing] = useState([]);

  const navigate = useNavigate();

  const toggleAccordion = (id) => {
    setOpenAccordion((prevState) =>
      prevState.includes(id)
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id]
    );
  };

  const fetchSettingData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    if (questionId) {
      const response = await getAssignmentQuestionById(questionId);
      if (response.isSuccess) {
        setData(response.question);
        if (response.question.rating) {
          setIsRatingSubmitted(true);
        }
        setRatingValue(
          response.question.rating ? parseInt(response.question.rating) : 0
        );
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [questionId]);

  const referenceStyleId = data?.selectReferenceStyle;
  const lineSpacingId = data?.selectLineSpacing;

  const refrencefillter = refrenceStyle.filter(
    (item) => item._id === referenceStyleId
  );
  const lineSpacingfillter = lineSpacing.filter(
    (item) => item._id === lineSpacingId
  );

  const getReference = async () => {
    const response = await getReferenceStyle();
    setRefrenceStyle(response.data);
  };
  const getLineSpacing = async () => {
    const response = await getAllLineSpacing();
    setLineSpacing(response.data);
  };

  useEffect(() => {
    fetchSettingData();
    getReference();
    getLineSpacing();
  }, []);

  const handleRatingChange = (value) => {
    setRatingValue(value);
  };

  const submitRating = async () => {
    try {
      const res = await axiosInstance.post("student/updateRating", {
        questionId: data?._id,
        paymentSection: data?.paymentSection,
        rating: ratingValue.toString(),
      });
      setIsRatingSubmitted(true);
      navigate("/dashboard/home")
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  return (
    <>
      {/* <div>Payment Intent {paymentIntent}</div> */}
      <div className="wrapper">
        <div className="pt-5 content-wrapper">
          <h1 className="fw-bold">Order Number - {data?.displayId}</h1>
          <h1 className="pt-2 pb-5">
            {/* {data?.paymentSection} - {data?.subCategoryId?.name} */}
            Assignment Help - {data?.subCategoryId?.name}
          </h1>
          <Accordion
            open={openAccordion}
            toggle={toggleAccordion}
            className="accordian-Wrapper content-card"
          >
            <AccordionItem>
              <AccordionHeader targetId="1" className="heading">
                Requirement
              </AccordionHeader>
              <AccordionBody accordionId="1">
                <div className="d-flex">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Subject
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle  text-end">
                    {data?.subjectId?.questionSubject}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="pe-3 greyTitle w-25 text-start">Words</span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle fw-bold text-end">
                    {data?.numerOfWords}
                  </span>
                </div>
                {refrencefillter[0]?.refStyle && (
                  <div className="d-flex">
                    <span className="pe-3 greyTitle w-25 text-start">
                      Reference Style
                    </span>
                    <span className="lineSeprator"></span>
                    <span className="ps-3 subTitle text-end">
                      {refrencefillter[0]?.refStyle}
                    </span>
                  </div>
                )}

                {lineSpacingfillter[0]?.lineSpacing && (
                  <div className="d-flex">
                    <span className="pe-3 greyTitle w-25 text-start">
                      Spacing
                    </span>
                    <span className="lineSeprator"></span>
                    <span className="ps-3 subTitle text-end">
                      {lineSpacingfillter[0]?.lineSpacing}
                    </span>
                  </div>
                )}

                <div className="d-flex">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Deadline
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle text-end">
                    {data?.deadlineHours} Hours
                  </span>
                </div>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          <div className="bg-white py-4 rounded-4 text-start ps-3 mb-5 content-card">
            <div className="row">
              <div className="col-md-6">
                <h1 className="whatsapp-text">Whatsapp Chat</h1>
              </div>
              <div className="col-md-6 com-whatsapp">
                <Link
                  to={`https://api.whatsapp.com/send?phone=${setting.contactNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={whatsapp} alt="whatsapp" width={50} />
                </Link>
              </div>
            </div>
          </div>
          {/* {data?.questionStatus !== "Solved" ? ( */}
          <Accordion
            open={openAccordion}
            toggle={toggleAccordion}
            className="accordian-Wrapper content-card"
          >
            <AccordionItem>
              <AccordionHeader targetId="2" className="heading">
                Tutor Engagement
              </AccordionHeader>
              <AccordionBody accordionId="2">
                <div className="d-flex justify-content-center align-items-center">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Tutor
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle w-50 text-end">
                    {/* {data?.questionStatus === "Unsolved" ? (
                        <CustomButton
                          text="Not Working"
                          disabled
                          style={{ backgroundColor: "#FF0000" }}
                        />
                      ) : (
                        <CustomButton
                          text="Working"
                          disabled
                          style={{ backgroundColor: "#1EF644" }}
                        />
                      )} */}
                    {data?.questionStatus === "Unsolved" ? (
                      <CustomButton
                        text="Not Working"
                        disabled
                        style={{ backgroundColor: "#FF0000" }}
                      />
                    ) : data?.questionStatus === "Solved" ? (
                      <CustomButton
                        text="Solved"
                        disabled
                        style={{ backgroundColor: "#25a53b" }}
                      />
                    ) : (
                      <CustomButton
                        text="Working"
                        disabled
                        style={{ backgroundColor: "#25a53b" }}
                      />
                    )}

                  </span>
                </div>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          {/* ) : null} */}

          <Accordion
            open={openAccordion}
            toggle={toggleAccordion}
            className="accordian-Wrapper content-card"
          >
            <AccordionItem>
              <AccordionHeader targetId="3" className="heading">
                Solution
              </AccordionHeader>
              <AccordionBody accordionId="3">
                {data?.answerPDF && data.answerPDF.length > 0 ? (
                  <div className="d-flex">
                    <div className="col-sm-6">
                      <div className="d-flex align-items-center mt-4">
                        {ExportFile(data.answerPDF)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <h3 className="text-start mx-2">You will get your solution soon.</h3>
                )}
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          <div className="mt-5">
            <Accordion
              open={openAccordion}
              toggle={toggleAccordion}
              className="accordian-Wrapper content-card"
            >
              <AccordionItem>
                <AccordionHeader targetId="4" className="heading">
                  Give us a feedback
                </AccordionHeader>
                <AccordionBody accordionId="4">
                  <Row className="disabled-element">
                    <Col md="6" className="text-start">
                      <span className="pe-3 greyTitle w-25">
                        <span className="pt-1">Rating&nbsp;</span>
                        <Rating
                          onClick={handleRatingChange}
                          initialValue={ratingValue}
                        />
                      </span>
                    </Col>
                    <Col md="6" className="text-end mt-2">
                      <span className="ps-3 subTitle fw-bold ">
                        <Button
                          className="fs-3 button button-primary"
                          onClick={submitRating}
                        >
                          Submit
                        </Button>
                      </span>
                    </Col>
                  </Row>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboardview;
