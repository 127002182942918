import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getLibraryTranscriptText } from "../../services/getLibraryTranscriptText";
import "../search/ViewLibraryAnswer.scss";
import Renderer from "../../Components/Renderer";
import noques from "../../Assets/images/EFEFEFW 1.png";
import Smile_Icon from "../../Assets/images/Smile_Icon.svg";

const Libraryviewabswer = () => {
  const { search } = useParams();
  const [questionData, setQuestionData] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    const response = await getLibraryTranscriptText(search);
    if (response?.isSuccess) {
      setQuestionData(response.question);
    }
  };

  useEffect(() => {
    fetchData();
  }, [search]);

  const handlePreviousPage = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="d-md-flex">
        <div className="d-flex flex-column p-5 w-100 h-100">
          <div
            className="back-link mb-3 text-start"
            onClick={() => handlePreviousPage()}
          >
            {`< Back to search results`}
          </div>
          <div className="align-items-start">
            <div className="d-flex row w-100">
              <h3 className="fw-bold me-3 text-start">Question:</h3>
              <div className="col-md-8 text-start question-card">
                <Renderer content={questionData[0]?.question} />
                <Renderer
                  content={questionData[0]?.transcriptText}
                  className="mt-4"
                />
              </div>
            </div>
            <div className="d-flex mt-4 row w-100">
              <h3 className="fw-bold me-3 text-start">Answer:</h3>
              <div className="col-md-8 text-start answer-card">
                <Renderer content={questionData[0]?.answer} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Libraryviewabswer;
