import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./MultiStepProgressBar.scss";
import check from "../../Assets/images/check.svg";
export const MultiStepProgressBar = ({ step, totalSteps }) => {
  // Calculate Percentage
  const calculatePercentage = (currentStep, totalSteps) => {
    const incrementedPercentageValue = 100 / (totalSteps - 1);
    return incrementedPercentageValue * (currentStep - 1);
  };

  return (
    <ProgressBar
      percent={calculatePercentage(step, totalSteps)}
      filledBackground="linear-gradient(89.86deg, #5956E9 33.02%, #E541ED 91.91%)"
    >
      {Array.from({ length: totalSteps })?.map((_, index) => {
        return (
          <Step transition="scale" key={index}>
            {({ accomplished, index }) => (
              <div className={`step ${accomplished ? "completed" : null}`}>
                {index + 1 === step ? <img src={check} /> : index + 1}
              </div>
            )}
          </Step>
        );
      })}
    </ProgressBar>
  );
};
