import React, { useState } from "react";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import HelpSteps from "../../../Components/HelpSteps";
import StudentStories from "../../../Components/StudentStories";
import HelpService from "../../../Components/HelpService";
import AdvantagesOfQuestions from "../../../Components/AdvantagesOfQuestions";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import BonusSection from "../../../Components/BonusSection";
import FillInTheBlankImg from "../../../Assets/images/background/fill-in-the-blank-method.svg";
import Button from "../../../Components/Buttons/Button";
// import { updateHomeworkHelpQuestion } from "../../../services/updateHomeworkHelpQuestion";
// import { getSubcategoryWisePrice } from "../../../services/getSubcategoryWisePrice";
// import { getAllSubject } from "../../../services/getAllSubject";
// import { getCategoryWiseSubcategory } from "../../../services/getCategoryWiseSubcategory";
// import Popup from "../../../Components/Popup/Popup";
// import { useNavigate } from "react-router";
// import { createQuestionHomeworkHelp } from "../../../services/createQuestionHomeworkHelp";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";

const FillupsService = () => {
  const subCategoryId = "65565726b9cbef8acade1d6a";
  const [isModelOpen, setIsModelOpen] = useState(false);
  // const [payload, setPayload] = useState({});
  // const [formData, setFormData] = useState({});
  // const navigate = useNavigate();
  // const [isQuestionSubmited, setIsQuestionSubmited] = useState(false);
  // const [error, setError] = useState({});
  // const [isError, setIsError] = useState(false);

  const toggle = () => {
    // setIsQuestionSubmited(false);
    setIsModelOpen(!isModelOpen);
    // setPayload({});
  };
  const totalSteps = 3;

  // const fetchHomeworkCategory = async () => {
  //   const response = await getCategoryWiseSubcategory(
  //     "65524e5e3c005ef3b51907c6"
  //   );
  //   const activeSubcategory = response.subCategory?.filter((x) => x.isActive);
  //   const tempData = activeSubcategory?.map((x) => ({
  //     _id: x?._id,
  //     name: x?.name,
  //   }));
  //   setFormData((prevstate) => ({ ...prevstate, subcategory: tempData }));
  //   return response;
  // };

  // const fetchSubjectData = async () => {
  //   const response = await getAllSubject();
  //   setFormData((prevstate) => ({ ...prevstate, subject: response.data }));
  // };

  // const fetchQuestionPricingData = async (id) => {
  //   const response = await getSubcategoryWisePrice(id);
  //   setPayload((prevstate) => ({
  //     ...prevstate,
  //     price: response?.price?.studentPrice,
  //   }));
  // };

  // const handleChange = (event) => {
  //   setPayload((prevstate) => ({
  //     ...prevstate,
  //     [event.target.name]: event.target.value,
  //   }));
  //   if (event.target.value) {
  //     setIsError(false);
  //     setError((prevstate) => ({ ...prevstate, [event.target.name]: "" }));
  //   }
  // };

  // const handlePostQuestion = async () => {
  //   const response = await createQuestionHomeworkHelp(payload);
  //   if (response.isSuccess) {
  //     if (localStorage.getItem("token")) {
  //       const updatedQuestion = await updateHomeworkHelpQuestion(
  //         response.question._id,
  //         {
  //           discountPrice: 0,
  //           promoPrice: 0,
  //           promoCode: "",
  //         }
  //       );
  //       if (updatedQuestion.isSuccess) {
  //         localStorage.removeItem("questionId");
  //         navigate(`/orderStatus/${response.question._id}`);
  //       }
  //     }
  //     setIsQuestionSubmited(true);
  //     localStorage.setItem("questionId", response.question._id);
  //   }
  // };

  // useEffect(() => {
  //   fetchHomeworkCategory();
  //   fetchSubjectData();
  // }, []);

  // useEffect(() => {
  //   if (payload.questionType) {
  //     fetchQuestionPricingData(payload.questionType);
  //   }
  // }, [payload.questionType]);
  return (
    <div>
      <Header />
      <MainBanner id={subCategoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection
        onSignUp={() => {
          // setIsModelOpen(true);
          // setIsQuestionSubmited(true);
        }}
      />
      <AdvantagesOfQuestions
        title="fill in the blanks method"
        arr={[
          "Student are required to find information",
          "Less time on writing notes",
          "Allows more time to elaborate on certain areas",
          "Great method for Exceptional children",
          "Steers student in right direction",
          "Student must find the words and phrases in the text",
          "Student must find the information that the teacher wants them to find",
        ]}
        img={FillInTheBlankImg}
      />
      <HelpService
        helpServiceTitle="DoubtQ guarantees"
        helpServiceDescription="Fill In The Blanks help services"
        subCategoryID={subCategoryId}
      />
      <HelpSteps subCategoryId={subCategoryId} />
      <BonusSection
        subCategoryId={subCategoryId}
        onClick={() => {
          setIsModelOpen(true);
        }}
      />
      <div className="example-container">
        <div className="example-img-container">
          <div className="example-title">
            <h2>
              <b>Example As Well As Sample Of</b>
            </h2>
            <span>Mcq Type Questions</span>
          </div>
          <div className="example-card">
            <h2 className="example-header">Question</h2>
            <p className="example-question m-0">
              Many species of animals have been crucial to human survival at
              various points throughout time. Today, scientific study is
              striving to ______ the good advantages of living with companion
              animals.
            </p>
            <div className="example-answer">
              <h2>Answer</h2>
              <span>Discover</span>
            </div>
            <div className="example-explanation">
              <h2>Explanation</h2>
              <span className="d-flex justify-content-between align-items-center">
                Many species of animals have been crucial to human survival at
                various points throughout time. Today, scientific study is
                striving to DISCOVER the good advantages of living with
                companion animals....
              </span>
            </div>
          </div>
          <Button
            varient="primary"
            className="mt-5"
            text={"Get MCQ Help"}
            onClick={() => {
              setIsModelOpen(true);
            }}
          ></Button>
        </div>
      </div>
      <StudentStories />
      <Footer />
      {/* {isModelOpen ? (
        <Popup
          key={subCategoryId}
          isOpen={isModelOpen}
          toggle={toggle}
          totalSteps={totalSteps}
          payload={payload}
          handleChange={handleChange}
          formData={formData}
          type="mcqHelp"
          serviceType={"HomeworkHelp"}
          handlePostQuestion={handlePostQuestion}
          isQuestionSubmited={isQuestionSubmited}
          isError={isError}
          setIsError={setIsError}
          error={error}
          setError={setError}
        />
      ) : (
        <></>
      )} */}
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "HomeworkHelp",
            type: "mcqHelp",
            totalSteps: 3,
          }}
          subCategory={subCategoryId}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
    </div>
  );
};

export default FillupsService;
