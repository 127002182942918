import React, { useEffect, useState, useRef } from "react";

import { Link, useNavigate } from "react-router-dom";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Container,
  Col,
  Input,
  Row,
} from "reactstrap";

import Button from "../../Components/Buttons/Button";
import search from "../../Assets/icons/Search.svg";
import { minuteDifference } from "../../utils/js/DateUtils";
import "./dashboard-header.scss";

import logo from "../../Assets/logo/logo.svg";
// import search from "../../Assets/icons/Search.svg";
// import user from "../../Assets/icons/user.svg";
import bell from "../../Assets/icons/bell.svg";
// import wallet from "../../Assets/icons/wallet.svg";
import avatar from "../../Assets/icons/avatar.svg";
import { getNotifcations } from "../../services/getNotifications";
import history from "../../Assets/icons/history.svg";
import profile from "../../Assets/images/Profile.png";
import moment from "moment";
import { readNotification } from "../../services/readNotification";

const DashboardHeader = () => {
  const userEmail = localStorage.getItem("email");

  const [isOpen, setIsOpen] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);

  const navigate = useNavigate();
  const toggle = () => setIsOpen(!isOpen);

  const handleLogout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("questionId");
    localStorage.removeItem("serviceType");
    navigate("/");
  };

  const fetchNotificationData = async () => {
    const response = await getNotifcations();
    setNotificationData(response.data);
  };

  const handleMessageRead = async (id) => {
    const response = await readNotification(id);
    if (response.isSuccess) {
      fetchNotificationData();
    }
  };
  const notificationRef = useRef(null);
  const profileRef = useRef(null);

  const closeNotificationMenu = () => {
    setNotificationMenuOpen(false);
  };

  // Function to close profile menu
  const closeProfileMenu = () => {
    setProfileMenuOpen(false);
  };

  // Function to handle clicks outside the menus
  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      closeNotificationMenu();
    }
    if (
      profileRef.current &&
      !profileRef.current.contains(event.target) &&
      !event.target.classList.contains("menu-link")
    ) {
      closeProfileMenu();
    }
  };

  const handleMenuLinkClick = () => {
    closeProfileMenu();
    closeNotificationMenu();
  };
  useEffect(() => {
    fetchNotificationData();
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Container fluid className="top-header-dashboard">
        <div className="logo-dashboard">
          <img src={logo} alt="" width={"100%"} height={"100%"} />
        </div>
        {/* </NavbarBrand> */}
        {/* <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar> */}
        {/* <div className="d-flex justify-content-center align-items-center"> */}
        <div className="search-container d-none d-sm-block">
          {/* <Row>
            <Col className="position-relative">
              <Input
                placeholder="Search, solutions, and more...."
                className="py-4 search"
              />
              <div className="d-flex p-3 rounded-3 search-icon">
                <img src={search} alt="" />
              </div>
            </Col>
          </Row> */}
        </div>
        <div className="d-flex  align-items-center justify-content-end">
          {/* <Col lg={3} md={6} className="me-5 pe-5">
                <Input
                  placeholder="Serach, solutions, and more...."
                  className="py-4 search rounded-4"
                />
                <div className="d-flex p-3  rounded-3 search-icon-main">
                  <img src={search} alt="" />
                </div>
              </Col> */}
          {/* <Button
            className="tutor ms-5"
            style={{ color: "#e541ed" }}
            varient="outline-secondaty"
            text="Want Tutor?"
          /> */}
          {/* <NavItem className="px-3">
                <Link to="/" className="menu nav-link">
                  <img
                    src={bell}
                    alt=""
                    style={{ width: "35px", height: "35px" }}
                  />
                </Link>
              </NavItem> */}
          {/* <NavItem className="px-3">
                <Link to="/" className="menu nav-link">
                  <img
                    src={wallet}
                    alt=""
                    style={{ width: "35px", height: "35px" }}
                  />
                </Link>
              </NavItem> */}
          <div className="px-3 notification-button" ref={notificationRef}>
            <span
              className="menu nav-link position-releative"
              onClick={() => setNotificationMenuOpen(!notificationMenuOpen)}>
              <img
                src={bell}
                alt=""
                style={{ width: "35px", height: "35px" }}
              />
              <span className="notification-count">
                {notificationData.length}
              </span>
            </span>
            <div
              className={`dropDown-Content ${notificationMenuOpen ? "open" : ""
                }`}>
              <div className="notification">
                <span>Notification</span>
              </div>
              <div className="pb-2 ps-4">
                {!notificationData.length ? (
                  <h3 className="text-left d-flex p-3">
                    No new notification available
                  </h3>
                ) : (
                  <></>
                )}
                <div className="notification-message-wrapper">
                  {notificationData?.map((noti) => {
                    return (
                      <div className="d-flex align-items-center notification-wrapper">
                        <div className="me-2">
                          <img src={history} />
                        </div>
                        <div>
                          <div className="fw-bold">
                            {noti.categoryName} | {noti.displayId}
                          </div>
                          <div>
                            <span className="notification-status">
                              {noti.notificationStatus} |{" "}
                            </span>
                            <span className="notification-time">
                              {minuteDifference(noti.createdAt)}
                            </span>
                          </div>
                          <div className="notification-title">{noti.title}</div>
                          <div className="d-flex justify-content-end">
                            <button
                              className="read-button"
                              onClick={() => handleMessageRead(noti._id)}>
                              Mark as Read
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 profile-button" ref={profileRef}>
            <span
              className="menu nav-link"
              onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
              <img
                src={avatar}
                alt=""
                style={{ width: "28px", height: "28px" }}
              />
            </span>
            <div
              className={`dropDown-Content ${profileMenuOpen ? "open" : ""}`}>
              <div className="profile-setting">
                <img src={profile} alt="Layer" width={35} />
                {/* <h3 className="fw-bold mt-2">Kartik</h3> */}
                <div className="mt-2 fs-8 ms-2">{userEmail}</div>
              </div>
              <div className="p-4">
                <div
                  className="menu-link"
                  // onClick={() => navigate("/dashboard/earnmoney")}
                  onClick={() => {
                    navigate("/dashboard/earnmoney");
                    handleMenuLinkClick();
                  }}
                >
                  Posting Streak
                </div>
                <div
                  className="menu-link"
                  // onClick={() => navigate("/dashboard/personal-info")}
                  onClick={() => {
                    navigate("/dashboard/personal-info");
                    handleMenuLinkClick();
                  }}
                >
                  Personal Info
                </div>
                <div className="menu-link" onClick={handleLogout}>
                  Logout
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Collapse> */}
        {/* </Navbar> */}
      </Container>
    </>
  );
};

export default DashboardHeader;
