import axios from "axios";
// const apiKey = process.env.API_KEY || "http://localhost:5001/api/";
// const apiKey = process.env.API_KEY || "https://doubtq-2e12.onrender.com/api/";
const apiKey = process.env.REACT_APP_API || "https://api.doubtq.com/api/";

const axiosInstance = axios.create({
  baseURL: apiKey,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // You can add any logic here before the request is sent
    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // You can add any logic here for successful responses
    return response;
  },
  (error) => {
    // You can add error handling logic here
    return Promise.reject(error);
  }
);

export default axiosInstance;
