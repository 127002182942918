import React, { useState } from "react";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import HelpSteps from "../../../Components/HelpSteps";
import StudentStories from "../../../Components/StudentStories";
import HelpService from "../../../Components/HelpService";
import AdvantagesOfDefinitionImg from "../../../Assets/images/background/advantage-of-definition-img.png";
import AdvantagesOfDefinitionImg2 from "../../../Assets/images/background/definition-img.svg";
import Footer from "../../../Components/Footer";
import "./Definition.scss";
import Header from "../../../Components/Header";
import BonusSection from "../../../Components/BonusSection";
import Button from "../../../Components/Buttons/Button";
// import { useNavigate } from "react-router";
// import { getCategoryWiseSubcategory } from "../../../services/getCategoryWiseSubcategory";
// import { getAllSubject } from "../../../services/getAllSubject";
// import { getSubcategoryWisePrice } from "../../../services/getSubcategoryWisePrice";
// import { createQuestionHomeworkHelp } from "../../../services/createQuestionHomeworkHelp";
// import { updateHomeworkHelpQuestion } from "../../../services/updateHomeworkHelpQuestion";
// import Popup from "../../../Components/Popup/Popup";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";

const Definition = () => {
  const subCatagoryId = "6586d3c2dafb1f4a8f4610e1";
  const [isModelOpen, setIsModelOpen] = useState(false);
  // const [payload, setPayload] = useState({});
  // const [formData, setFormData] = useState({});
  // const navigate = useNavigate();
  // const [isQuestionSubmited, setIsQuestionSubmited] = useState(false);
  // const [error, setError] = useState({});
  // const [isError, setIsError] = useState(false);

  const toggle = () => {
    // setIsQuestionSubmited(false);
    setIsModelOpen(!isModelOpen);
    // setPayload({});
  };
  const totalSteps = 3;

  // const fetchHomeworkCategory = async () => {
  //   const response = await getCategoryWiseSubcategory(
  //     "65524e5e3c005ef3b51907c6"
  //   );
  //   const activeSubcategory = response.subCategory?.filter((x) => x.isActive);
  //   const tempData = activeSubcategory?.map((x) => ({
  //     _id: x?._id,
  //     name: x?.name,
  //   }));
  //   setFormData((prevstate) => ({ ...prevstate, subcategory: tempData }));
  //   return response;
  // };

  // const fetchSubjectData = async () => {
  //   const response = await getAllSubject();
  //   setFormData((prevstate) => ({ ...prevstate, subject: response.data }));
  // };

  // const fetchQuestionPricingData = async (id) => {
  //   const response = await getSubcategoryWisePrice(id);
  //   setPayload((prevstate) => ({
  //     ...prevstate,
  //     price: response?.price?.studentPrice,
  //   }));
  // };

  // const handleChange = (event) => {
  //   setPayload((prevstate) => ({
  //     ...prevstate,
  //     [event.target.name]: event.target.value,
  //   }));
  //   if (event.target.value) {
  //     setIsError(false);
  //     setError((prevstate) => ({ ...prevstate, [event.target.name]: "" }));
  //   }
  // };

  // const handlePostQuestion = async () => {
  //   const response = await createQuestionHomeworkHelp(payload);

  //   if (response.isSuccess) {
  //     if (localStorage.getItem("token")) {
  //       const updatedQuestion = await updateHomeworkHelpQuestion(
  //         response.question._id,
  //         {
  //           discountPrice: 0,
  //           promoPrice: 0,
  //           promoCode: "",
  //         }
  //       );
  //       if (updatedQuestion.isSuccess) {
  //         localStorage.removeItem("questionId");
  //         navigate(`/orderStatus/${response.question._id}`);
  //       }
  //     }
  //     setIsQuestionSubmited(true);
  //     localStorage.setItem("questionId", response.question._id);
  //   }
  // };

  // useEffect(() => {
  //   fetchHomeworkCategory();
  //   fetchSubjectData();
  // }, []);

  // useEffect(() => {
  //   if (payload.questionType) {
  //     fetchQuestionPricingData(payload.questionType);
  //   }
  // }, [payload.questionType]);
  return (
    <div>
      <Header />
      <MainBanner id={subCatagoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection
      // onSignUp={() => {
      //   setIsModelOpen(true);
      //   setIsQuestionSubmited(true);
      // }}
      />
      <div className="advantages-of-definition-container">
        <div className="advantages-of-definition-header">
          <div className="advantages-of-definition-title">
            <span>Advantages of </span>
            <b>definition</b>
          </div>
          <div className="advantages-of-definition-description">
            Definition : is a statement that explains the meaning of a term(a
            word,phrase, or other set of symbols)
          </div>
        </div>
        <div className="advantages-of-definition">
          <div className="advantages-of-definition-img-container">
            <div className="advantages-of-definition-img">
              <img src={AdvantagesOfDefinitionImg} alt="" />
            </div>
            <div className="advantages-of-definition-img">
              <img src={AdvantagesOfDefinitionImg2} alt="" />
            </div>
          </div>
          <div className="advantages-of-definition-action">
            Action : pretend to open dictionary to look up the definition of a
            word you do not know
          </div>
        </div>
      </div>
      <HelpService
        helpServiceDescription="Definition help services"
        helpServiceTitle="DoubtQ guarantees"
        subCategoryID={subCatagoryId}
      />
      <HelpSteps subCategoryId={subCatagoryId} />
      <BonusSection
        subCategoryId={subCatagoryId}
        onClick={() => {
          setIsModelOpen(true);
        }}
      />
      <div className="example-container">
        <div className="example-img-container">
          <div className="example-title">
            <h2>
              <b>Example as well as sample for students</b>
            </h2>
            <span>Match The Following type questions</span>
          </div>
          <div className="example-card mb-2">
            <h2 className="example-header mb-0">Question</h2>
            <p className="example-question m-0">
              Definition terms- (i) mineral. (ii) ore. (iii) gangue.
            </p>
            <div className="example-answer">
              <h2>Answer</h2>
              <span>
                <div>
                  (i) Naturally occurring substance, that occurs in earth’s
                  crust, in which metals are present in native or combined
                  states is called mineral. Example: Magnetite, iron pyrite..
                </div>
                <div>
                  (ii) Mineral from which metals can be extracted, conveniently
                  and economically is called ore. Example-Haematite, calamine
                  etc.
                </div>
                <div>
                  (iii) The impurities present along with the pure metal in a
                  particular ore, is known as gangue. Impurities can be
                  separated from metal by using simple extraction processes.
                </div>
              </span>
            </div>
          </div>
          <Button
            varient="primary"
            className="mt-5"
            text={"definition"}
            onClick={() => {
              setIsModelOpen(true);
            }}
          ></Button>
        </div>
      </div>
      <StudentStories />
      <Footer />
      {/* {isModelOpen ? (
        <Popup
          key={subCatagoryId}
          isOpen={isModelOpen}
          toggle={toggle}
          totalSteps={totalSteps}
          payload={payload}
          handleChange={handleChange}
          formData={formData}
          type="mcqHelp"
          serviceType={"HomeworkHelp"}
          handlePostQuestion={handlePostQuestion}
          isQuestionSubmited={isQuestionSubmited}
          isError={isError}
          setIsError={setIsError}
          error={error}
          setError={setError}
        />
      ) : (
        <></>
      )} */}
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "HomeworkHelp",
            type: "mcqHelp",
            totalSteps: 3,
          }}
          subCategory={subCatagoryId}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
    </div>
  );
};

export default Definition;
