import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Input, Label, Row } from "reactstrap";

import call from "../../Assets/icons/call.svg";
import email from "../../Assets/icons/email.svg";
import location from "../../Assets/icons/location.svg";
import contactUs from "../../Assets/images/contact-us.png";
import Button from "../../Components/Buttons/Button";

import axiosInstance from "../../axiosInterceptor";
import { Link } from "react-router-dom";

const DashboardContactUs = () => {
  const [setting, setSetting] = useState([]);
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post("student/contactUs", profile)
      .then((res) => {
        // console.log(res)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSettingData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSettingData();
  }, []);

  return (
    <>
      <div className="faq-top">
        <Container className="py-5 w-85 dashboard">
          <Row className="py-5">
            <Col className="py-5">
              <h1
                className="py-5"
                style={{ fontSize: "2.5em", fontWeight: "600" }}
              >
                Have some questions?
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="add-contact">
        <Row className="">
          <Col md="4" className="mb-2">
            <Card className="contact-card" style={{ borderRadius: "11px" }}>
              <div className="w-100 text-start">
                <img
                  src={call}
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                />
                <h4
                  className="pt-3"
                  style={{ fontSize: "1.6em", fontWeight: "bold" }}
                >
                  Student support
                </h4>
                {/* <h4 className='pt-3 text-black font-weight-bold'>Student support</h4> */}
                <h4
                  style={{ color: "#rgb(133 133 133)" }}
                >{` +91 ${setting?.contactNumber}`}</h4>
              </div>
            </Card>
          </Col>
          <Col md="4" className="mb-2">
            <Card
              className="contact-card"
              style={{ borderRadius: "11px", cursor: "pointer" }}
            >
              <div className="w-100 text-start">
                <img
                  src={email}
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                />
                <h4
                  className="pt-3"
                  style={{ fontSize: "1.6em", fontWeight: "bold" }}
                >
                  Email Address
                </h4>
                <h5 style={{ color: "rgb(133 133 133)" }}>
                  <Link
                    to={`mailto:${setting?.contactEmail}`}
                    style={{
                      color: "#666",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    <h4>{setting?.contactEmail}</h4>
                  </Link>
                </h5>
              </div>
            </Card>
          </Col>
          <Col md="4" className="mb-2">
            <Card className="contact-card" style={{ borderRadius: "11px" }}>
              <div className="w-100 text-start">
                <img
                  src={location}
                  alt=""
                  style={{ width: "27px", height: "27px" }}
                />
                <h4
                  className="pt-3"
                  style={{ fontSize: "1.6em", fontWeight: "bold" }}
                >
                  Our Location
                </h4>
                <h4 style={{ color: "rgb(133 133 133)" }}>
                  B-603, Sky View Heights, Behind Midas Square, Surat, Gujarat,
                  395010
                </h4>
              </div>
            </Card>
          </Col>
          {/* <Col md="4" className="mb-2">
                        <Card className='contact-card' style={{ borderRadius: '11px' }}>
                            <div className='w-100 text-start'>
                                <img src={location} alt="" style={{ width: '27px', height: '27px' }} />
                                <h4 className='pt-3' style={{ fontSize: '1.6em', fontWeight: 'bold' }}>Instagram</h4>
                                <h5 style={{ color: '#A19A9A' }}>5678 Bangla Main Road, cities
                                    580GBnagla, example 54786</h5>
                            </div>
                        </Card>
                    </Col> */}
        </Row>
      </Container>

      <Container className="add-contact mb-4">
        <Row>
          <Col md="6">
            <img className="w-100" src={contactUs} alt="" style={{ borderRadius: "1.5rem" }} />
          </Col>
          <Col md="6">
            <Card className="contactus-card text-start contactus-card-m">
              <h1 className="text-start w-100 fw-semi-bold">
                You Can Contact With Me
              </h1>
              <Form
                className="w-100 pt-5"
                onSubmit={handleSubmit}
                style={{ zIndex: 9999 }}
              >
                <Label style={{ fontSize: "1.5em", fontWeight: "fw-semi-bold" }}>
                  Full Name
                </Label>
                <Input
                  type="text"
                  name="name"
                  className="w-100 py-4"
                  value={profile.name}
                  onChange={handleChange}
                  placeholder="User Full Name"
                  style={{ fontSize: "1.2em", color: "#949494" }}
                />

                <Label className="mt-4" style={{ fontSize: "1.5em", fontWeight: "fw-semi-bold" }}>
                  Mobile
                </Label>
                <Input
                  type="number"
                  name="phoneNumber"
                  className="w-100 py-4"
                  value={profile.phoneNumber}
                  onChange={handleChange}
                  placeholder="Your Number"
                  style={{ fontSize: "1.2em", color: "#949494" }}
                />

                <Label className="mt-4" style={{ fontSize: "1.5em", fontWeight: "fw-semi-bold" }}>
                  Email Id
                </Label>
                <Input
                  type="text"
                  name="email"
                  className="w-100 py-4"
                  value={profile.email}
                  onChange={handleChange}
                  placeholder="abc@gmail.com"
                  style={{ fontSize: "1.2em", color: "#949494" }}
                />

                <Label className="mt-4" style={{ fontSize: "1.5em", fontWeight: "fw-semi-bold" }}>
                  Message
                </Label>
                <Input
                  type="textarea"
                  name="message"
                  className="w-100 py-4"
                  value={profile.message}
                  onChange={handleChange}
                  placeholder="Write your message here"
                  style={{ fontSize: "1.2em", color: "#949494" , height: "150px"}}
                />

                <Button
                  type="submit"
                  varient="linear-gradient-primary"
                  className="mt-5 w-100"
                  text={"Send Message"}
                ></Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardContactUs;
