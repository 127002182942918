import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
} from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
// import { updatePaymentDetails } from "./../../services/updatePaymentDetails";

import thanks from "../../Assets/images/thankyou.png";
import whatsapp from "../../Assets/images/WhatsApp-Logo-Icon.png"

import "./OrderHistory.scss";
import { default as CustomButton } from "../../Components/Buttons/Button";
import Feedback from "./Feedback";
import axios from "axios";
import Solution from "./Solution";
import { getReferenceStyle } from "../../services/getReferenceStyle";
import { getAllLineSpacing } from "../../services/getAllLineSpacing";

const Completion = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const paymentIntent = searchParams.get("payment_intent");

  const [openAccordion, setOpenAccordion] = useState(["1", "2", "3", "4"]);
  const [orderData, setOrderData] = useState(() => {
    // Retrieve orderData from localStorage on component mount
    return JSON.parse(localStorage.getItem("orderData")) || null;
  });

  const [refrenceStyle, setRefrenceStyle] = useState([]);
  const [lineSpacing, setLineSpacing] = useState([]);

  const referenceStyleId = orderData?.data?.getQuestion?.selectReferenceStyle
  const lineSpacingId = orderData?.data?.getQuestion?.selectLineSpacing

  const refrencefillter = refrenceStyle.filter((item) => (item._id === referenceStyleId))
  const lineSpacingfillter = lineSpacing.filter((item) => (item._id === lineSpacingId))

  const toggleAccordion = (id) => {
    setOpenAccordion(id === openAccordion ? null : id);
  };

  const updatePayment = useCallback(async () => {
    if (paymentIntent) {
      const token = localStorage.getItem("token");
      const apiEndPoint = process.env.REACT_APP_API;
      const response = await axios.post(
        `${apiEndPoint}student/payment/paymentDetails`,
        { paymentIntent },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response?.data;
      if (data.isSuccess) {
        setSearchParams();
        setOrderData(data);
        // Store orderData in localStorage
        localStorage.setItem("orderData", JSON.stringify(data));
      }
    }
  }, [paymentIntent]);

  const getReference = async () => {
    const response = await getReferenceStyle();
    setRefrenceStyle(response.data);
  };
  const getLineSpacing = async () => {
    const response = await getAllLineSpacing();
    setLineSpacing(response.data);
  };

  useEffect(() => {
    updatePayment();
    getReference();
    getLineSpacing();
  }, [orderData]);

  return (
    <>
      {/* <div>Payment Intent {paymentIntent}</div> */}
      <div className="wrapper">
        <div>
          <img
            src={thanks}
            alt=""
            style={{ height: "150px", marginTop: "10px" }}
          />
        </div>
        <div className="pt-5 content-wrapper">
          <h1 className="fw-bold">
            Order Number - {orderData?.data?.getQuestion?.displayId}
          </h1>
          <h3 className="pt-2 pb-5">
            {orderData?.data?.getQuestion?.paymentSection}-{" "}
            {orderData?.data?.getQuestion?.subCategoryId?.name}
          </h3>
          <Accordion
            open={openAccordion}
            toggle={toggleAccordion}
            className="accordian-Wrapper content-card"
          >
            {/* AssignmentHelp  */}
            <AccordionItem>
              <AccordionHeader targetId="1" className="heading">
                Requirement
              </AccordionHeader>
              <AccordionBody accordionId="1">
                <div className="d-flex">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Subject
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle  text-end">
                    {orderData?.data?.getQuestion?.subjectId?.questionSubject}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Words
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle fw-bold text-end">
                    {orderData?.data?.getQuestion?.numerOfWords}
                  </span>
                </div>

                {refrencefillter[0]?.refStyle && (
                  <div className="d-flex">
                    <span className="pe-3 greyTitle w-25 text-start">
                      Reference Style
                    </span>
                    <span className="lineSeprator"></span>
                    <span className="ps-3 subTitle text-end">
                      {refrencefillter[0]?.refStyle}
                    </span>
                  </div>
                )}

                {lineSpacingfillter[0]?.lineSpacing && (
                  <div className="d-flex">
                    <span className="pe-3 greyTitle w-25 text-start">
                      Spacing
                    </span>
                    <span className="lineSeprator"></span>
                    <span className="ps-3 subTitle text-end">
                      {lineSpacingfillter[0]?.lineSpacing}
                    </span>
                  </div>
                )}
                {/* <div className="d-flex  justify-content-center">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Reference Style
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle text-end">
                    {fillterData[0]?.refStyle}
                  </span>
                </div>
                <div className="d-flex  justify-content-center">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Spacing
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle text-end">
                    {fillterData1[0]?.lineSpacing}
                  </span>
                </div> */}
                <div className="d-flex">
                  <span className="pe-3 greyTitle w-25 text-start">
                    Deadline
                  </span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle text-end">
                    {orderData?.data?.getQuestion?.deadlineHours} Hours
                  </span>
                </div>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          <div className="bg-white py-4 rounded-4 text-start ps-4 mb-5 content-card">
            <div className="row">
              <div className="col-md-6">
                <h4 className="heading whatsapp-chat-title">Whatsapp Chat</h4>
              </div>
              <div className="col-md-6 com-whatsapp">
                <Link to="https://api.whatsapp.com/send?phone=7788994455" target="_blank" rel="noopener noreferrer">
                  <img src={whatsapp} alt="whatsapp" width={50} />
                </Link>
              </div>
            </div>
          </div>
          <Accordion
            open={openAccordion}
            toggle={toggleAccordion}
            className="accordian-Wrapper content-card"
          >
            <AccordionItem>
              <AccordionHeader targetId="2" className="heading">
                Tutor Engagment
              </AccordionHeader>
              <AccordionBody accordionId="2">
                <div className="d-flex  justify-content-center align-items-center">
                  <span className="pe-3 greyTitle w-25 text-start">Tutor</span>
                  <span className="lineSeprator"></span>
                  <span className="ps-3 subTitle w-50 text-end">
                    <CustomButton
                      text="Working"
                      disabled
                      style={{ backgroundColor: "#FF0000" }}
                    />
                  </span>
                </div>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          <Solution orderData={orderData} />
          <div className="mt-5">
            <Feedback />
          </div>
        </div>
      </div>
    </>
  );
};

export default Completion;
