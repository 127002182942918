import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getHomeWorkHelpQuestionById } from "../../services/getHomeWorkHelpQuestionById";
import "./OrderHistory.scss";
import Requirements from "./Requirements";
import Remarks from "./Remarks";
import WhatsappSupport from "./WhatsappSupport";
import PaymentDetails from "./PaymentDetails";
import TutorEngagement from "./TutorEngagement";
import Solution from "./Solution";
import LogoHeader from "./LogoHeader";
import axios from "axios";
import { Button } from "reactstrap";

const apiEndPoint = process.env.REACT_APP_API;
const OrderHistory = () => {
  const [data, setData] = useState({});
  const [isAddressAvailable, setIsAddressAvailable] = useState(false);
  const { questionId } = useParams();
  const navigate = useNavigate();
  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      if (localStorage.getItem("serviceType") === "HomeworkHelp") {
        const response = await axios.get(
          `${apiEndPoint}student/homeworkHelp/getQuestionDetailByID/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response?.data;
        setData(data.question);
        setIsAddressAvailable(data.billingDetails);
      } else if (localStorage.getItem("serviceType") === "AssignmentHelp") {
        const response = await axios.get(
          `${apiEndPoint}student/assignmentHelp/getQuestionDetailByID/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response?.data;
        setData(data.question);
        setIsAddressAvailable(data.billingDetails);
      } else if (localStorage.getItem("serviceType") === "LiveSessionHelp") {
        const response = await axios.get(
          `${apiEndPoint}student/liveSessionHelp/getQuestionDetailByID/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response?.data;
        setData(data.question);
        setIsAddressAvailable(data.billingDetails);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [questionId]);

  return (
    <div className="wrapper">
      <LogoHeader />
      <div className="content-wrapper">
        <h4 className="title">Confirm & Pay</h4>
        <h6 className="subTitle pb-4">
          {data?.paymentSection
            ?.replace(/LiveSessionHelp/g, "Live Session Help")
            .replace(/AssignmentHelp/g, "Assignment Help")
            .replace(/HomeworkHelp/g, "Homework Help")}
        </h6>
        <div className="mb-4 text-start mx-2">
          <Button
            className="back-btn-payment"
            onClick={() => navigate("/dashboard/home")}
          >
            Back
          </Button>
        </div>
        <Requirements questionData={data} className="mt-4" />
        <Remarks questionData={data} />
        <WhatsappSupport questionId={questionId} studentId={data.studentId} />
        <PaymentDetails
          questionData={data}
          fetchData={fetchData}
          disable={true}
          isAddressAvailable={isAddressAvailable}
        />
        <TutorEngagement disable={true} />
        <Solution disable={true} />
      </div>
    </div>
  );
};

export default OrderHistory;
