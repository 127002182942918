import React, { useEffect, useState } from "react";
import "./dashboard-header.scss";
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Col,
  Row,
} from "reactstrap";
import whatsapp from "../../Assets/images/whatsapp.png";
import mail from "../../Assets/images/8665305_envelope_email_icon 1.png";
import OBJECTS from "../../Assets/images/OBJECTS.png";
import { getPostingStreak } from "../../services/getPostingStreak";
import axiosInstance from "../../axiosInterceptor";
import { Link } from "react-router-dom";

const Earnmoney = () => {
  const [modal, setModal] = useState(false);
  const updateStreak = async () => {
    const response = await getPostingStreak();
    if (response.isSuccess) {
      fetchData();
    }
  };

  const [homeworkData, setHomeworkData] = useState([]);
  const [assignmentData, setAssignmentData] = useState([]);
  const [liveSessionData, setLiveSessionData] = useState([]);
  const [setting, setSetting] = useState([]);

  const fetchData = async () => {
    try {
      const data = await getPostingStreak();
      setHomeworkData(data.homeworkData);
      setAssignmentData(data.assignmentData);
      setLiveSessionData(data.liveSessionData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => {
    setModal(!modal);
    updateStreak();
  };

  const fetchSettingData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSettingData();
  }, []);

  // const [state, setState] = useState({
  //     isSendGift: false,
  //     disabled: false,
  //     rewardStatus: false
  // });

  // console.log("--",state.rewardStatus);

  // const handleButtonClick = async () => {
  //     try {
  //         const response = await axiosInstance.get('/student/getReward/65cded28959b069d63162c3a');
  //         console.log("dd",response.data);
  //         setState(prevState => ({
  //             ...prevState,
  //             rewardStatus: response.data.isSendGift,
  //             isSendGift: response.data.isSendGift,
  //             disabled: true
  //         }));
  //         toggle();
  //     } catch (error) {
  //         console.error('Error fetching reward:', error);
  //     }
  // };

  // const handleButtonClickWithToggle = () => {
  //     handleButtonClick();
  //     toggle();
  // };

  return (
    <div>
      <div className="earnmoney-page p-3">
        <h1 className="text-start">Homework Help:</h1>
        <Table bordered>
          <thead>
            <tr className="table-bg">
              <th scope="col" className="text-start Questions-box">
                Questions
              </th>
              <th scope="col" className="text-start rewards-box">
                Rewards
              </th>
              <th scope="col" />
              <th scope="col" className="Questions-box">
                You Got
              </th>
            </tr>
          </thead>
          <tbody className="vertical-align-middle">
            {homeworkData.map((story, index) => {
              // Calculate the progress percentage
              const progressPercentage =
                (story.studentPost / story.countPost) * 100;

              return (
                <tr key={index}>
                  <td className="text-start">{story.streak}</td>
                  <td className="text-start">{story.rewards}</td>
                  <td className="progress-box">
                    <div className="single-progress">
                      <div className="progress">
                        <div
                          className="progress-bar wow fadeInLeft"
                          style={{
                            width: `${progressPercentage}%`,
                            visibility: "visible",
                            animationDuration: "0.5s",
                            animationDelay: "0.3s",
                            animationName: "fadeInLeft",
                          }}
                          role="progressbar"
                          aria-valuenow={progressPercentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                        <span className="progress-number">
                          {story.studentPost}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    {/* Conditionally render button */}
                    {story.countPost === story.studentPost ? (
                      <Button className="myBtn" onClick={toggle}>
                        Get Reward
                      </Button>
                    ) : (
                      <Button className="myBtn myBtnDisabled" disabled>
                        Get Reward
                      </Button>
                    )}
                    {/* <Button className="myBtn" onClick={handleButtonClick} disabled={state.rewardStatus}>
                                            Get Reward
                                        </Button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="terms_condition text-start">
          <p className="fw-bold">Terms &amp; Condition</p>
          <ul className="list-none">
            <li>
              For only genuine questions asked and when it should be properly
              answered by our tutors
            </li>
            <li>For Questions which fulfill the criteria of DoubtQ</li>
            <li>
              Redeemable criteria can be updated or changed by DoubtQ anytime
              without any consent
            </li>
            <li>
              For every GET REWARD - Mail with the email id shown there and have
              to send the bank details where we can send your earnings amount
            </li>
          </ul>
        </div>
      </div>
      <div className="earnmoney-page p-3 mt-4">
        <h1 className="text-start">Assignment Help:</h1>
        <Table bordered>
          <thead>
            <tr className="table-bg">
              <th scope="col" className="text-start Questions-box">
                Assignments
              </th>
              <th scope="col" className="text-start rewards-box">
                Rewards
              </th>
              <th scope="col" />
              <th scope="col" className="Questions-box">
                You Got
              </th>
            </tr>
          </thead>
          <tbody className="vertical-align-middle">
            {assignmentData.map((assignmentData, index) => {
              // Calculate the progress percentage
              const progressPercentage =
                (assignmentData.studentPost / assignmentData.countPost) * 100;

              return (
                <tr key={index}>
                  <td className="text-start">{assignmentData.streak}</td>
                  <td className="text-start">{assignmentData.rewards}</td>
                  <td className="progress-box">
                    <div className="single-progress">
                      <div className="progress">
                        <div
                          className="progress-bar wow fadeInLeft"
                          style={{
                            width: `${progressPercentage}%`, // Set width based on progress percentage
                            visibility: "visible",
                            animationDuration: "0.5s",
                            animationDelay: "0.3s",
                            animationName: "fadeInLeft",
                          }}
                          role="progressbar"
                          aria-valuenow={progressPercentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                        <span className="progress-number">
                          {assignmentData.studentPost}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    {/* Conditionally render button */}
                    {assignmentData.countPost === assignmentData.studentPost ? (
                      <Button className="myBtn" onClick={toggle}>
                        Get Reward
                      </Button>
                    ) : (
                      <Button className="myBtn myBtnDisabled" disabled>
                        Get Reward
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="terms_condition text-start">
          <p className="fw-bold">Terms &amp; Condition</p>
          <ul className="list-none">
            <li>
              Minimum Amount of order of each assignment will be valued at - $40
            </li>
            <li>
              For only Assignments asked and when payment received then only
            </li>
            <li>For Assignments which fulfill the criteria of DoubtQ</li>
            <li>
              This Redeemable criteria can be updated or change by DoubtQ
              anytime without any consent
            </li>
            <li>
              For every GET REWARD - Mail with the email id shown there and have
              to send the bank details where we can send your earnings amount or
              relevant gifts
            </li>
          </ul>
        </div>
      </div>
      <div className="earnmoney-page p-3 mt-4">
        <h1 className="text-start">Live Session Help:</h1>
        <Table bordered>
          <thead>
            <tr className="table-bg">
              <th scope="col" className="text-start Questions-box">
                Live Sessions
              </th>
              <th scope="col" className="text-start rewards-box">
                Rewards
              </th>
              <th scope="col" />
              <th scope="col" className="Questions-box">
                You Got
              </th>
            </tr>
          </thead>
          <tbody className="vertical-align-middle">
            {liveSessionData.map((liveSessionData, index) => {
              // Calculate the progress percentage
              const progressPercentage =
                (liveSessionData.studentPost / liveSessionData.countPost) * 100;

              return (
                <tr key={index}>
                  <td className="text-start">{liveSessionData.streak}</td>
                  <td className="text-start">{liveSessionData.rewards}</td>
                  <td className="progress-box">
                    <div className="single-progress">
                      <div className="progress">
                        <div
                          className="progress-bar wow fadeInLeft"
                          style={{
                            width: `${progressPercentage}%`, // Set width based on progress percentage
                            visibility: "visible",
                            animationDuration: "0.5s",
                            animationDelay: "0.3s",
                            animationName: "fadeInLeft",
                          }}
                          role="progressbar"
                          aria-valuenow={progressPercentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                        <span className="progress-number">
                          {liveSessionData.studentPost}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    {/* Conditionally render button */}
                    {liveSessionData.countPost ===
                    liveSessionData.studentPost ? (
                      <Button className="myBtn" onClick={toggle}>
                        Get Reward
                      </Button>
                    ) : (
                      <Button className="myBtn myBtnDisabled" disabled>
                        Get Reward
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="terms_condition text-start">
          <p className="fw-bold">Terms &amp; Condition</p>
          <ul className="list-none">
            <li>Minimum Time of session must be 60 Minutes or above</li>
            <li>
              For only Live sessions done and when payment received then only
            </li>
            <li>For Live sessions which fulfill the criteria of DoubtQ</li>
            <li>
              This Reedemable criteria can be updated or change by DoubtQ
              anytime without any consent
            </li>
            <li>
              For every GET REWARD - Mail with the email id shown there and have
              to send the bank details where we can send your earnings amount or
              relevant gifts
            </li>
          </ul>
        </div>
      </div>
      {/* Model-Box */}
      <div id="myModal" className="modal">
        {/* Modal content */}
        <div className="modal-content">
          <span className="close">×</span>
          <div className="text-center">
            <img
              src="./assets/images/OBJECTS.png"
              height="200px"
              width="200px"
              alt=""
              srcSet=""
            />
            <p className="congratulation mt-3">CONGRATULATIONS</p>
            <p className="details">
              To Get the following Reward, Please Provide the Details <br />
              Given Below :
            </p>
            <div className="d-flex justify-content-around align-items-center form m-3">
              <input className="form-control" type="text" placeholder="Name" />
              <input
                className="form-control ms-5"
                type="text"
                placeholder="Residence Address"
              />
            </div>
            <div className="d-flex justify-content-around align-items-center form m-3">
              <input
                className="form-control"
                type="text"
                placeholder="Country"
              />
              <input
                className="form-control ms-5"
                type="text"
                placeholder="PayPal ID"
              />
            </div>
            <div className="d-flex justify-content-around align-items-center form m-3">
              <input
                className="form-control"
                type="text"
                placeholder="Phone No"
              />
              <input
                className="form-control ms-5"
                type="text"
                placeholder="Your Social Media link"
              />
            </div>
            <p className="details" style={{ backgroundColor: "#E2DCFF" }}>
              Please Provide the Detail to below emai ID &amp; <br />
              Whats app Number
            </p>
            <div className="d-flex justify-content-around align-items-center btn_design">
              <div>
                <button>
                  <img
                    src="./assets/images/8665305_envelope_email_icon 1.png"
                    alt=""
                    srcSet=""
                    className="pe-2"
                  />
                  Email
                </button>
              </div>
              <div>
                <button>
                  <img
                    src="./assets/images/Group.png"
                    alt=""
                    srcSet=""
                    className="pe-2"
                  />
                  Whatsapp
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} className="modal-get-reward">
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <div className="text-center">
            <img src={OBJECTS} height="200px" width="200px" alt="" srcSet="" />
            <p className="congratulation mt-3 fw-bold">CONGRATULATIONS</p>
            <p className="details">
              To Get the following Reward, Please Provide the Details <br />
              Given Below :
            </p>
            <Row>
              <Col md="6">
                <div className="reward-box-pop">
                  <span>Name</span>
                </div>
              </Col>
              <Col md="6">
                <div className="reward-box-pop">
                  <span>Address</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="reward-box-pop">
                  <span>PayPal ID</span>
                </div>
              </Col>
              <Col md="6">
                <div className="reward-box-pop">
                  <span>Country</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="reward-box-pop">
                  <span>Phone No</span>
                </div>
              </Col>
              <Col md="6">
                <div className="reward-box-pop">
                  <span>Your Social Media link</span>
                </div>
              </Col>
            </Row>
            {/* <div className="d-flex justify-content-around align-items-center form m-3">
                            <Input className="form-control" type="text" placeholder="Name" />
                            <Input
                                className="form-control ms-5"
                                type="text"
                                placeholder="Residence Address"
                            />
                        </div> */}
            {/* <div className="d-flex justify-content-around align-items-center form m-3">
                            <Input className="form-control" type="text" placeholder="Country" />
                            <Input
                                className="form-control ms-5"
                                type="text"
                                placeholder="PayPal ID"
                            />
                        </div> */}
            {/* <div className="d-flex justify-content-around align-items-center form m-3">
                            <Input className="form-control" type="text" placeholder="Phone No" />
                            <Input
                                className="form-control ms-5"
                                type="text"
                                placeholder="Your Social Media link"
                            />
                        </div> */}
            <p className="details mt-4" style={{ backgroundColor: "#E2DCFF" }}>
              Please Provide the Detail to below emai ID &amp; <br />
              Whats app Number
            </p>
            <div className="d-flex justify-content-around align-items-center btn_design mt-4">
              <div>
                <Link to={`mailto:${setting.contactEmail}`} target="_blank">
                  <button className="popup-button">
                    <img src={mail} alt="" className="icon-popup pe-2" />
                    Email
                  </button>
                </Link>
              </div>
              <div>
                <Link
                  to={`https://api.whatsapp.com/send?phone=${setting.contactNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="popup-button">
                    <img
                      src={whatsapp}
                      alt=""
                      srcSet=""
                      className="icon-popup pe-2"
                    />
                    Whatsapp
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Earnmoney;
