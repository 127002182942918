import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import "./CommonForm.scss";
// import HomeworkIcon from "../../../Assets/images/HomeworkIcon.png";
// import AssignmetIcon from "../../../Assets/images/AssignemtIcon.png";
// import LiveIcon from "../../../Assets/images/videoIcon.png";
import Button from "../../../Components/Buttons/Button";
import Authentication from "../../../Components/Popup/Authentication";
import { MultiStepProgressBar } from "../../../Components/Popup/MultiStepProgressBar";
import FormUI from "../../../Components/Popup/FormUI";
import { getCategoryWiseSubcategory } from "../../../services/getCategoryWiseSubcategory";
import { getSubcategoryWisePrice } from "../../../services/getSubcategoryWisePrice";
import { getAllSubject } from "../../../services/getAllSubject";
import { createQuestionHomeworkHelp } from "../../../services/createQuestionHomeworkHelp";
import { updateHomeworkHelpQuestion } from "../../../services/updateHomeworkHelpQuestion";
import { useNavigate } from "react-router-dom";
import { getMaxMinWords } from "../../../services/getMaxMinWords";
import { getHoursByWords } from "../../../services/getHoursByWords";
import { getPriceByWords } from "../../../services/getPriceByWords";
import { updateAssignmentQuestion } from "../../../services/updateAssignmentQuestion";
import { createQuestionAssignmentHelp } from "../../../services/createQuestionAssignmentHelp";
import { getPriceLivehelp } from "../../../services/getPriceLivehelp";
import { updateLiveSessionQuestion } from "../../../services/updateLiveSessionQuestion";
import { createQuestionLivesessionHelp } from "../../../services/createQuestionLivesessionHelp";
import { getCategories } from "../../../services/getCategories";
import { ImageLink } from "../../../constants";

const categoryStatic = [
  {
    label: "Homework Help",
    sublabel: "Ask your doubts here",
    serviceType: "HomeworkHelp",
    type: "mcqHelp",
    totalSteps: 3,
  },
  {
    label: "Assignment Help",
    sublabel: "Complete your assignments here",
    serviceType: "AssignmentHelp",
    type: "assignmentHelp",
    totalSteps: 4,
  },
  {
    label: "Live Session Help",
    sublabel: "Solve your questions instantly here",
    serviceType: "LiveSessionHelp",
    type: "livesessionHelp",
    totalSteps: 4,
  },
];

const assignmentHelpDefaultValue = {
  fontSize: "No",
  fontStyle: "No",
  lineSpacing: "No",
  referenceStyle: "No",
  haveReferenceFile: "No",
  deadline: 10,
};
const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const liveSessionDefaultValue = {
  haveReferenceFile: "No",
  date: `${new Date().getFullYear()}-${new Date().getMonth() < 10
    ? `0${new Date().getMonth() + 1}`
    : new Date().getMonth() + 1
    }-${new Date().getDate() < 9 ? `0${new Date().getDate()}` : new Date().getDate()
    }`,
  hours:
    new Date(new Date().setHours(new Date().getHours() + 4)).getHours() % 12 <
      10
      ? `0${new Date(new Date().setHours(new Date().getHours() + 4)).getHours() %
      12
      }`
      : new Date(new Date().setHours(new Date().getHours() + 4)).getHours() %
      12 || 12,
  minutes:
    new Date(new Date()).getMinutes() > 10
      ? new Date(new Date()).getMinutes()
      : `0${new Date(new Date()).getMinutes()}`,
  meradian: new Date(new Date()).getHours() < 12 ? "AM" : "PM",
  durationMintues: 60,
  timeZone: defaultTimezone,
};
const CommonForm = ({ isOpen, toggle, typeData = "", subCategory = "", question }) => {


  const [index, setIndex] = useState(1);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [currentType, setCurrentType] = useState(typeData);
  const [isQuestionSubmited, setIsQuestionSubmited] = useState(false);
  const [error, setError] = useState({});
  const [isError, setIsError] = useState(false);
  const [payload, setPayload] = useState({ subCategory });
  const [formData, setFormData] = useState({});
  const [rest, setRest] = useState({ setPayload });
  const [isPostButtonDisabled, setIsPostQuestionDisabled] = useState(false);
  const quillfRef = useRef(null);
  const navigate = useNavigate();
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };

  const [categorieData, setCategorieData] = useState([]);
  const fetchData = async () => {
    await getCategories()
      .then((data) => {
        const mergedArray = categoryStatic.map((obj1) => {
          const obj2 = data.data.find((obj) => obj.name === obj1.label);
          return { ...obj1, ...obj2 };
        });
        setCategorieData(mergedArray);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const closeBtn = (
    <button
      className="closBtn"
      onClick={toggleNested}
      style={{ zIndex: 1 }}
      type="button"
    >
      X
    </button>
  );

  const continueButton = () => {
    setPayload((prevstate) => ({
      ...prevstate,
      categoryId: "65524e633c005ef3b51907c9",
    }));
    localStorage.setItem("serviceType", currentType.serviceType);
    if (currentType.serviceType === "AssignmentHelp") {
      if (
        payload.subCategory === "6586dfe7d2f7eeadeea4fafa" ||
        payload.subCategory === "6586d9a9dafb1f4a8f461274"
      ) {
        delete payload.noOfWords;
        delete payload.min;
        delete payload.max;
      }
      if (!payload.subCategory && index === 1) {
        setIsError(true);
        setError((prevstate) => ({
          ...prevstate,
          selectedAssignment: "Assignment help type is required!",
        }));
      } else if (index === 2) {
        let isValid = { question: true, reference: true };

        if (
          (payload?.questionPDF?.length ? payload?.questionPDF?.length : 0) ===
          0 &&
          !payload?.question?.trim()?.length
        ) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            questionPDF: "Please add Question OR File!",
          }));
          isValid.question = false;
        } else {
          setError((prevstate) => ({
            ...prevstate,
            questionPDF: "",
          }));
          isValid.question = true;
        }

        if (
          payload.haveReferenceFile === "Yes" &&
          !payload.referencePDF?.length
        ) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            referencePDF: "Please add Reference File!",
          }));
          isValid.reference = false;
        } else {
          setError((prevstate) => ({
            ...prevstate,
            referencePDF: "",
          }));
          isValid.reference = true;
        }
        if (isValid.question && isValid.reference) {
          setIndex((prevIndex) => prevIndex + 1);
          setIsError(false);
        }
      } else if (index === 3) {
        if (!payload.subject) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            subject: "Please Select Subject",
          }));
        } else if (
          payload.referenceStyle === "Yes" &&
          !payload.referenceStyleType
        ) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            referenceStyleType: "Please Select Reference Style",
          }));
        } else if (payload.lineSpacing === "Yes" && !payload.lineSpacingType) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            lineSpacingType: "Please Select Line Spacing",
          }));
        } else if (payload.fontStyle === "Yes" && !payload.fontStyleType) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            fontStyleType: "Please Select Font Style",
          }));
        } else if (payload.fontSize === "Yes" && !payload.fontSizeType) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            fontSizeType: "Please Select Font Size",
          }));
        } else {
          setIndex((prevIndex) => prevIndex + 1);
          setIsError(false);
        }
      } else {
        if (index <= currentType.totalSteps - 1) {
          setIndex((prevIndex) => prevIndex + 1);
          setIsError(false);
        }
      }
    } else if (currentType.serviceType === "HomeworkHelp") {
      if (
        index === 1 &&
        (!payload.question || payload.question === "<p><br></p>")
      ) {
        setIsError(true);
        setError((prevstate) => ({
          ...prevstate,
          question: "Question is required!",
        }));
      } else if (index === 2 && (!payload.subject || !payload.questionType)) {
        if (!payload.subject) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            subject: "Subject is required!",
          }));
        }
        if (!payload.questionType) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            questionType: "Question Type is required!",
          }));
        }
      } else {
        if (index <= currentType.totalSteps - 1) {
          setIndex((prevIndex) => prevIndex + 1);
          setIsError(false);
        }
      }
    } else if (currentType.serviceType === "LiveSessionHelp") {
      if (index === 1 && !payload.subCategory) {
        setIsError(true);
        setError((prevstate) => ({
          ...prevstate,
          subCategory: "Select Any one Service",
        }));
      } else if (index === 2) {
        let isValid = { question: true, reference: true, subject: true };
        if (
          (payload?.questionPDF?.length ? payload?.questionPDF?.length : 0) ===
          0 &&
          !payload?.question?.trim()?.length
        ) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            questionPDF: "Please add Question OR File!",
          }));
          isValid.question = false;
        } else {
          setError((prevstate) => ({
            ...prevstate,
            questionPDF: "",
          }));
          isValid.question = true;
        }

        if (
          payload.haveReferenceFile === "Yes" &&
          !payload.referencePDF?.length
        ) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            referencePDF: "Please add Reference File!",
          }));
          isValid.reference = false;
        } else {
          setError((prevstate) => ({
            ...prevstate,
            referencePDF: "",
          }));
          isValid.reference = true;
        }
        if (!payload.subject) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            subject: "Please Select Subject",
          }));
          isValid.subject = false;
        } else {
          setError((prevstate) => ({
            ...prevstate,
            subject: "",
          }));
          isValid.subject = true;
        }
        if (isValid.question && isValid.reference && isValid.subject) {
          setIndex((prevIndex) => prevIndex + 1);
          setIsError(false);
        }
      } else {
        if (index <= currentType.totalSteps - 1) {
          setIndex((prevIndex) => prevIndex + 1);
          setIsError(false);
        }
      }
    }
  };

  const backButton = () => {
    if (index > 1) {
      setIndex((prevIndex) => prevIndex - 1);
      setIsError(false);
    }
  };

  const handleChange = async (event) => {
    if (currentType.serviceType === "HomeworkHelp") {
      setPayload((prevstate) => ({
        ...prevstate,
        [event.target.name]: event.target.value,
      }));
      if (event.target.value) {
        setIsError(false);
        setError((prevstate) => ({ ...prevstate, [event.target.name]: "" }));
      }
    } else if (currentType.serviceType === "AssignmentHelp") {
      if (event.target?.files && event.target?.files[0]) {
        const data = await handleUploadFile(
          Array.prototype.slice.call(event.target?.files),
          event.target.name
        );
        setPayload((prevstate) => ({
          ...prevstate,
          [event.target.name]: data,
        }));
      } else if (event.target.name === "noOfWords") {
        // setIsPostQuestionDisabled(true);
        const { value } = event.target;
        if (value === "" || parseInt(value) === 0) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            [event.target.name]: "Number must be greater than 0",
          }));
          setPayload((prevstate) => ({
            ...prevstate,
            [event.target.name]: event.target.value,
          }));
        } else if (
          /^\d+$/.test(value) &&
          parseInt(value) >= payload.min &&
          parseInt(value) < payload.max
        ) {
          setPayload((prevstate) => ({
            ...prevstate,
            [event.target.name]: event.target.value,
          }));
          setIsError(false);
          setError((prevstate) => ({
            ...prevstate,
            [event.target.name]: "",
          }));
        }
      } else {
        // if (event.target.name === "deadline") {
        //   setIsPostQuestionDisabled(true);
        // }
        setPayload((prevstate) => ({
          ...prevstate,
          [event.target.name]: event.target?.value,
        }));
        if (event.target.value) {
          setIsError(false);
          setError((prevstate) => ({ ...prevstate, [event.target.name]: "" }));
        }
      }
    } else if (currentType.serviceType === "LiveSessionHelp") {
      if (event.target?.files && event.target?.files[0]) {
        const data = await handleUploadFile(
          Array.prototype.slice.call(event.target?.files),
          event.target.name
        );
        setPayload((prevstate) => ({
          ...prevstate,
          [event.target.name]: data,
        }));
      } else if (event.target.name === "date") {
        const dateData = `${new Date(event.target.value).getFullYear()}-${new Date(event.target.value).getMonth() + 1
          }-${new Date(event.target.value).getDate()}`;
        setPayload((prevstate) => ({
          ...prevstate,
          [event.target.name]: dateData ?? "",
        }));
      } else if (event.target.name === "hours") {
        if (
          parseInt(event.target.value) > 0 &&
          parseInt(event.target.value) <= 12
        ) {
          setPayload((prevstate) => ({
            ...prevstate,
            [event.target.name]:
              parseInt(event.target.value) < 10
                ? `0${event.target.value}`
                : event.target.value,
          }));
        }
      } else if (event.target.name === "minutes") {
        if (
          parseInt(event.target.value) >= 0 &&
          parseInt(event.target.value) <= 60
        ) {
          setPayload((prevstate) => ({
            ...prevstate,
            [event.target.name]:
              parseInt(event.target.value) < 10
                ? `0${event.target.value}`
                : event.target.value,
          }));
        }
      } else if (event.target.name === "durationMintues") {
        const { value } = event.target;
        if (value === "" || value === 0) {
          setIsError(true);
          setError((prevstate) => ({
            ...prevstate,
            [event.target.name]: "Minutes must be greater than 1",
          }));
          setPayload((prevstate) => ({
            ...prevstate,
            [event.target.name]: event.target.value,
          }));
        } else if (
          parseInt(event.target.value) >= 0 &&
          parseInt(event.target.value) <= 2000
        ) {
          setPayload((prevstate) => ({
            ...prevstate,
            [event.target.name]: event.target.value,
          }));
          setIsError(false);
          setError((prevstate) => ({
            ...prevstate,
            [event.target.name]: "",
          }));
        }
      } else {
        setPayload((prevstate) => ({
          ...prevstate,
          [event.target.name]: event.target.value,
        }));
        if (event.target.value) {
          setIsError(false);
          setError((prevstate) => ({ ...prevstate, [event.target.name]: "" }));
        }
      }
    }
  };
  const handlePostQuestion = async () => {
    if (currentType.serviceType === "HomeworkHelp") {
      const response = await createQuestionHomeworkHelp(payload);
      if (response.isSuccess) {
        if (localStorage.getItem("token")) {
          const updatedQuestion = await updateHomeworkHelpQuestion(
            response.question._id,
            {}
          );
          if (updatedQuestion.isSuccess) {
            localStorage.removeItem("questionId");
            navigate(`/orderStatus/${response.question._id}`);
          }
        }
        setIsQuestionSubmited(true);
        localStorage.setItem("questionId", response.question._id);
      }
    } else if (currentType.serviceType === "AssignmentHelp") {
      const response = await createQuestionAssignmentHelp(payload);
      if (response.isSuccess) {
        if (localStorage.getItem("token")) {
          const updatedQuestion = await updateAssignmentQuestion(
            response.question._id,
            {}
          );
          if (updatedQuestion.isSuccess) {
            localStorage.removeItem("questionId");
          }
          navigate(`/orderStatus/${response.question._id}`);
        } else {
          setIsQuestionSubmited(true);
          localStorage.setItem("questionId", response.question._id);
        }
      }
    } else if (currentType.serviceType === "LiveSessionHelp") {
      const response = await createQuestionLivesessionHelp(payload);
      if (response.isSuccess) {
        if (localStorage.getItem("token")) {
          const updatedQuestion = await updateLiveSessionQuestion(
            response.question._id,
            {
              discountPrice: 0,
              promoPrice: 0,
              promoCode: "",
            }
          );
          if (updatedQuestion.isSuccess) {
            localStorage.removeItem("questionId");
          }
          navigate(`/orderStatus/${response.question._id}`);
        }
        setIsQuestionSubmited(true);
        localStorage.setItem("questionId", response.question._id);
      }
    }
  };

  const handlePopupType = (serviceType, type, totalSteps) => {
    setCurrentType({ serviceType, type, totalSteps });
    localStorage.setItem("serviceType", serviceType);
  };

  const fetchHomeworkCategory = async () => {
    const response = await getCategoryWiseSubcategory(
      "65524e5e3c005ef3b51907c6"
    );
    const activeSubcategory = response.subCategory?.filter((x) => x.isActive);
    const tempData = activeSubcategory?.map((x) => ({
      _id: x?._id,
      name: x?.name,
    }));
    setFormData((prevstate) => ({ ...prevstate, subcategory: tempData }));
    return response;
  };

  const fetchSubjectData = async () => {
    const response = await getAllSubject();
    setFormData((prevstate) => ({ ...prevstate, subject: response.data }));
  };

  const fetchCategories = async (categoryId) => {
    await getCategoryWiseSubcategory(categoryId)
      .then((data) => {
        setRest((prevState) => ({
          ...prevState,
          categories: data.subCategory,
        }));
      })
      .catch((err) => console.log(err));
  };

  const fetchQuestionPricingData = async (id) => {
    const response = await getSubcategoryWisePrice(id);
    setPayload((prevstate) => ({
      ...prevstate,
      price: response?.price?.studentPrice,
    }));
  };

  // To Add File in State
  const handleUploadFile = async (files, name) => {
    const uploaded = payload[name] ? [...payload[name]] : [];

    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    if (uploaded.length === 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    return uploaded;
  };

  // To remove File from state
  const handleRemoveFile = (index, key) => {
    const payloadClone = payload;
    if (payloadClone[key]) {
      payloadClone[key]?.splice(index, 1);
    }
    setPayload({ ...payloadClone });
  };

  // To Fetch Number of words of Subcategory
  const fetchWords = async (subCategoryId) => {
    const response = await getMaxMinWords(subCategoryId);
    // console.log(response.data);
    // setPayload({
    //   ...payload,
    //   noOfWords: response.data.minWords,
    //   max: response.data.maxWords,
    //   min: response.data.minWords,
    // });
    setPayload((prevPayload) => ({
      ...prevPayload,
      noOfWords: response.data.minWords,
      max: response.data.maxWords,
      min: response.data.minWords,
    }));
  };

  //  To Get Hours range of Subcategory
  const getHours = async () => {
    const response = await getHoursByWords({
      subCategoryId: payload.subCategory,
      words: payload.noOfWords ? Number(payload.noOfWords) : payload?.min,
    });
    if (response.isSuccess) {
      const hoursArray = response.hours?.reverse();
      setFormData((prevstate) => ({
        ...prevstate,
        hours: hoursArray,
      }));
    }
  };

  // To fetch Estimated Price of subcategory
  const getAssignmentPrice = async () => {
    // if (payload.deadline) {
    //   console.log("called");
    const response = await getHoursByWords({
      subCategoryId: payload.subCategory,
      words: payload.noOfWords ? Number(payload.noOfWords) : payload.min,
    });

    if (response.isSuccess) {
      const hoursData = response.hours?.reverse();
      setFormData((prevstate) => ({
        ...prevstate,
        hours: hoursData,
      }));
      let hours = payload.deadline;
      if (hoursData?.includes(parseInt(hours))) {
        hours = payload.deadline;
      } else {
        hours = hoursData[Math.ceil(hoursData?.length / 2) ?? 0];
      }
      setPayload({ ...payload, deadline: hours });
      const data = await getPriceByWords({
        subCategoryId: payload.subCategory,
        words: payload.noOfWords ? Number(payload.noOfWords) : payload.min,
        hours: hours,
      });

      if (data.isSuccess) {
        setFormData((prevstate) => ({
          ...prevstate,
          estimatedPrice: data?.price?.estimatedPrice,
        }));
        setIsPostQuestionDisabled(false);
      }
      //   }
    }
  };

  const getLiveSessionPrice = async () => {
    setIsPostQuestionDisabled(true);
    const response = await getPriceLivehelp({
      durationMintues: payload.durationMintues,
    });
    if (response.isSuccess) {
      setFormData((prevstate) => ({
        ...prevstate,
        studentPrice: response?.price?.studentPrice,
      }));
    }
    setTimeout(() => {
      setIsPostQuestionDisabled(false);
    }, 100);
  };

  // console.log(isPostButtonDisabled, "isPostButtonDisabled");
  useEffect(() => {
    if (currentType.serviceType === "HomeworkHelp") {
      fetchHomeworkCategory();
      fetchSubjectData();
    } else if (currentType.serviceType === "AssignmentHelp") {
      fetchCategories("65524e633c005ef3b51907c9");
      fetchSubjectData();
      setPayload({ ...payload, ...assignmentHelpDefaultValue });
      setRest({ ...rest, handleRemoveFile });
    } else if (currentType.serviceType === "LiveSessionHelp") {
      fetchSubjectData();
      fetchCategories("65524e353c005ef3b51907c2");
      setPayload({ ...payload, ...liveSessionDefaultValue });
    }
  }, [currentType]);

  useEffect(() => {
    if (payload.questionType) {
      fetchQuestionPricingData(payload.questionType);
    }
  }, [payload.questionType]);

  useEffect(() => {
    if (
      currentType.serviceType === "AssignmentHelp" &&
      payload.subCategory &&
      payload.subCategory !== "6586dfe7d2f7eeadeea4fafa" &&
      payload.subCategory !== "6586d9a9dafb1f4a8f461274"
    ) {
      fetchWords(payload.subCategory);
    }
  }, [payload.subCategory, currentType.serviceType]);

  // useEffect(() => {
  //   if (payload.noOfWords) {
  //     getHours();
  //   }
  // }, [payload.noOfWords]);

  useEffect(() => {
    setRest({ ...rest, handleRemoveFile });
  }, [payload]);

  useEffect(() => {
    let timeoutId;

    const delayedAssignmentPrice = () => {
      setIsPostQuestionDisabled(true);
      timeoutId = setTimeout(getAssignmentPrice, 500);
    };

    if (payload.noOfWords) {
      delayedAssignmentPrice();
    }

    return () => clearTimeout(timeoutId);
  }, [payload.deadline, payload.noOfWords]);

  useEffect(() => {
    if (payload.durationMintues) {
      getLiveSessionPrice();
    }
  }, [payload.durationMintues]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} fullscreen>
        {isQuestionSubmited ? <div className="authentication-bg"></div> : null}
        <ModalHeader close={closeBtn} className="border-0"></ModalHeader>
        <ModalBody>
          <Col className="mx-auto pop-width" xs="12" md="7">
            {!currentType ? (
              <div className="d-flex flex-column">
                <div className="mainHeading">
                  Types of Academic help you need.
                </div>
                <div className="d-flex row mt-5 g-3">
                  {categorieData.map((category) => {
                    return (
                      category.isActive === true && (
                        <div className="col category-card p-5 d-flex rounded-3  flex-column align-items-center justify-content-between">
                          <div>
                            <img
                              src={ImageLink(category.icon)}
                              alt="Icon"
                              className="w-50"
                            />
                          </div>
                          <div className="category-label">{category.name}</div>
                          <div className="category-sublabel mb-2">
                            {category.description}
                          </div>
                          <Button
                            className="mb-3"
                            text={"Continue"}
                            onClick={() =>
                              handlePopupType(
                                category.serviceType,
                                category.type,
                                category.totalSteps
                              )
                            }
                          />
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            ) : (
              <>
                {!isQuestionSubmited ? (
                  <>
                    <Row className="m-3">
                      <Col className="align-self-center">
                        <MultiStepProgressBar
                          step={index}
                          totalSteps={currentType.totalSteps}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {FormUI(
                        currentType?.type,
                        index,
                        payload,
                        handleChange,
                        formData,
                        rest,
                        quillfRef,
                        error,
                        isError,
                        setIsError,
                        question
                      )}
                    </Row>
                    {/* {rest.selectedAssignment ||
                type !== PopupTemplate.TEMPLATE_ASSIGNMENT ? ( */}
                    <Row className="mt-5">
                      <Col className="d-flex justify-content-center gap-5">
                        <Button
                          varient="outline-secondaty"
                          text="Back"
                          onClick={backButton}
                          disabled={index == 1}
                        />
                        {index !== currentType.totalSteps ? (
                          <Button
                            varient="linear-gradient-primary"
                            text="Continue"
                            onClick={continueButton}
                            disabled={isError || isPostButtonDisabled}
                          />
                        ) : (
                          <>
                            {payload.subCategory ===
                              "6586d9a9dafb1f4a8f461274" ||
                              payload.subCategory ===
                              "6586dfe7d2f7eeadeea4fafa" ? (
                              <Button
                                varient="linear-gradient-primary"
                                text={
                                  currentType.serviceType === "HomeworkHelp"
                                    ? "Post Question"
                                    : "Post Order"
                                }
                                onClick={handlePostQuestion}
                              />
                            ) : (
                              <Button
                                varient="linear-gradient-primary"
                                text={
                                  currentType.serviceType === "HomeworkHelp"
                                    ? "Post Question"
                                    : "Post Order"
                                }
                                onClick={handlePostQuestion}
                                disabled={isError || isPostButtonDisabled}
                              />
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                    {/* ) : null} */}
                  </>
                ) : (
                  <Authentication key={"signin"} />
                )}
              </>
            )}
            <Modal
              isOpen={nestedModal}
              toggle={toggleNested}
              centered
              className="rounded-5 rounded"
              onClosed={closeAll ? toggle : undefined}
            >
              <ModalBody>
                <div className="p-5">
                  <div className="nestedPopup">
                    Are you sure you want to leave this window?
                  </div>
                  <div className="pt-3 d-flex justify-content-end">
                    <span className="pe-3 resumeBtn" onClick={toggleNested}>
                      Resume
                    </span>
                    <span className="ps-3 exitBtn" onClick={toggle}>
                      Exit
                    </span>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CommonForm;
