import React from "react";
import "./WorldWide.scss";
import WorldWideMap from "../Assets/images/background/WorldWideMap.svg";
import Button from "./Buttons/Button";

const WorldWide = () => {
  return (

   
<div className="world-wide-container-bg">

<div className="world-wide-container">
      
      <div className="world-wide-img">
        <img src={WorldWideMap} alt="" />
      </div>
      <div className="world-wide-details">
        <h2 className="world-wide-details-title">
        <span>DoubtQ Academic Help</span> Shaping the FutureWorldwide
        </h2>
        <span className="world-wide-details-description">
          Start learning by registering for free
        </span>
        <span className="world-wide-details-details">
          Our Academic aid service is consistently ranked as one of the most
          sought-after offerings on a global scale. Up to this point, we have
          offered more than 500,000 different options. Our instructors clear up
          any confusion students may have about Academic help them have a firmer
          grip on the material.
        </span>
        {/* <Button text={'Do My Work'} varient='primary'/> */}
      </div>
    </div>
    </div>
  );
};

export default WorldWide;
