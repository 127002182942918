import React, { useEffect, useState } from "react";
import "./CounterSection.scss";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import tutorIcon from "../Assets/icons/tutor.svg";
import studentIcon from "../Assets/icons/student.svg";
import questionsIcon from "../Assets/icons/questions.svg";
import axiosInstance from "../axiosInterceptor";

const CounterSection = () => {
  const [setting, setSetting] = useState({
    tutorCounter: 0,
    studentCounter: 0,
    questionsCounter: 0,
  });

  const fetchSettingData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log("Error fetching setting data:", error);
    }
  };

  // const formatNumber = (value) => {
  //   if (value >= 1000000) {
  //     return (value / 1000000).toFixed(2) + 'M';
  //   } else if (value >= 1000) {
  //     return (value / 1000).toFixed(2) + 'K';
  //   } else {
  //     return value.toString();
  //   }
  // };

  useEffect(() => {
    fetchSettingData();
  }, []);

  return (
    <div className="counterSection">
      <div className="counter-wrapper">
        <div className="tutor-bubble">
          <img src={tutorIcon} alt="Tutor Icon" />
        </div>
        <div className="counter">
          <Odometer value={setting.tutorCounter} format="(,ddd)" />
          <div className="counter-label">Tutors</div>
        </div>
      </div>
      <div className="counter-wrapper">
        <div className="tutor-bubble">
          <img src={studentIcon} alt="Student Icon" />
        </div>
        <div className="counter">
          <Odometer value={setting.studentCounter} format="(,ddd)" />
          <div className="counter-label">Students</div>
        </div>
      </div>
      <div className="counter-wrapper">
        <div className="tutor-bubble">
          <img src={questionsIcon} alt="Questions Icon" />
        </div>
        <div className="counter">
          <Odometer value={setting.questionsCounter} format="(,ddd)" />
          <div className="counter-label">Questions Solved</div>
        </div>
      </div>
    </div>
  );
};

export default CounterSection;
