import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import Button from "../Buttons/Button";
import { sendOTP } from "../../services/sendOtp";
import { verifyOTP } from "../../services/verifyOtp";
import { emailRegister } from "../../services/emailRegister";

const SignUp = ({ countries, setPageToShow }) => {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSendOTP = async () => {
    const response = await sendOTP({ email: formData.email });
    if (response.isSuccess) {
      setError({ ...error, otp: "" });
    } else {
      setError({ ...error, otp: response.error });
    }
  };

  const handleVerfiyOtp = async () => {
    const response = await verifyOTP({
      email: formData.email,
      otpCode: formData.otp,
    });
    if (response.isSuccess) {
      setFormData({ ...formData, isOtpVerified: true });
      setError({ ...error, verify: "" });
    } else {
      setError({ ...error, verify: response.message });
    }
  };

  const handleStudentRegister = async () => {
    const response = await emailRegister({
      email: formData?.email,
      otpCode: Number(formData?.otp),
      countryId: formData?.country,
      referralCode: formData?.referralCode,
    });
    if (response.isSuccess) {
      setPageToShow("login");
    } else {
      setError({ ...error, register: response.message });
    }
  };

  return (
    <Col xs={12} md={8}>
      <Row className="justify-content-center pt-5 text-start">
        <Col xs={8}>
          <Label>
            <b>
              <small className="fs-4 mx-2">Email Id</small>
            </b>
          </Label>
          <InputGroup className="rounded-end-5 overflow-hidden">
            <Input
              placeholder="Enter Email"
              className="fs-3 p-4 rounded-start-4"
              name="email"
              type="email"
              disabled={formData.isOtpVerified}
              onChange={handleChange}
            />
            <InputGroupText className="authentication-form-input-group p-0 overflow-hidden">
              <Button
                varient="linear-gradient-primary"
                text={"OTP"}
                onClick={handleSendOTP}
                disabled={!formData?.email?.length || formData.isOtpVerified}
                className="rounded-0 h-100 px-5 py-4"
              />
            </InputGroupText>
          </InputGroup>
          {error?.otp ? <div className="text-danger">{error.otp}</div> : <></>}
          <Label className="pt-5">
            <b>
              <small className="fs-4 mx-2">OTP</small>
            </b>
          </Label>
          <InputGroup className="rounded-end-5 overflow-hidden">
            <Input
              placeholder="Enter OTP"
              className="fs-3 p-4 rounded-start-4"
              name="otp"
              type="number"
              disabled={formData.isOtpVerified}
              onChange={handleChange}
            />
            <InputGroupText className="authentication-form-input-group p-0 overflow-hidden">
              <Button
                varient="linear-gradient-primary"
                text={"Verify"}
                disabled={!formData.otp?.length || formData.isOtpVerified}
                onClick={handleVerfiyOtp}
                className="rounded-0 h-100 px-5 py-4"
              />
            </InputGroupText>
          </InputGroup>
          {error?.verify ? (
            <div className="text-danger">{error.verify}</div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center py-5">
        <Col xs={8}>
          <Row>
            <Col xs={12}>
              <Label>
                <b>
                  <small className="fs-4 text-Country">Country</small>
                </b>
              </Label>
              <InputGroup>
                <Input
                  type="select"
                  placeholder="Select Country"
                  name="country"
                  onChange={handleChange}
                >
                  <option disabled selected>
                    Select Country
                  </option>
                  {countries?.map((country) => {
                    return (
                      <option key={country.countryCode} value={country._id}>
                        {country.countryName}
                      </option>
                    );
                  })}
                </Input>
              </InputGroup>
            </Col>
            {/* <Col xs={6}>
              <Label>
                <b>
                  <small className="fs-4">Referral code</small>
                </b>
              </Label>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Enter Referral code"
                  className="fs-3 p-3"
                  name="referralCode"
                  onChange={handleChange}
                />
              </InputGroup>
            </Col> */}
          </Row>
        </Col>
      </Row>
      {error?.register ? (
        <div className="text-danger text-center">{error.register}</div>
      ) : (
        <></>
      )}
      <Row className="justify-content-center pb-5">
        <Col xs={8}>
          <Row>
            {/* <Col xs={6}>
              <button
                className="button cursor-pointer button-secondary w-100 p-4 d-flex align-items-center justify-content-center border-1 border border-gray gap-4"
                type="button"
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2707_8288)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.60698 13.0002C5.60698 12.1558 5.74714 11.3462 5.99759 10.5869L1.61619 7.24121C0.76225 8.97488 0.28125 10.9285 0.28125 13.0002C0.28125 15.0703 0.761844 17.0225 1.61436 18.7551L5.99333 15.403C5.74531 14.6471 5.60698 13.8405 5.60698 13.0002Z"
                      fill="#FBBC05"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.2929 5.31822C15.1273 5.31822 16.7842 5.96822 18.0861 7.03178L21.8731 3.25C19.5654 1.24089 16.6067 0 13.2929 0C8.14817 0 3.72655 2.94206 1.60938 7.241L5.99058 10.5867C7.00011 7.52233 9.87778 5.31822 13.2929 5.31822Z"
                      fill="#EA4335"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.2929 20.6815C9.87798 20.6815 7.00031 18.4774 5.99078 15.4131L1.60938 18.7581C3.72655 23.0577 8.14817 25.9998 13.2929 25.9998C16.4682 25.9998 19.4998 24.8722 21.775 22.7597L17.6162 19.5446C16.4428 20.2838 14.965 20.6815 13.2929 20.6815Z"
                      fill="#34A853"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.7235 13.0003C25.7235 12.2321 25.605 11.4047 25.4275 10.6367H13.2969V15.6594H20.2795C19.9303 17.3719 18.9801 18.6884 17.6202 19.5452L21.779 22.7602C24.1689 20.5421 25.7235 17.2377 25.7235 13.0003Z"
                      fill="#4285F4"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2707_8288">
                      <rect width="26" height="26" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <b>Google</b>
              </button>
            </Col> */}
            <Col xs={12}>
              <Button
                text={"Sign Up"}
                varient="linear-gradient-primary"
                className="px-5 py-4 h-100 w-100"
                onClick={handleStudentRegister}
                disabled={!formData.isOtpVerified || !formData.country}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={8}>
          <Row className="justify-content-center">
            <span className="fs-2 text-center">
              Already have an account? {""}
              <Link
                className="text-decoration-none"
                style={{ color: "#5956E9" }}
                onClick={() => setPageToShow("login")}
              >
                Sign in
              </Link>
            </span>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={8}>
          <Row className="justify-content-center pt-5">
            <span className="fs-3 text-center">
              By Clicking Sign up, You agree our{" "}
              <Link
                to="/terms-and-conditions"
                className=""
                style={{ color: "black" }}
              >
                Terms & Conditions
              </Link>
              ,{" "}
              <Link to="/refund-policy" className="" style={{ color: "black" }}>
                Refund Policy
              </Link>{" "}
              &{" "}
              <Link
                to="/privacy-policy"
                className=""
                style={{ color: "black" }}
              >
                Privacy Policy
              </Link>
            </span>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default SignUp;
