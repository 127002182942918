import React, { useState } from "react";
import Header from "../../../Components/Header";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import BenefitsSection from "../../../Components/BenefitsSection";
import HelpSteps from "../../../Components/HelpSteps";
import BonusSection from "../../../Components/BonusSection";
import StudentStories from "../../../Components/StudentStories";
import ChatHelpExperts from "../../../Components/ChatHelpExperts";
import Footer from "../../../Components/Footer";
import WorldWide from "../../../Components/WorldWide";
// import { getSubcategoryWiseContent } from "../../../services/getSubcategoryWiseContent";
// import Popup from "../../../Components/Popup/Popup";
// import { useNavigate } from "react-router-dom";
// import { getAllSubject } from "../../../services/getAllSubject";
// import { getCategoryWiseSubcategory } from "../../../services/getCategoryWiseSubcategory";
// import { getSubcategoryWisePrice } from "../../../services/getSubcategoryWisePrice";
// import { getHoursByWords } from "../../../services/getHoursByWords";
// import { getPriceByWords } from "../../../services/getPriceByWords";
// import { createQuestionAssignmentHelp } from "../../../services/createQuestionAssignmentHelp";
// import { updateAssignmentQuestion } from "../../../services/updateAssignmentQuestion";
// import { getMaxMinWords } from "../../../services/getMaxMinWords";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";

const ProjectLabReportWriting = () => {
  const subCategoryId = "6586d9a9dafb1f4a8f461274";
  // const [pageData, setPageData] = useState([]);

  // const fetchData = async () => {
  //   await getSubcategoryWiseContent(subCategoryId)
  //     .then((data) => {
  //       setPageData(data);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const defaultValue = {
  //   fontSize: "No",
  //   fontStyle: "No",
  //   lineSpacing: "No",
  //   referenceStyle: "No",
  //   haveReferenceFile: "No",
  //   subCategory: subCategoryId,
  //   categoryId: "65524e633c005ef3b51907c9",
  // };

  // const [categories, setCategories] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  // const [payload, setPayload] = useState(defaultValue);
  // const [formData, setFormData] = useState({});
  // const [selectedAssignment, setSelectedAssignment] = useState(null);
  // const navigate = useNavigate();
  // const [isQuestionSubmited, setIsQuestionSubmited] = useState(false);
  // const [error, setError] = useState();
  // const [isError, setIsError] = useState(false);
  const toggle = () => {
    // setIsQuestionSubmited(false);
    setIsModelOpen(!isModelOpen);
    // setSelectedAssignment(null);
    // setPayload(defaultValue);
  };
  // const totalSteps = 4;

  // const fetchSubjectData = async () => {
  //   const response = await getAllSubject();
  //   setFormData((prevstate) => ({ ...prevstate, subject: response.data }));
  // };

  // const fetchWords = async () => {
  //   const response = await getMaxMinWords(subCategoryId);
  //   // console.log(response.data);
  //   setPayload({
  //     ...payload,
  //     noOfWords: response.data.minWords,
  //     max: response.data.maxWords,
  //     min: response.data.minWords,
  //   });
  // };

  // useEffect(() => {
  //   fetchData();
  //   fetchSubjectData();
  //   fetchCategories();
  //   // fetchWords();
  //   // fetchHomeworkCategory();
  // }, []);

  // const fetchCategories = async () => {
  //   await getCategoryWiseSubcategory("65524e633c005ef3b51907c9")
  //     .then((data) => {
  //       setCategories(data.subCategory);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const fetchQuestionPricingData = async (id) => {
  //   const response = await getSubcategoryWisePrice(id);
  //   setPayload((prevstate) => ({
  //     ...prevstate,
  //     price: response?.price?.studentPrice,
  //   }));
  // };

  // const handleUploadFile = async (files, name) => {
  //   const uploaded = payload[name] ? [...payload[name]] : [];

  //   files.some((file) => {
  //     if (uploaded.findIndex((f) => f.name === file.name) === -1) {
  //       uploaded.push(file);
  //     }
  //   });
  //   if (uploaded.length === 0) {
  //     setIsError(true);
  //   } else {
  //     setIsError(false);
  //   }
  //   return uploaded;
  // };

  // const handleChange = async (event) => {
  //   if (event.target?.files && event.target?.files[0]) {
  //     const data = await handleUploadFile(
  //       Array.prototype.slice.call(event.target?.files),
  //       event.target.name
  //     );
  //     setPayload((prevstate) => ({
  //       ...prevstate,
  //       [event.target.name]: data,
  //     }));
  //   } else if (event.target.name === "noOfWords") {
  //     const { value } = event.target;
  //     if (value === "" || parseInt(value) === 0) {
  //       setIsError(true);
  //       setError((prevstate) => ({
  //         ...prevstate,
  //         [event.target.name]: "Number must be greater than 0",
  //       }));
  //       setPayload((prevstate) => ({
  //         ...prevstate,
  //         [event.target.name]: event.target.value,
  //       }));
  //     } else if (/^\d+$/.test(value) && parseInt(value) <= payload.max) {
  //       setPayload((prevstate) => ({
  //         ...prevstate,
  //         [event.target.name]: event.target.value,
  //       }));
  //       setIsError(false);
  //       setError((prevstate) => ({
  //         ...prevstate,
  //         [event.target.name]: "",
  //       }));
  //     }
  //   } else {
  //     setPayload((prevstate) => ({
  //       ...prevstate,
  //       [event.target.name]: event.target.value,
  //     }));
  //     if (event.target.value) {
  //       setIsError(false);
  //       setError((prevstate) => ({ ...prevstate, [event.target.name]: "" }));
  //     }
  //   }
  // };

  // const handleRemoveFile = (index, key) => {
  //   const payloadClone = payload;
  //   if (payloadClone[key]) {
  //     payloadClone[key]?.splice(index, 1);
  //   }
  //   setPayload({ ...payloadClone });
  // };

  // const getHours = async () => {
  //   const response = await getHoursByWords({
  //     subCategoryId: subCategoryId,
  //     words: payload.noOfWords ? Number(payload.noOfWords) : 0,
  //   });
  //   if (response.isSuccess) {
  //     const hoursArray = response.hours?.reverse();
  //     setFormData((prevstate) => ({
  //       ...prevstate,
  //       hours: hoursArray,
  //     }));
  //   }
  // };

  // useEffect(() => {
  //   if (payload.noOfWords) {
  //     getHours();
  //   }
  // }, [payload.noOfWords]);

  // const getAssignmentPrice = async () => {
  //   const response = await getHoursByWords({
  //     subCategoryId: subCategoryId,
  //     words: payload.noOfWords ? Number(payload.noOfWords) : payload.min,
  //   });

  //   if (response.isSuccess) {
  //     const hoursData = response.hours?.reverse();
  //     setFormData((prevstate) => ({
  //       ...prevstate,
  //       hours: hoursData,
  //     }));
  //     let hours = payload.deadline;
  //     if (hoursData?.includes(parseInt(hours))) {
  //       hours = payload.deadline;
  //     } else {
  //       hours = hoursData[Math.ceil(hoursData?.length / 2) ?? 0];
  //     }
  //     setPayload({ ...payload, deadline: hours });

  //     const data = await getPriceByWords({
  //       subCategoryId: subCategoryId,
  //       words: payload.noOfWords ? Number(payload.noOfWords) : 0,
  //       hours: hours,
  //     });

  //     if (data.isSuccess) {
  //       setFormData((prevstate) => ({
  //         ...prevstate,
  //         estimatedPrice: data?.price?.estimatedPrice,
  //       }));
  //     }
  //     //   }
  //   }
  // };

  // useEffect(() => {
  //   let getData = () => {};
  //   if (payload.noOfWords) {
  //     getData = setTimeout(() => {
  //       getAssignmentPrice();
  //     }, 2000);
  //   }
  //   return () => clearTimeout(getData);
  // }, [payload.deadline, payload.noOfWords]);

  // const handlePostQuestion = async () => {
  //   const response = await createQuestionAssignmentHelp(payload);
  //   if (response.isSuccess) {
  //     if (localStorage.getItem("token")) {
  //       const updatedQuestion = await updateAssignmentQuestion(
  //         response.question._id,
  //         {}
  //       );
  //       if (updatedQuestion.isSuccess) {
  //         localStorage.removeItem("questionId");
  //         navigate(`/orderStatus/${response.question._id}`);
  //       }
  //     } else {
  //       setIsQuestionSubmited(true);
  //       localStorage.setItem("questionId", response.question._id);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (payload.questionType) {
  //     fetchQuestionPricingData(payload.questionType);
  //   }
  // }, [payload.questionType]);

  return (
    <>
      <Header />
      <MainBanner id={subCategoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection
        onSignUp={() => {
          // setIsModelOpen(true);
          // setIsQuestionSubmited(true);
        }}
      />
      <BenefitsSection
        subCategoryId={subCategoryId}
        onClick={() => {
          setIsModelOpen(true);
        }}
      />
      <HelpSteps
        subCategoryId={subCategoryId}
        // stepsData={pageData?.helpStepData && pageData?.helpStepData[0]}
      />
      <BonusSection
        subCategoryId={subCategoryId}
        onClick={() => {
          setIsModelOpen(true);
        }}
      />
      <StudentStories />
      <ChatHelpExperts />
      <WorldWide />
      <Footer />
      {/* {isModelOpen ? (
        <Popup
          key={subCategoryId}
          isOpen={isModelOpen}
          toggle={toggle}
          totalSteps={totalSteps}
          payload={payload}
          handleChange={handleChange}
          formData={formData}
          type="assignmentHelp"
          serviceType="AssignmentHelp"
          handlePostQuestion={handlePostQuestion}
          isQuestionSubmited={isQuestionSubmited}
          categories={categories}
          setSelectedAssignment={setSelectedAssignment}
          selectedAssignment={selectedAssignment}
          setPayload={setPayload}
          handleRemoveFile={handleRemoveFile}
          isError={isError}
          setIsError={setIsError}
          error={error}
          setError={setError}
        />
      ) : (
        <></>
      )} */}
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "AssignmentHelp",
            type: "assignmentHelp",
            totalSteps: 4,
          }}
          subCategory={subCategoryId}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
    </>
  );
};

export default ProjectLabReportWriting;
