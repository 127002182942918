import React, { useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Container, Input, Row } from 'reactstrap'

import search from '../../Assets/images/search.png'
import fabBg from '../../Assets/images/faqbg.svg'

const DashboardFaq = () => {

    const [open, setOpen] = useState('1');

    const toggle = (id) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    const accordionData = [
        {
            id: 1,
            question: "How do I create my account?",
            answer: "To join, visit www.DoubtQ.com, and choose 'Sign Up' in the right-hand menu bar or 'sign up now' in the white space just to the right of the 'sign in' button. If you click on it, you'll be redirected to a new web page. Simply complete the necessary fields now."
        },
        {
            id: 2,
            question: "Can anybody see my identity?",
            answer: "No. We never reveal our customers' personal information to outside parties. Your information will be kept confidential and will not be shared with any third parties outside of tutorbin.com. Have a look at what we have to say about privacy if you're interested."
        },
        {
            id: 3,
            question: "How do I delete my account?",
            answer: "Please contact us and ask that your account be removed. You will no longer have access to your account and it will be permanently removed from our system."
        },
        {
            id: 4,
            question: "Can you help in exam or quiz?",
            answer: "While we can't assist you during the actual exam, we can help you get ready for it. Prepare for your exam with the help of our professional tutors and get access to 100% of the questions that have already been answered. Moreover, you can consult with our specialists in a given area and have any questions you have answered instantly."
        },
        {
            id: 5,
            question: "How do I ensure that the solutions I receive are correct?",
            answer: "All of DOUBTQ's online tutors are thoroughly vetted to ensure their expertise in their respective fields. Before submitting the answers, our tutors double-check them to make sure they are accurate"
        },
        {
            id: 6,
            question: "What is the importance of reference material?",
            answer: "Tutors can get a head start on answering students' questions by reading up on the topics and techniques relevant to those problems in advance. This gives them a tool to make sure the answers they give are straightforward and presented in a consistent manner."
        },
        {
            id: 7,
            question: "How much does DOUBTQ charge for answering a question?",
            answer: "Our charges shift based on a number of factors. We set prices based on the nature of the questions asked, how long they are, what category they fall under, and how difficult they are."
        },
        {
            id: 8,
            question: "Can I submit multiple questions at once?",
            answer: "YES, in this regard, we require each student to submit multiple question at a time. However, we do not discourage students from posing as many questions as they want in the sake of enhancing their own education. It is possible to publish all of the questions at once so that quick answers may be provided."
        },
        {
            id: 9,
            question: "How earliest can I get solution of uploaded questions?",
            answer: "We realize the significance of responding promptly. Timeliness of submissions is a major priority for all of our experts. Because of this, we promise that all solutions will be delivered before the date and time specified by us. Depending on the amount of work and the availability of the tutors, we may even be able to turn around the solutions in a matter of hours. We promise to submit all solutions as soon as possible."
        },
        {
            id: 10,
            question: "How to contact customer care of DOUBTQ?",
            answer: "At DOUBTQ, we offer round the clock customer assistance. You can also reach out to us at info@doubtq.com or our WhatsApp Number."
        },
        {
            id: 11,
            question: "What all services DOUBTQ provide?",
            answer: "DOUBTQ provides a variety of options for online tutoring to meet your unique requirements by asking your academic questions and doubts. Confer with the subject matter experts of your choosing in real time to get your questions answered. Provide assignment solving for qualified tutors and receive answers by the due date you set."
        },
        {
            id: 12,
            question: "Does DOUBTQ offer free trial?",
            answer: "DOUBTQ doesn't offer any free trial as we value the time and the efforts subjects matter experts put into your doubts and questions to make them 100% unique. Our charges are affordable. We also offer exciting discounts and reward points to students to ensure they don't have to pay a high price. "
        },
        {
            id: 13,
            question: "Does DOUBTQ have fixed price for questions?",
            answer: "Prices for all our services are variable. They depend upon the nature of the service, the type of question category you ask, Assignment category you ask and the length of the queries."
        },
        {
            id: 14,
            question: "Is it legal to take study help from DOUBTQ?",
            answer: "No law forbids you from seeking out and using the services of experts in the field, as academic assistance is a perfectly legal service. No copied material was used in the creation of any of our answers. Our services are intended solely to help students succeed in their studies."
        },
        {
            id: 15,
            question: "What is the payment structure for students at DOUBTQ?",
            answer: "DOUBTQ's payment options are versatile, allowing students to pay for answers at their convenience. Payment for each inquiry is typically based on the question category and the question's degree of difficulty."
        },
        {
            id: 16,
            question: "Are DOUBTQ solutions affordable?",
            answer: "DOUBTQ always aims to offer the most competitive pricing to its customers. We keep our prices low because we know that students are our target audience. To get answers to your homework questions and doubts, you need not spend a lot of money. In any case, we are unable to promise that our prices are lower than those of competing sites. If you give us your money, we'll give you solutions written by experts in your field that are guaranteed to be correct and original."
        },
        {
            id: 17,
            question: "What if I need 2 different copies of same submitted questions?",
            answer: "When it comes to online tutoring, we at DOUBTQ want to make sure you're covered no matter what. Just post the same question again if you require two separate sets of answers to the same problem. If you have more than one question, we'll pair you with two different experts who will each answer your question independently and provide you with a plagiarism-free, fully accurate solution"
        },
        {
            id: 18,
            question: "How long it will take to get solutions?",
            answer: "Before you submit, we will do everything we can to clear up any confusion you may have. Because of this, we insist that all tutors complete their work by the respective deadlines we set for each question type, and we guarantee that you will receive the answers in a timely manner. We'll do our best to get you the answers within a few minutes, but we can't promise we'll make the promised deadline."
        },
        {
            id: 19,
            question: "Can I review and check previous work?",
            answer: "We believe in 100% client satisfaction and thus, we encourage them to review their work. We offer a period of 24hours where you can put in any query for your subject matter expert through the chat feature and review your doubt history in “your work” section. Additionally, all solutions you receive via DOUBTQ will be available on your dashboard for Life time for your reference."
        },
        {
            id: 20,
            question: "Can I raise a query if my solution is not, correct?",
            answer: "It is our goal to provide you with answers that are both original and accurate. As a result of the depth of knowledge in their respective fields possessed by all of our experts, we guarantee that you will almost never receive inaccurate information. But if you think the answers, you get are wrong, you will get a option of Re-answer. Please review the answer within 12 hours. Post 48 hours, please feel free to reach out to us at info@doubtq.com if there is any discrepancy in the solutions you receive."
        },
        {
            id: 21,
            question: "What to do if my question subjects are not listed in list?",
            answer: "We've made an effort to provide information on a wide range of topics on this portal for your own ease of use, but this is by no means an exhaustive list. But if your topic isn't there, don't worry; just use the 'Other' option under 'Miscellaneous' to send in your question."
        },
        {
            id: 22,
            question: "Is it compulsory to give rating after my question answered?",
            answer: "No, it is not compulsory to give rating to your answered question but we want students to encourage our high qualified subject matter expert by giving their valuable vote."
        },
        {
            id: 23,
            question: "What are redeemable vouchers and how it could be used?",
            answer: "Redeemable vouchers are the Amount you receive when you achieve some targets in our posting streak which we made for our pride students. We want every student to be part of this wonderful feature we made. These vouchers can be redeemed or cash out through your redeemable amount section."
        },
        {
            id: 24,
            question: "What is minimum Amount for asking a question?",
            answer: "As we know that students are coming here to learn something regarding educational field and we don’t want any student to leave this platform due to high prices in questions. Thus, the minimum amount for asking a question is just $0.80."
        },
        {
            id: 25,
            question: "Can I post a question which belongs to different question type category?",
            answer: "No, you can’t post a question which belongs to different question type category. For instance, if question belongs to MCQs and you asked a question of T/F then you will receive a notification of update the question type category."
        },
        {
            id: 26,
            question: "Can I post a question in math’s subject who belongs to commerce field?",
            answer: "No, you don’t have to post a question which belongs to different subjects instead of my subject. If you do so then, you will receive a notification of update the subject category. So, that you receive the answer from respective subject expert only."
        },
        {
            id: 27,
            question: "Can I ask a question of Case study ($0.28) question category to MCQ ($0.18) category?",
            answer: "It is our goal to provide you with answers that are both original and accurate. As we offer you to review within 12 hours when question answered. After that questions will move on to close question which you can refer for long time access but cannot review for that."
        },

        {
            id: 28,
            question: "How much time I will get to review my answer?",
            answer: "No, you don’t have to post a question which belongs to different subjects instead of my subject. If you do so then, you will receive a notification of update the subject category. So, that you receive the answer from respective subject expert only."
        },

        {
            id: 29,
            question: "Can I try to give downvote to get new answer?",
            answer: "No, you cannot be too smart as in our system, once you downvote it will be deleted from your history and you cannot get the same answer to see again and again and by chance you can get another wrong answer but there is less chance of wrong answer as we have highly professionalized experts with us. Thus, try to give less downvotes."
        },
    ];


    return (
        <div>
            <div className='faq-top'>
                <Container className="w-85 dashboard">
                    <Row className='pt-5'>
                        <Col className="d-flex flex-column justify-content-start" lg={7}>
                            <h1 className='text-start pt-5 pb-3'>Frequently Asked Questions(FAQ)</h1>
                            <div className="position-relative search-input for-faq">
                                <Input
                                    placeholder="Search, solutions, and more...."
                                    className="py-4 search"
                                />
                                <div
                                    className="d-flex p-3 rounded-3 search-icon"
                                >
                                    <img src={search} alt="search-icon" />
                                </div>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <img className='faq-image' src={fabBg} alt="" />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='pt-5'>
                <Container className="w-85 dashboard">
                    {/* <h1 className='text-start mb-4'>Sign up Related Query</h1> */}
                    <h1 className='text-start mb-4'>Frequently Asked Questions(FAQ)</h1>
                    <Row>
                        <Accordion
                            open={open}
                            toggle={toggle}
                            className="accordian-Wrapper content-card mb-4 text-start"
                        >
                            {accordionData.map((item) => (
                                <AccordionItem key={item.id} className='mt-4'>
                                    <AccordionHeader targetId={item.id.toString()} className="heading">
                                        {item.question}
                                    </AccordionHeader>
                                    <AccordionBody accordionId={item.id.toString()}>
                                        {item.answer}
                                    </AccordionBody>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </Row>
                    {/* <h1 className='text-start mt-4'>Creating Order Related Query</h1> */}
                </Container>
            </div>
        </div>
    )
}

export default DashboardFaq