import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import Button from "../Buttons/Button";
import { Link, useNavigate } from "react-router-dom";
import { sendOTP } from "../../services/sendOtp";
import { verifyOTP } from "../../services/verifyOtp";
import { emailLogin } from "../../services/emailLogin";
import { updateHomeworkHelpQuestion } from "../../services/updateHomeworkHelpQuestion";
import axios from "axios";
import { emailRegister } from "../../services/emailRegister";

const SignIn = ({ countries, setPageToShow }) => {
  const [formData, setFormData] = useState({});
  const [timerState, setTimerState] = useState(0);
  const timerRef = useRef(null);
  const [isSignIn, setIsSignIn] = useState(false);
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const startTimer = () => {
    setTimerState(120);
    timerRef.current = setInterval(() => {
      setTimerState((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          stopTimer();
          return 0;
        }
      });
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
  };

  const handleSendOTP = async () => {
    setFormData({
      ...formData,
      isOtpSend: true,
    });
    setTimerState(120);
    startTimer();
    const response = await sendOTP({ email: formData.email });
    if (response.isSuccess) {
      setFormData({
        ...formData,
        isOtpSend: true,
        isNewUser: response.newUser,
      });
      setError({ ...error, otp: "" });
    } else {
      setError({ ...error, otp: response.error });
    }
  };

  const handleVerfiyOtp = async () => {
    const response = await verifyOTP({
      email: formData.email,
      otpCode: formData.otp,
    });
    if (response.isSuccess) {
      stopTimer(); // Stop the timer
      setFormData((prevstate) => ({ ...prevstate, isOtpVerified: true }));
      setError({ ...error, verify: "" });
    } else {
      setError({ ...error, verify: response.message });
    }
  };


  const handleLogin = async () => {
    // console.log(formData);
    // let ifNotNew = {
    //   email: formData.email,
    //   otpCode: Number(formData.otp),
    // };
    // let ifNew = {
    //   ...ifNotNew,
    //   countryId: formData?.country,
    //   referralCode: formData?.referralCode,
    // };

    // const response = await emailLogin(formData.isNewUser ? ifNew : ifNotNew);
    // if (response.isSuccess) {
    //   localStorage.setItem("token", response.authToken);
    //   setTimeout(async () => {
    //     setIsSignIn(true);
    //   }, [2000]);
    // }

    if (formData.isNewUser) {
      const response = await emailRegister({
        email: formData?.email,
        otpCode: Number(formData?.otp),
        countryId: formData?.country,
        referralCode: formData?.referralCode,
      });
      if (response.isSuccess) {
        localStorage.setItem("token", response.authToken);
        localStorage.setItem("email", formData.email);
        setTimeout(async () => {
          setIsSignIn(true);
        }, [100]);
      } else {
        setError({ ...error, register: response.message });
      }
    } else {
      const response = await emailLogin({
        email: formData.email,
        otpCode: Number(formData.otp),
      });
      if (response.isSuccess) {
        localStorage.setItem("token", response.authToken);
        localStorage.setItem("email", formData.email);
        setTimeout(async () => {
          setIsSignIn(true);
        }, [100]);
      }
    }
  };

  useEffect(() => {
    if (isSignIn) {
      const updateQuestion = async () => {
        const apiEndPoint = process.env.REACT_APP_API;
        const token = localStorage.getItem("token");
        if (token) {
          const questionId = localStorage.getItem("questionId");
          if (questionId) {
            if (localStorage.getItem("serviceType") === "HomeworkHelp") {
              axios
                .put(
                  `${apiEndPoint}student/homeworkHelp/updateQuestion/${questionId}`,
                  {
                    discountPrice: 0,
                    promoPrice: 0,
                    promoCode: "",
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  if (response.data.isSuccess) {
                    localStorage.removeItem("questionId");
                    navigate(`/orderStatus/${questionId}`);
                  }
                });
            } else if (
              localStorage.getItem("serviceType") === "AssignmentHelp"
            ) {
              axios
                .put(
                  `${apiEndPoint}student/assignmentHelp/updateQuestion/${questionId}`,
                  {
                    discountPrice: 0,
                    promoPrice: 0,
                    promoCode: "",
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  if (response.data.isSuccess) {
                    localStorage.removeItem("questionId");
                    navigate(`/orderStatus/${questionId}`);
                  }
                });
            } else if (
              localStorage.getItem("serviceType") === "LiveSessionHelp"
            ) {
              axios
                .put(
                  `${apiEndPoint}student/liveSessionHelp/updateQuestion/${questionId}`,
                  {
                    discountPrice: 0,
                    promoPrice: 0,
                    promoCode: "",
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  if (response.data.isSuccess) {
                    localStorage.removeItem("questionId");
                    navigate(`/orderStatus/${questionId}`);
                  }
                });
            }
          }
        }
      };
      updateQuestion();
    }
  }, [isSignIn]);

  return (
    <Col xs={12} md={8}>
      <Row className="justify-content-center pt-5">
        <Col xs={12} md={8}>
          <Label>
            <b>
              <small className="fs-5">Email Id</small>
            </b>
          </Label>
          <InputGroup className="rounded-end-4 overflow-hidden mb-3">
            <Input
              placeholder="Enter Id"
              className="fs-3 px-3 py-1 rounded-start-4"
              name="email"
              disabled={formData.isOtpVerified}
              onChange={handleChange}
            />
            <InputGroupText className="authentication-form-input-group p-0 overflow-hidden">
              <Button
                varient="linear-gradient-primary"
                text={formData.isOtpSend ? `${timerState} s` : "OTP"}
                disabled={
                  !formData?.email?.length ||
                  formData.isOtpVerified ||
                  formData.isOtpSend
                }
                onClick={handleSendOTP}
                className="rounded-0 h-100 px-5 py-4"
              />
            </InputGroupText>
          </InputGroup>
          {error?.otp ? <div className="text-danger">{error.otp}</div> : <></>}
          <Label className="pt-2">
            <b>
              <small className="fs-5">OTP</small>
            </b>
          </Label>
          <InputGroup className="rounded-end-4 overflow-hidden mb-3">
            <Input
              placeholder="Enter OTP"
              className="fs-3 py-1 rounded-start-4"
              name="otp"
              type="number"
              disabled={formData.isOtpVerified}
              onChange={handleChange}
            />
            <InputGroupText className="authentication-form-input-group p-0 overflow-hidden">
              <Button
                varient="linear-gradient-primary"
                text={formData.isOtpVerified ? "Verified" : "Verify"}
                disabled={!formData.otp?.length || formData.isOtpVerified}
                onClick={handleVerfiyOtp}
                className="rounded-0 h-100 px-5 py-4"
              />
            </InputGroupText>
          </InputGroup>
          {error?.verify ? (
            <div className="text-danger">{error.verify}</div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      {formData?.isNewUser ? (
        <Row className="justify-content-center py-4">
          <Col xs={12} md={8} className="text-start">
            <Row>
              <Col xs={12}>
                <Label>
                  <b>
                    <small className="fs-4">Country</small>
                  </b>
                </Label>
                <InputGroup style={{ height: "4.7rem" }}>
                  <Input
                    type="select"
                    placeholder="Select Country"
                    name="country"
                    className="fs-3"
                    onChange={handleChange}
                  >
                    <option disabled selected>
                      Select Country
                    </option>
                    {countries?.map((country) => {
                      return (
                        <option key={country.countryCode} value={country._id}>
                          {country.countryName}
                        </option>
                      );
                    })}
                  </Input>
                </InputGroup>
              </Col>
              {/* <Col xs={6}>
                <Label>
                  <b>
                    <small className="fs-4">Referral code</small>
                  </b>
                </Label>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Enter Referral code"
                    className="fs-3 p-3"
                    name="referralCode"
                    onChange={handleChange}
                  />
                </InputGroup>
              </Col> */}
            </Row>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <Row className="justify-content-center py-3">
        <Col xs={8}>
          <Row>
            <Col xs={12}>
              <Button
                text={"Sign In"}
                varient="linear-gradient-primary"
                onClick={handleLogin}
                disabled={
                  !formData.isOtpVerified || formData?.isNewUser
                    ? !formData.country
                    : false
                }
                className="w-100"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row className="justify-content-center">
        <Col xs={8}>
          <Row className="justify-content-center">
            <span className="fs-5 text-center">
              Don’t have an account? {""}
              <Link
                className="text-decoration-none"
                style={{ color: "#5956E9" }}
                onClick={() => setPageToShow("signup")}
              >
                Sign up
              </Link>
            </span>
          </Row>
        </Col>
      </Row> */}
      <Row className="justify-content-center">
        <Col xs={12}>
          <Row className="justify-content-center pt-1">
            <span className="text-center policy-text">
              By Clicking Sign in, You agree our{" "}
              <Link className="" style={{ color: "black" }}>
                Terms & Conditions
              </Link>
              ,{" "}
              <Link className="" style={{ color: "black" }}>
                Refund Policy & Privacy Policy
              </Link>
            </span>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default SignIn;
