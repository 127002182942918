import React, { useEffect, useState } from "react";
import WhatsappIcon from "../Assets/icons/whatsapp-icon.svg";
import emailIcon from "../Assets/icons/email-icon.svg";
import locationIcon from "../Assets/icons/location-footer.svg";
// import facebookIcon from '../Assets/icons/facebook-icon.svg'
// import instagramIcon from '../Assets/icons/instagram-icon.svg'
// import DownloadFromAppStore from '../Assets/images/background/get-it-on-app-store.svg'
// import DownloadFromPlayStore from '../Assets/images/background/get-it-on-google-play.svg'
import "./Footer.scss";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
const baseURL = process.env.REACT_APP_FILE_BASE_URL;

const Footer = () => {
  const [footerLinks, setFooterLinks] = useState([]);
  const [setting, setSetting] = useState([]);
  const [socialMedia, setsocialMedia] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axiosInstance.get(
        `/admin/category/getCategoryWiseSubcategory`
      );
      setFooterLinks(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSettingData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchsocialMediaData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/socialMedia/getSocialMedia`);
      setsocialMedia(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchSettingData();
    fetchsocialMediaData();
  }, []);

  const getSubCategoriesByCategory = (categoryName) => {
    const subCategory = footerLinks?.find(
      (x) => x?.categoryName === categoryName
    );
    return subCategory;
  };

  return (
    <div className="footer-section">
      <div className="footer-container container">
        <div className="row">
          <div className="footer-col col-sm-6 col-md-3 col-12">
            {getSubCategoriesByCategory("Homework Help") ? (
              <>
                <h3>Homework Help</h3>
                <ul className="p-0">
                  {getSubCategoriesByCategory(
                    "Homework Help"
                  ).subcategories.map((element) => {
                    return (
                      <li>
                        <Link
                          className="text-decoration-none"
                          to={element.path}
                        >
                          {element.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : null}
          </div>
          <div className="footer-col col-sm-6 col-md-3 col-12">
            {getSubCategoriesByCategory("Assignment Help") ? (
              <>
                <h3>Assignment Help</h3>
                <ul className="p-0">
                  {getSubCategoriesByCategory(
                    "Assignment Help"
                  ).subcategories.map((element) => {
                    return (
                      <li>
                        <Link
                          className="text-decoration-none"
                          to={element.path}
                        >
                          {element.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : null}
            {getSubCategoriesByCategory("Live Session Help") ? (
              <>
                <h3>Live Session Help</h3>
                <ul className="p-0">
                  {getSubCategoriesByCategory(
                    "Live Session Help"
                  ).subcategories.map((element) => {
                    return (
                      <li>
                        <Link
                          className="text-decoration-none"
                          to={element.path}
                        >
                          {element.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : null}
          </div>
          <div className="footer-col col-sm-6 col-md-3 col-12">
            <h3>About Us</h3>
            <ul className="p-0">
              <Link to="" className="text-decoration-none">
                <li>Become a tutor</li>
              </Link>
              <Link to="" className="text-decoration-none">
                <li>CareersWe're Hiring!</li>
              </Link>
              <Link to="/faqs" className="text-decoration-none">
                <li>FAQs</li>
              </Link>
              <Link to="/refund-policy" className="text-decoration-none">
                <li>Refund Policy</li>
              </Link>
              <Link to="/privacy-policy" className="text-decoration-none">
                <li>Privacy Policy</li>
              </Link>
              <Link to="/terms-and-conditions" className="text-decoration-none">
                <li>Terms and Conditions</li>
              </Link>
              <Link to="" className="text-decoration-none">
                <li>Reviews</li>
              </Link>
              <Link to="/copyright-policy" className="text-decoration-none">
                <li>Copyright Policy</li>
              </Link>
            </ul>
          </div>

          <div className="footer-col col-sm-6 col-md-3 col-12">
            <h3>Contact Us</h3>
            <ul className="p-0">
              <li className="li-icon">
                <img
                  className="mt-1"
                  src={locationIcon}
                  height={16}
                  width={16}
                />
                B-603, Sky View Heights, Behind Midas Square, Surat, Gujarat,
                395010
              </li>
              <li className="li-icon">
                <img
                  className="mt-1"
                  src={WhatsappIcon}
                  height={16}
                  width={16}
                />
                {` +91 ${setting.contactNumber}`}
              </li>

              <li className="li-icon">
                <img className="mt-1" src={emailIcon} height={13} width={13} />
                {setting.contactEmail}
              </li>

             
              {/* <li>
                <h3>Get App</h3>
                <span>
                  <img src={DownloadFromPlayStore} alt="" />
                  <img src={DownloadFromAppStore} alt="" />
                </span>
              </li> */}

              
            </ul>

            <ul className="social-media-footer">
              <li>
              {socialMedia.map((media, index) => (
                <li
                  key={index}
                  className="li-icon"
                  style={{ display: "inline-block", marginRight: "10px" }}
                >
                  <Link to={media.url} target="_blank">
                    <img
                      src={`${baseURL}${media.image}`}
                      alt={media.name}
                      height={24}
                      width={24}
                    />
                  </Link>
                </li>
              ))}
              </li>
            </ul>
          </div>
          <hr />

          <div className="footer-end-section">
            <span>
              <h3>© {new Date().getFullYear()} DoubtQ. All Rights Reserved</h3>
              {/* <span>Website developed by: Setblue.com</span> */}
            </span>
            {/* <div>
              *The amount will be in form of wallet points that you can redeem
              to pay upto 10% of the price for any assignment. <br />
              **Use of solution provided by us for unfair practice like cheating
              will result in action from our end which may include permanent
              termination of the defaulter’s account.
            </div> */}
            <div className="d-flex">
              <p dangerouslySetInnerHTML={{ __html: setting.disclaimer }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
