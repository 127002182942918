import React, { useEffect, useRef, useState } from "react";
import "./RangeSlider.scss";

const RangeSlider = ({ name, dynamicArray = [], onChange, value = "" }) => {
  // let randomValue =
  //   dynamicArray && dynamicArray[Math.ceil(dynamicArray.length / 2) ?? 0];

  const [sliderValue, setSliderValue] = useState(value);
  const sliderRef = useRef(null);

  useEffect(() => {
    if (dynamicArray?.includes(value)) {
      setSliderValue(value);
    } else {
      setSliderValue(dynamicArray[Math.ceil(dynamicArray.length / 2) ?? 0]);
    }
  }, [dynamicArray]);

  const handleSliderClick = (events) => {
    const newValue = calculateNewValue(events);
    setSliderValue(newValue);
    const event = {
      target: {
        name: name,
        value: newValue,
      },
    };
    onChange(event);
  };

  useEffect(() => {
    const event = {
      target: {
        name: name,
        value: sliderValue,
      },
    };
    onChange(event);
  }, []);

  const calculateNewValue = (event) => {
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const percentage =
      ((event.clientX - sliderRect.left) / sliderRect.width) * 100;
    const nearestIndex = Math.round(
      (percentage / 100) * (dynamicArray.length - 1)
    );
    return dynamicArray[nearestIndex];
  };

  return (
    <div className="app-container">
      <div className="fixed-hours-display">
        <div className="hour-range-slider-container">
          <div
            id="custom-slider"
            className="slider-track"
            name={name}
            onClick={handleSliderClick}
            ref={sliderRef}
          >
            {dynamicArray?.map((hour, index) => (
              <div
                key={index}
                className="slider-label"
                style={{
                  left: `${(index / (dynamicArray?.length - 1)) * 100}%`,
                }}
              >
                {hour}
              </div>
            ))}
            <div
              className="slider-handle"
              style={{
                left: `${
                  (dynamicArray?.indexOf(sliderValue) /
                    (dynamicArray?.length - 1)) *
                  100
                }%`,
              }}
            />
          </div>
          {dynamicArray.map((hour, index) => (
            <div
              key={index}
              className={`hour-label ${
                hour === sliderValue ? "selected-hour" : ""
              }`}
              style={{
                left: `${(index / (dynamicArray?.length - 1)) * 100}%`,
              }}
            >
              {hour}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
