import axiosInstance from "../axiosInterceptor";

export async function createQuestionLivesessionHelp(body, selectedTimezone) {
  const { questionPDF, referencePDF } = body;
  const formdata = new FormData();
  formdata.append("categoryId", "65524e353c005ef3b51907c2");
  formdata.append("subCategoryId", body.subCategory);
  formdata.append("question", body.question);
  formdata.append("subjectId", body.subject);
  if (questionPDF) {
    questionPDF.forEach((file, index) => {
      formdata.append(`questionPDF`, file);
    });
  }
  formdata.append("date", body.date);
  if (referencePDF) {
    referencePDF.forEach((file, index) => {
      formdata.append(`referencePDF`, file);
    });
  }
  // Create a new Date object
  // const time = new Date();

  // // Set the hours and minutes in the Date object
  // time.setHours(body.hours);
  // time.setMinutes(body.minutes);

  // Append the Date object to the FormData object
  formdata.append("time", `${body.hours}:${body.minutes}`);

  formdata.append("durationMintues", body.durationMintues);
  if (body.haveReferenceFile === "Yes") {
    formdata.append("isReference", true);
  } else {
    formdata.append("isReference", false);
  }
  formdata.append(
    "timeZone",
    typeof body?.timeZone === "object" ? body.timeZone.label : body.timeZone
  );
  formdata.append("meradian", body.meradian);
  //   formdata.append("deadlineHours", body.deadline);
  //
  //   if (referencePDF) {
  //     referencePDF.forEach((file, index) => {
  //       formdata.append(`referencePDF`, file);
  //     });
  //   }
  const response = await axiosInstance.post(
    `/student/liveSessionHelp/createQuestion`,
    formdata,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  const data = response?.data;
  return data;
}
