import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  NavbarToggler,
  Input,
  Col,
  Button,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Header.scss";
import logo from "../Assets/logo/logo.svg";
import arrowDown from "../Assets/icons/arrowDown.svg";
import search from "../Assets/icons/Search.svg";
import user from "../Assets/icons/user.svg";
import axiosInstance from "../axiosInterceptor";
import { CategoryContext } from "../categoryContext";

const Header = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [navbarMenu, setNavbarMenu] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track user's authentication status

  const fetchData = async () => {
    try {
      const res = await axiosInstance.get(
        "/admin/category/getCategoryWiseSubcategory"
      );
      setNavbarMenu(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    // Check if user is already authenticated
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const toggleNavbar = () => setIsNavbarOpen(!isNavbarOpen);
  const { setCategoryId } = useContext(CategoryContext);

  const handleSearch = () => {
    navigate(`/search?query=${searchTerm}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSignIn = () => {
    navigate("/auth");
  };

  return (
    <Fragment>
      <Container fluid className="top-header">
        <Navbar expand="lg" className="top-header">
          <NavbarBrand href="/">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} />
          <Collapse isOpen={isNavbarOpen} navbar>
            <Nav className="mx-auto align-items-center py-4 " navbar>
              {navbarMenu
                .filter((nav) => nav.categoryName !== "Home")
                .map((category) => (
                  <NavItem key={category.categoryName} className="px-5">
                    <NavLink className="menu dropdown-menu position-relative">
                      {category.categoryName}{" "}
                      <img src={arrowDown} alt="arrow-down" />
                      <Nav className="sub-dropdown-menu bg-light pt-2 shadow-lg xxx">
                        {category?.subcategories?.map((item) => (
                          <NavItem key={item._id} className="sub-menu py-1">
                            <Link
                              to={item.path}
                              className="nav-link p-0 px-5 py-3"
                              onClick={() => setCategoryId(item._id)}
                            >
                              {item.name}
                            </Link>
                          </NavItem>
                        ))}
                      </Nav>
                    </NavLink>
                  </NavItem>
                ))}
            </Nav>
            <div className="position-relative search-input">
              <Input
                placeholder="Search, solutions, and more...."
                className="py-4 search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <div
                className="d-flex p-3 rounded-3 search-icon"
                onClick={handleSearch}
              >
                <img src={search} alt="search-icon" />
              </div>
            </div>
              <Button
                color="primary"
                className="ms-3 signInButton"
                onClick={handleSignIn}
              >
                <img src={user} alt="user-icon" /> Sign In
              </Button>
          </Collapse>
        </Navbar>
      </Container>
    </Fragment>
  );
};

export default Header;
