import React, { useEffect, useState } from "react";
import "./ReadMoreSection.scss";
import Button from "./Buttons/Button";
import axiosInstance from "../axiosInterceptor";

const ReadMoreSection = () => {
  const [setting, setSetting] = useState([]);
  const fetchData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="read-more-section-container">
      {/* <div className="read-more-section-title">
        Is Academic Help High in Demand?
      </div>
      <div className="read-more-section-description">
        Despite the apparent rise in the demand for the finest Issue help
        websites among college students, the answer to this question is
        controversial among both professors and their pupils. Many students find
        Question helpers useful, although not all educators agree with this
        assessment. The majority of students (56%) say that they are stressed
        out by their questions, and these students need the greatest online
        Question assistance to have questions answered in all areas. As a result
        of the epidemic, the number of people requiring aid has skyrocketed to
        an all-time high. It happens because of a disparity in education. In
        this article, we will analyze the factors that have led to the meteoric
        growth in popularity of question assistance services, as well as provide
        a glance into the kinds of services they provide.
      </div> */}

      <div className="read-more-section-title">
        Is Academic Help High in Demand?
      </div>
      <div className="read-more-section-description">
        {/* {setting.academicHelp} */}
        <p dangerouslySetInnerHTML={{ __html: setting.academicHelp }} />
      </div>
    </div>
  );
};

export default ReadMoreSection;
