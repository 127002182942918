import React, { useState, useEffect } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import pencil from "../../Assets/images/draw_edit_pencil.png";
import axiosInstance from '../../axiosInterceptor';
import { useNavigate } from 'react-router-dom';
import { getAllCountries } from '../../services/getAllCountries';

const PersionalSetting = () => {
    const [isEditing, setEditing] = useState(false);
    const [countries, setCountries] = useState([]);
    const [isSaving, setSaving] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        countryId: '',
        name: '',
        state: '',
        city: '',
        postalCode: '',
        address: ''
    });
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axiosInstance.get('student/getProfile');
                const profileData = response.data.profile;
                // Set formData state with the fetched profile data
                setFormData({
                    email: profileData.email,
                    countryId: profileData.countryId,
                    name: profileData.name,
                    state: profileData.state,
                    city: profileData.city,
                    postalCode: profileData.postalCode,
                    address: profileData.address
                });
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };
        fetchProfileData();

        // Fetch countries
        const fetchCountries = async () => {
            try {
                const res = await getAllCountries();
                setCountries(res.data);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setSaving(true); // Disable the Save button
            const response = await axiosInstance.put('student/updateProfile', formData);
            navigate('/dashboard/home');
            // Optionally, display a toast message here
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setSaving(false); // Enable the Save button
        }
    };

    return (
        <div className='dashboard-persional-setting'>
            <Row>
                <Col md="6">
                    <h2 className='fw-bold text-start mt-4 p-4'>Personal Info</h2>
                </Col>
                {/* <Col md="6">
                    <div className='d-flex float-end'>
                        <div className="Personal-Settings-button p-6" align="right">
                            <Button
                                className="border-editst-btn"
                                size="lg"
                            >
                                <img className='mx-2' src={pencil} alt='pencil' />
                                Edit
                            </Button>
                        </div>
                    </div>
                </Col> */}
            </Row>
            <Form className='p-4' onSubmit={handleSubmit}>
                <Row>
                    <Col md="6" className='text-start'>
                        <FormGroup>
                            <Label for="exampleEmail">
                                Email Id
                            </Label>
                            <Input
                                className='p-4 font-size'
                                id="exampleEmail"
                                name="email"
                                placeholder="Please enter your email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" className='text-start'>
                        <FormGroup>
                            <Label for="exampleEmail">
                                Country
                            </Label>
                            <Input
                                className='p-4'
                                type="select"
                                placeholder="Select Country"
                                name="countryId"
                                value={formData.countryId}
                                onChange={handleChange}
                            >
                                <option disabled selected value="">
                                    Select Country
                                </option>
                                {countries?.map((country) => {
                                    return (
                                        <option key={country.countryCode} value={country._id}>
                                            {country.countryName}
                                        </option>
                                    );
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                {/* Additional fields */}
                <Row>
                    <Col md="6" className='text-start'>
                        <FormGroup>
                            <Label for="exampleName">
                                Name
                            </Label>
                            <Input
                                className='p-4 font-size'
                                id="exampleName"
                                name="name"
                                placeholder="Enter your name"
                                type="text"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" className='text-start'>
                        <FormGroup>
                            <Label for="exampleState">
                                State
                            </Label>
                            <Input
                                className='p-4 font-size'
                                id="exampleState"
                                name="state"
                                placeholder="Enter your state"
                                type="text"
                                value={formData.state}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" className='text-start'>
                        <FormGroup>
                            <Label for="exampleCity">
                                City
                            </Label>
                            <Input
                                className='p-4 font-size'
                                id="exampleCity"
                                name="city"
                                placeholder="Enter your city"
                                type="text"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" className='text-start'>
                        <FormGroup>
                            <Label for="examplePostalCode">
                                Postal Code
                            </Label>
                            <Input
                                className='p-4 font-size'
                                id="examplePostalCode"
                                name="postalCode"
                                placeholder="Enter your postal code"
                                type="text"
                                value={formData.postalCode}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className='text-start'>
                        <FormGroup>
                            <Label for="exampleAddress">
                                Address
                            </Label>
                            <Input
                                className='p-4 font-size'
                                id="exampleAddress pay"
                                name="address"
                                placeholder="Enter your address"
                                type="textarea"
                                value={formData.address}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <div className="Personal-Settings-button p-6" align="right">
                    <Button
                        className="border-edit-btn"
                        size="lg"
                        type="submit"
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default PersionalSetting;
