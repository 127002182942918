import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./StudentStories.scss";
import StudentStoriesIcon from "../Assets/images/background/student-stories-icon.svg";
import { getStory } from "../services/getStory";
import { ImageLink } from "../constants";


const StudentStories = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
    },
  };

  const [storyData, setStoryData] = useState([]);
  const fetchData = async () => {
    await getStory()
      .then((data) => {
        setStoryData(data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div className="student-stories-container">
      <h2 className="title">Student Stories</h2>
      <span className="description">
        DoubtQ  has got 3k+ positive student ratings from different countries of
        the world. <br></br>Their positive reviews show how DoubtQ dedicatedly helped
        them to grow
      </span>
      <div className="carousel-container">
        <Carousel
          responsive={responsive}
          autoPlay={true}
          arrows={false}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
        // partialVisible={false}
        >
          {storyData.map((e, index) => {
            return (
              <div key={index} className="carousel">
                <div className="carousel-header">
                  <img src={ImageLink(e.image)} className="carousel-img" alt="carousel" />
                  <div className="carousel-title">{e.name}</div>
                  <div className="carousel-icon">
                    <img
                      src={StudentStoriesIcon}
                      className="carousel-img"
                      alt="carousel-icon"
                    />
                  </div>
                </div>
                <div className="carousel-description">{e.description}</div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default StudentStories;
