import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import MCQService from "./Pages/homework-help/MCQService/index";
import TrueFalseService from "./Pages/homework-help/TrueFalseService/index";
import FillupsService from "./Pages/homework-help/FillUps";
import ShortAnswerService from "./Pages/homework-help/ShortAnswerService";
import MatchTheFollowing from "./Pages/homework-help/MatchTheFollowing";
import Definition from "./Pages/homework-help/definition";
import AssignmentWriting from "./Pages/assignment-help/AssignmentWriting";
import ProjectLabReportWriting from "./Pages/assignment-help/ProjectLabReportWriting";
import EssayWriting from "./Pages/assignment-help/EssayWriting";
import PresentationWriting from "./Pages/assignment-help/PresentationWriting";
import ArticleWriting from "./Pages/assignment-help/ArticleWriting";
import SpeechWriting from "./Pages/assignment-help/SpeechWriting";
import LiveSessionChatHelp from "./Pages/live-session-help/1-to-1-chat-help";
import LiveSessionVideoHelp from "./Pages/live-session-help/1-to-1-video-help";
import { useEffect, useState } from "react";
import OrderHistory from "./Components/OrderStatusAndBilling/OrderHistory";
import BillingAddress from "./Components/OrderStatusAndBilling/BillingAddress";
import CheckOut from "./Components/OrderStatusAndBilling/CheckOut";
import { loadStripe } from "@stripe/stripe-js";
import Completion from "./Components/OrderStatusAndBilling/Completion";
import Dashboard from "./Pages/dashboard/Dashboard";
import DashboardHome from "./Pages/dashboard/DashboardHome";
import DashboardOrderHistory from "./Pages/dashboard/DashboardOrderHistory";
import DashboardQuestions from "./Pages/dashboard/DashboardQuestions";
import DashboardAnswer from "./Pages/dashboard/DashboardAnswer";
import Dashboardview from "./Pages/dashboard/Dashboardview";
import DashboardLibrary from "./Pages/dashboard/DashboardLibrary";
import DashboardFaq from "./Pages/dashboard/DashboardFaq";
import DashboardContactUs from "./Pages/dashboard/DashboardContactUs";
import { checkToken } from "./services/checkToken";
import { isLoggedIn } from "./hooks/useIsLoggedIn";
import OopsError from "./Components/OrderStatusAndBilling/OopsError";
import { CategoryContext } from "./categoryContext";
import Search from "./Pages/search/Search";

import Refundpolicy from "./Pages/Staticpages/Refundpolicy";
import Privacypolicy from "./Pages/Staticpages/Privacypolicy";
import Termsandconditions from "./Pages/Staticpages/Termsandconditions";
import Copyrightpolicy from "./Pages/Staticpages/Copyrightpolicy";
import Faq from "./Pages/Staticpages/Faq";
import Authentication from "./Pages/auth/Auth";
import Earnmoney from "./Pages/dashboard/Earnmoney";
import PersionalSetting from "./Pages/dashboard/PersionalSetting";
import Dashboardviewlive from "./Pages/dashboard/Dashboardviewlive";
import ViewLibraryAnswer from "./Pages/search/ViewLibraryAnswer";
import Libraryviewabswer from "./Pages/dashboard/Libraryviewabswer";
import Razorpay from "./Components/OrderStatusAndBilling/Razorpay";
// import Honourcode from "./Pages/Staticpages/Honorcode";
// import Aboutdoubtq from "./Pages/Staticpages/Aboutdoubtq";
// import Academicintegrity from "./Pages/Staticpages/Academicintegrity";

function RequireAuth({ children }) {
  const [checkFlag, setCheckFlag] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await isLoggedIn();
      setCheckFlag(result);
    };

    fetchData();
  }, []);

  if (checkFlag === null) {
    return <div>Loading...</div>;
  }

  if (!checkFlag) {
    return <Navigate to="/" />;
  }
  return children;
}

function CheckRoutes({ children }) {
  const [checkFlag, setCheckFlag] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const result = await isLoggedIn();
      setCheckFlag(result);
    };

    fetchData();
  }, []);

  if (checkFlag === null) {
    return <div>Loading...</div>;
  }

  if (checkFlag) {
    return <Navigate to="/dashboard/home" />;
  }
  return children;
}

function App() {
  const [categoryId, setCategoryId] = useState("6568bbce652c801ff8766635");
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    const verifyToken = async () => await checkToken();
    verifyToken();
    return null;
  }

  // const stripePromise = loadStripe(
  //   "pk_test_51OXRmRSAfkUVABaeJZMFJFDvAFQGYU9qHLfL6mMnZtSMthvlRm40fSgIwv8xQFJG38M27VrKsCOVb2E4FijQVogh00uJOghMOJ"
  // );

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

  const razorpayPromise = loadStripe(process.env.REACT_APP_RAZORPAY_KEY_ID);

  return (
    <div className="App">
      <CategoryContext.Provider value={{ categoryId, setCategoryId }}>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <CheckRoutes>
                <HomePage />
              </CheckRoutes>
            }
          />
          <Route
            path="/auth"
            element={
              <CheckRoutes>
                <Authentication />
              </CheckRoutes>
            }
          />
          <Route
            // path="/mcq-service"
            path="/question-help"
            element={
              <CheckRoutes>
                <MCQService />
              </CheckRoutes>
            }
          />
          <Route
            path="/true-false"
            element={
              <CheckRoutes>
                <TrueFalseService />
              </CheckRoutes>
            }
          />
          <Route
            path="/fill-ups"
            element={
              <CheckRoutes>
                <FillupsService />
              </CheckRoutes>
            }
          />
          <Route
            path="/short-question-answer"
            element={
              <CheckRoutes>
                <ShortAnswerService />
              </CheckRoutes>
            }
          />
          <Route
            path="/match-the-following"
            element={
              <CheckRoutes>
                <MatchTheFollowing />
              </CheckRoutes>
            }
          />
          <Route
            path="/definition"
            element={
              <CheckRoutes>
                <Definition />
              </CheckRoutes>
            }
          />
          <Route
            // path="/assignment-writing"
            path="/problem-solving"
            element={
              <CheckRoutes>
                <AssignmentWriting />
              </CheckRoutes>
            }
          />
          <Route
            path="/project-lab-report-writing"
            element={
              <CheckRoutes>
                <ProjectLabReportWriting />
              </CheckRoutes>
            }
          />
          <Route
            path="/essay-writing"
            element={
              <CheckRoutes>
                <EssayWriting />
              </CheckRoutes>
            }
          />
          <Route
            path="/presentation-writing"
            element={
              <CheckRoutes>
                <PresentationWriting />
              </CheckRoutes>
            }
          />
          <Route
            // path="/article-writing"
            path="/research-dissertation-writing"
            element={
              <CheckRoutes>
                <ArticleWriting />
              </CheckRoutes>
            }
          />
          <Route
            path="/speech-writing"
            element={
              <CheckRoutes>
                <SpeechWriting />
              </CheckRoutes>
            }
          />
          <Route
            path="/1-1-chat"
            element={
              <CheckRoutes>
                <LiveSessionChatHelp />
              </CheckRoutes>
            }
          />
          <Route
            path="/1-1-video"
            element={
              <CheckRoutes>
                <LiveSessionVideoHelp />
              </CheckRoutes>
            }
          />
          <Route
            path="/search"
            element={
              <CheckRoutes>
                <Search />
              </CheckRoutes>
            }
          />
          <Route
            path="/homework-help/question-and-answer/:search"
            element={
              <CheckRoutes>
                <ViewLibraryAnswer />
              </CheckRoutes>
            }
          />
          <Route
            path="/refund-policy"
            element={
              <CheckRoutes>
                <Refundpolicy />
              </CheckRoutes>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <CheckRoutes>
                <Privacypolicy />
              </CheckRoutes>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <CheckRoutes>
                <Termsandconditions />
              </CheckRoutes>
            }
          />
          <Route
            path="/copyright-policy"
            element={
              <CheckRoutes>
                <Copyrightpolicy />
              </CheckRoutes>
            }
          />
          <Route
            path="/faqs"
            element={
              <CheckRoutes>
                <Faq />
              </CheckRoutes>
            }
          />

          <Route
            path="/orderStatus/:questionId"
            element={
              <RequireAuth>
                <OrderHistory />
              </RequireAuth>
            }
          />
          <Route
            path="/billingDetails/:paymentMethod/:questionId"
            element={
              <RequireAuth>
                <BillingAddress />
              </RequireAuth>
            }
          />
          <Route
            path="/checkout"
            element={
              <RequireAuth>
                <CheckOut stripePromise={stripePromise} />
              </RequireAuth>
            }
          />
          <Route
            path="/razorpay-checkout"
            element={
              <RequireAuth>
                <Razorpay razorpayPromise={razorpayPromise} />
              </RequireAuth>
            }
          />
          <Route
            path="dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          >
            <Route path="completion" element={<Completion />} />
            <Route path="failed" element={<OopsError />} />
            <Route
              path="home"
              index
              element={
                <RequireAuth>
                  <DashboardHome />
                </RequireAuth>
              }
            />
            <Route
              path="order-history"
              element={
                <RequireAuth>
                  <DashboardOrderHistory />
                </RequireAuth>
              }
            />
            <Route
              path="questions"
              element={
                <RequireAuth>
                  <DashboardQuestions />
                </RequireAuth>
              }
            />
            <Route
              path="answer"
              element={
                <RequireAuth>
                  <DashboardAnswer />
                </RequireAuth>
              }
            />
            <Route
              path="earnmoney"
              element={
                <RequireAuth>
                  <Earnmoney />
                </RequireAuth>
              }
            />
            <Route
              path="personal-info"
              element={
                <RequireAuth>
                  <PersionalSetting />
                </RequireAuth>
              }
            />
            <Route
              path="viewanswer/:questionId"
              element={
                <RequireAuth>
                  <Dashboardview />
                </RequireAuth>
              }
            />
            <Route
              path="viewanswer-live/:questionId"
              element={
                <RequireAuth>
                  <Dashboardviewlive />
                </RequireAuth>
              }
            />
            <Route
              path="library"
              element={
                <RequireAuth>
                  <DashboardLibrary />
                </RequireAuth>
              }
            />
            <Route
              path="library/question-and-answer/:search"
              element={
                <RequireAuth>
                  <Libraryviewabswer />
                </RequireAuth>
              }
            />
            <Route
              path="faq"
              element={
                <RequireAuth>
                  <DashboardFaq />
                </RequireAuth>
              }
            />
            <Route
              path="contact-us"
              element={
                <RequireAuth>
                  <DashboardContactUs />
                </RequireAuth>
              }
            />
          </Route>

          {/* <Route path="/honourcode" element={<Honourcode />} />
          <Route path="/aboutdoubtq" element={<Aboutdoubtq />} />
          <Route path="/academicintegrity" element={<Academicintegrity />} /> */}
        </Routes>
      </CategoryContext.Provider>
    </div>
  );
}

export default App;
