import React, { useEffect, useState } from "react";
import "./BonusOffersSection.scss";
import Button from "./Buttons/Button";
import arrowRight from "../Assets/icons/arrowRight.svg";
import tag from "../Assets/icons/tag.svg";
import { getAllBonusOffer } from "../services/getAllBonusOffer";
import { Row, Col } from "reactstrap";

const arr = [
  {
    name: "Homework-Help",
    description: "WHEN YOU ADD FUNDS $50 & $250",
    percentage: "20",
  },
  {
    name: "Assignment-Help",
    description: "FOR YOUR FIRST ASSIGNMENT FOR YOUR THIRD ASSIGNMENT",
    percentage: "30",
  },
  {
    name: "Live Session-Help",
    description: "FOR YOUR FIRST LIVE SESSION FOR YOUR THIRD LIVE SESSION",
    percentage: "40",
  },
];

const BonusOffersSection = () => {
  const [pageData, setPageData] = useState([]);
  const fetchData = async () => {
    await getAllBonusOffer()
      .then((data) => {
        setPageData(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bonussection">
      <div className="title">Bonus Offers</div>
      <Row className="my-4">
        {pageData.map((item, index) => (
          <Col sm={12} md={6} lg={4} key={index} className="mb-4">
            <BonusOffersCard
              className={index % 2 ? "blue" : "violet"}
              details={item}
            />
          </Col>
        ))}
      </Row>
      <Button
        className="mt-4"
        text="Place Your Help"
        varient="primary"
      ></Button>
    </div>
  );
};

const BonusOffersCard = ({ details, className = "" }) => {
  return (
    <div className={`card ${className}`}>
      <div className="label">{details.name}</div>
      <div className="discount-wrapper">
        <div className="bonus">BONUS</div>
        <div className="discount">{details.percentage}%</div>
        <div className="off">OFF</div>
        <div className="arrow">
          <img src={arrowRight}></img>
        </div>
      </div>
      <div className="message">{details.description}</div>
      <img className="tag1" src={tag}></img>
      <img className="tag2" src={tag}></img>
    </div>
  );
};
export default BonusOffersSection;
