import React, { useEffect, useState } from "react";
import "./LiveHelpSection.scss";
import Button from "../Components/Buttons/Button";
import CategoryCard from "./Card/CategoryCard";
import { getCategoryWiseSubcategory } from "../services/getCategoryWiseSubcategory";

const arr = [
  {
    name: "1-on-1 session chat",
    description:
      "With VAIDIK's 100% unique, accurate, and around-the-clock homework assistance, you can meet even the most stringent of deadlines with ease With VAIDIK's 100% unique, accurate, and around-the-clock homework assistance, ",
  },
  {
    name: "True False",
    description:
      "With VAIDIK's 100% unique, accurate, and around-the-clock homework assistance, you can meet even the most stringent of deadlines with ease With VAIDIK's 100% unique, accurate, and around-the-clock homework assistance, ",
  },
];

const LiveHelpSection = ({ setIsModelOpen, setTypeData }) => {
  const [pageData, setPageData] = useState([]);
  const fetchData = async () => {
    await getCategoryWiseSubcategory("65524e353c005ef3b51907c2")
      .then((data) => {
        setPageData(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="livesection">
      <div className="title">Live Session Help</div>
      <div className="description">
        Variety of Unique Solution call for variety of solutions.
      </div>
      <div className="card-wrapper">
        {pageData?.subCategory?.map((item, index) => {
          return (
            <CategoryCard key={index} className="liveSession" details={item} />
          );
        })}
      </div>
      <Button
        text="Place Your Live Session Help"
        varient="primary"
        onClick={() => {
          setTypeData({
            serviceType: "LiveSessionHelp",
            type: "livesessionHelp",
            totalSteps: 4,
          });
          setIsModelOpen(true);
        }}
      ></Button>
    </div>
  );
};

export default LiveHelpSection;
