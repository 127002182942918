import React, { useEffect, useState } from "react";
import "./BenefitsSection.scss";
import AccurateSolutionIcon from "../Assets/icons/accurate-solution.svg";
import Button from "./Buttons/Button";
import { getBenefitBySubcategoryId } from "../services/getBenefitBySubcategoryId";
import { ImageLink } from "../constants";
const arr = [
  {
    icon: AccurateSolutionIcon,
    title: "Answers in 15 Minutes",
    description:
      "Don’t waste time searching for answers or waiting for your teacher to email back.",
  },
  {
    icon: AccurateSolutionIcon,
    title: "Chat with Tutors Instantly",
    description:
      "Get all your questions answered. Chat with tutors instantly and ask any follow-up questions you have.",
  },
  {
    icon: AccurateSolutionIcon,
    title: "Affordable for Students",
    description:
      "Priced right for students with the most affordable mobile tutors. For INR 3 / per Question, get 24/7 access to expert tutors.",
  },
  {
    icon: AccurateSolutionIcon,
    title: "Unlimited Expert Q&A",
    description:
      "There are no monthly question limits with VAIDIK. Our massive tutor network, with 40,000+ subject matter experts, are always available to help you!",
  },
  {
    icon: AccurateSolutionIcon,
    title: "Confidentiality",
    description:
      "We guarantee the safety of your information from any third party miscreants.",
  },
  {
    icon: AccurateSolutionIcon,
    title: "Accurate solutions",
    description:
      "Get 100% Accurate solutions in 25+ subjects to boost your GPA.",
  },
];

const BenefitsSection = ({ subCategoryId, onClick }) => {
  const [pageData, setPageData] = useState([]);
  const fetchData = async () => {
    await getBenefitBySubcategoryId({
      subCategoryId,
    })
      .then((data) => {
        setPageData(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="benefits-section-wrapper">
      <div className="container">
      <div className="benefits-section-container">
        <h3 className="title">
          {pageData?.mainTitle ?? "Multiple Benefits of DoubtQ Help"}
        </h3>
        <div className="description">
          create a healthy platform that helps students to launch into the sky
          of success.
        </div>
      </div>
      <div className="benefits-card-wrapper">
        {pageData?.benefitData?.map((e, index) => {
          return <BenfitCard key={index} {...e} />;
        })}
      </div>
      <Button
        text={"Place Your Help"}
        varient="primary"
        onClick={onClick}
      ></Button>
        </div>
    </div>
  );
};

const BenfitCard = ({ title = "", description = "", image = "" }) => {
  return (
    <div className="benefits-card-container">
      <div className="benefits-card-icon">
        <img src={ImageLink(image)} alt="" />
      </div>
      <h3 className="benefits-card-title">{title}</h3>
      <div className="benefits-card-description">{description}</div>
    </div>
  );
};

export default BenefitsSection;
