// ** React Imports ** //
import React, { forwardRef } from "react";

// ** Components Imports ** //
import DateInputControl from "./dateInputControl";
import MonthSubPopup from "./monthSubPopup";

const DateInputPopup = forwardRef(
  (
    {
      currentMonth,
      setCurrentMonth,
      setCurrentYear,
      currentYear,
      navigateMonth,
      showSubPopup,
      toggleSubPopupHandler,
      children,
      selected,
      handleClear = () => {},
    },
    ref
  ) => {
    return (
      <>
        {!showSubPopup && (
          <div className="px-2 py-1 bg-light rounded-3 date-popup" ref={ref}>
            <DateInputControl
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              currentYear={currentYear}
              toggleSubPopupHandler={toggleSubPopupHandler}
              navigateToNextMonth={() => navigateMonth(1)}
              navigateToPrevMonth={() => navigateMonth(-1)}
            />
            <div className="date-popup__grid pt-2">
              <small className="d-block text-center py-1 text-muted week-code">
                Sun
              </small>
              <small className="d-block text-center py-1 text-muted week-code">
                Mon
              </small>
              <small className="d-block text-center py-1 text-muted week-code">
                Tue
              </small>
              <small className="d-block text-center py-1 text-muted week-code">
                Wed
              </small>
              <small className="d-block text-center py-1 text-muted week-code">
                Thu
              </small>
              <small className="d-block text-center py-1 text-muted week-code">
                Fri
              </small>
              <small className="d-block text-center py-1 text-muted week-code">
                Sat
              </small>
              {children}
            </div>
            {/* <div className='text-center py-2'>
                            <button type='button' onClick={handleClear} className='border-0 fs-6 bg-transparent cursor-pointer'>Clear</button>
                        </div> */}
          </div>
        )}

        {showSubPopup && (
          <div className="py-3 bg-light rounded-3 date-sub-popup">
            <MonthSubPopup
              showSubPopup={showSubPopup}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
              toggleSubPopupHandler={toggleSubPopupHandler}
              navigateToNextMonth={() => navigateMonth(1)}
              navigateToPrevMonth={() => navigateMonth(-1)}
              selected={selected}
            />
          </div>
        )}
      </>
    );
  }
);

export default DateInputPopup;
