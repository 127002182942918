import React from "react";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import checkLogo from "../../../Assets/images/Group 707.svg";
import ReactQuill from "react-quill";

const HomeworkForm1 = ({
  texteditorRef,
  payload,
  modules,
  formats,
  handlePaste,
  handleChange,
  question,
  error,
  isError,
  setIsError,
}) => {

  return (
    <Container>
      <h1 className="step-header text-center">Place Your Question here</h1>
      <h2 className="step-sub-header">How it works</h2>
      <Row>
        <Col className="stap-form-1 gap-5 d-xl-flex">
          <FormGroup className="d-flex gap-1">
            <img src={checkLogo} height="30px" width="30px" />
            <Label className="form-label m-0">
              Post one question at one time
            </Label>
          </FormGroup>
          <FormGroup className="d-flex gap-1">
            <img src={checkLogo} height="30px" width="30px" />
            <Label className="form-label m-0">
              Get an instant answer from our experts
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="">
          <FormGroup>
            <ReactQuill
              theme="snow"
              value={payload?.question || question}
              ref={(el) => (texteditorRef.current = el)}
              modules={modules}
              formats={formats}
              className="question-textarea"
              onChange={(event) =>
                handleChange({
                  target: {
                    name: "question",
                    value: event,
                  },
                })
              }
              onPaste={handlePaste}
            />

            {/* <Input
          type="textarea"
          name="question"
          className="question-textarea"
          value={payload?.question}
          onChange={handleChange}
        /> */}
          </FormGroup>
          {isError && error.question && (
            <span className="text-danger">{error.question}</span>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default HomeworkForm1;
