import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "./Static.scss";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Collapse, Button, CardBody, CardHeader, Container } from "reactstrap";
import ScrollTop from "../../Components/Buttons/ScrollTop";

const Faq = () => {
  useEffect(() => {
    document.title =
      "Frequently Asked Questions (FAQ) | DoubtQ - Your Questions Answered";
  }, []);

  const [openAccordionId, setOpenAccordionId] = useState(null);

  const toggleAccordion = (accordionId) => {
    if (openAccordionId === accordionId) {
      setOpenAccordionId(null);
    } else {
      setOpenAccordionId(accordionId);
    }
  };

  const accordionData = [
    {
      id: 1,
      question: "How do I create my account?",
      answer:
        "To join, visit www.DoubtQ.com, and choose 'Sign Up' in the right-hand menu bar or 'sign up now' in the white space just to the right of the 'sign in' button. If you click on it, you'll be redirected to a new web page. Simply complete the necessary fields now.",
    },
    {
      id: 2,
      question: "Can anybody see my identity?",
      answer:
        "No. We never reveal our customers' personal information to outside parties. Your information will be kept confidential and will not be shared with any third parties outside of tutorbin.com. Have a look at what we have to say about privacy if you're interested.",
    },
    {
      id: 3,
      question: "How do I delete my account?",
      answer:
        "Please contact us and ask that your account be removed. You will no longer have access to your account and it will be permanently removed from our system.",
    },
    {
      id: 4,
      question: "Can you help in exam or quiz?",
      answer:
        "While we can't assist you during the actual exam, we can help you get ready for it. Prepare for your exam with the help of our professional tutors and get access to 100% of the questions that have already been answered. Moreover, you can consult with our specialists in a given area and have any questions you have answered instantly.",
    },
    {
      id: 5,
      question: "How do I ensure that the solutions I receive are correct?",
      answer:
        "All of DOUBTQ's online tutors are thoroughly vetted to ensure their expertise in their respective fields. Before submitting the answers, our tutors double-check them to make sure they are accurate",
    },
    {
      id: 6,
      question: "What is the importance of reference material?",
      answer:
        "Tutors can get a head start on answering students' questions by reading up on the topics and techniques relevant to those problems in advance. This gives them a tool to make sure the answers they give are straightforward and presented in a consistent manner.",
    },
    {
      id: 7,
      question: "How much does DOUBTQ charge for answering a question?",
      answer:
        "Our charges shift based on a number of factors. We set prices based on the nature of the questions asked, how long they are, what category they fall under, and how difficult they are.",
    },
    {
      id: 8,
      question: "Can I submit multiple questions at once?",
      answer:
        "YES, in this regard, we require each student to submit multiple question at a time. However, we do not discourage students from posing as many questions as they want in the sake of enhancing their own education. It is possible to publish all of the questions at once so that quick answers may be provided.",
    },
    {
      id: 9,
      question: "How earliest can I get solution of uploaded questions?",
      answer:
        "We realize the significance of responding promptly. Timeliness of submissions is a major priority for all of our experts. Because of this, we promise that all solutions will be delivered before the date and time specified by us. Depending on the amount of work and the availability of the tutors, we may even be able to turn around the solutions in a matter of hours. We promise to submit all solutions as soon as possible.",
    },
    {
      id: 10,
      question: "How to contact customer care of DOUBTQ?",
      answer:
        "At DOUBTQ, we offer round the clock customer assistance. You can also reach out to us at info@doubtq.com or our WhatsApp Number.",
    },
    {
      id: 11,
      question: "What all services DOUBTQ provide?",
      answer:
        "DOUBTQ provides a variety of options for online tutoring to meet your unique requirements by asking your academic questions and doubts. Confer with the subject matter experts of your choosing in real time to get your questions answered. Provide assignment solving for qualified tutors and receive answers by the due date you set.",
    },
    {
      id: 12,
      question: "Does DOUBTQ offer free trial?",
      answer:
        "DOUBTQ doesn't offer any free trial as we value the time and the efforts subjects matter experts put into your doubts and questions to make them 100% unique. Our charges are affordable. We also offer exciting discounts and reward points to students to ensure they don't have to pay a high price. ",
    },
    {
      id: 13,
      question: "Does DOUBTQ have fixed price for questions?",
      answer:
        "Prices for all our services are variable. They depend upon the nature of the service, the type of question category you ask, Assignment category you ask and the length of the queries.",
    },
    {
      id: 14,
      question: "Is it legal to take study help from DOUBTQ?",
      answer:
        "No law forbids you from seeking out and using the services of experts in the field, as academic assistance is a perfectly legal service. No copied material was used in the creation of any of our answers. Our services are intended solely to help students succeed in their studies.",
    },
    {
      id: 15,
      question: "What is the payment structure for students at DOUBTQ?",
      answer:
        "DOUBTQ's payment options are versatile, allowing students to pay for answers at their convenience. Payment for each inquiry is typically based on the question category and the question's degree of difficulty.",
    },
    {
      id: 16,
      question: "Are DOUBTQ solutions affordable?",
      answer:
        "DOUBTQ always aims to offer the most competitive pricing to its customers. We keep our prices low because we know that students are our target audience. To get answers to your homework questions and doubts, you need not spend a lot of money. In any case, we are unable to promise that our prices are lower than those of competing sites. If you give us your money, we'll give you solutions written by experts in your field that are guaranteed to be correct and original.",
    },
    {
      id: 17,
      question:
        "What if I need 2 different copies of same submitted questions?",
      answer:
        "When it comes to online tutoring, we at DOUBTQ want to make sure you're covered no matter what. Just post the same question again if you require two separate sets of answers to the same problem. If you have more than one question, we'll pair you with two different experts who will each answer your question independently and provide you with a plagiarism-free, fully accurate solution",
    },
    {
      id: 18,
      question: "How long it will take to get solutions?",
      answer:
        "Before you submit, we will do everything we can to clear up any confusion you may have. Because of this, we insist that all tutors complete their work by the respective deadlines we set for each question type, and we guarantee that you will receive the answers in a timely manner. We'll do our best to get you the answers within a few minutes, but we can't promise we'll make the promised deadline.",
    },
    {
      id: 19,
      question: "Can I review and check previous work?",
      answer:
        "We believe in 100% client satisfaction and thus, we encourage them to review their work. We offer a period of 24hours where you can put in any query for your subject matter expert through the chat feature and review your doubt history in “your work” section. Additionally, all solutions you receive via DOUBTQ will be available on your dashboard for Life time for your reference.",
    },
    {
      id: 20,
      question: "Can I raise a query if my solution is not, correct?",
      answer:
        "It is our goal to provide you with answers that are both original and accurate. As a result of the depth of knowledge in their respective fields possessed by all of our experts, we guarantee that you will almost never receive inaccurate information. But if you think the answers, you get are wrong, you will get a option of Re-answer. Please review the answer within 12 hours. Post 48 hours, please feel free to reach out to us at info@doubtq.com if there is any discrepancy in the solutions you receive.",
    },
    {
      id: 21,
      question: "What to do if my question subjects are not listed in list?",
      answer:
        "We've made an effort to provide information on a wide range of topics on this portal for your own ease of use, but this is by no means an exhaustive list. But if your topic isn't there, don't worry; just use the 'Other' option under 'Miscellaneous' to send in your question.",
    },
    {
      id: 22,
      question: "Is it compulsory to give rating after my question answered?",
      answer:
        "No, it is not compulsory to give rating to your answered question but we want students to encourage our high qualified subject matter expert by giving their valuable vote.",
    },
    {
      id: 23,
      question: "What are redeemable vouchers and how it could be used?",
      answer:
        "Redeemable vouchers are the Amount you receive when you achieve some targets in our posting streak which we made for our pride students. We want every student to be part of this wonderful feature we made. These vouchers can be redeemed or cash out through your redeemable amount section.",
    },
    {
      id: 24,
      question: "What is minimum Amount for asking a question?",
      answer:
        "As we know that students are coming here to learn something regarding educational field and we don’t want any student to leave this platform due to high prices in questions. Thus, the minimum amount for asking a question is just $0.80.",
    },
    {
      id: 25,
      question:
        "Can I post a question which belongs to different question type category?",
      answer:
        "No, you can’t post a question which belongs to different question type category. For instance, if question belongs to MCQs and you asked a question of T/F then you will receive a notification of update the question type category.",
    },
    {
      id: 26,
      question:
        "Can I post a question in math’s subject who belongs to commerce field?",
      answer:
        "No, you don’t have to post a question which belongs to different subjects instead of my subject. If you do so then, you will receive a notification of update the subject category. So, that you receive the answer from respective subject expert only.",
    },
    {
      id: 27,
      question:
        "Can I ask a question of Case study ($0.28) question category to MCQ ($0.18) category?",
      answer:
        "It is our goal to provide you with answers that are both original and accurate. As we offer you to review within 12 hours when question answered. After that questions will move on to close question which you can refer for long time access but cannot review for that.",
    },

    {
      id: 28,
      question: "How much time I will get to review my answer?",
      answer:
        "No, you don’t have to post a question which belongs to different subjects instead of my subject. If you do so then, you will receive a notification of update the subject category. So, that you receive the answer from respective subject expert only.",
    },

    {
      id: 29,
      question: "Can I try to give downvote to get new answer?",
      answer:
        "No, you cannot be too smart as in our system, once you downvote it will be deleted from your history and you cannot get the same answer to see again and again and by chance you can get another wrong answer but there is less chance of wrong answer as we have highly professionalized experts with us. Thus, try to give less downvotes.",
    },
  ];

  return (
    <>
      <Header />
      <Container fluid>
        <main className="rbt-main-wrapper questionAnswerjustify">
          <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-inner text-center">
                    <h2 className="title fw-bold">FAQ's</h2>
                    <ul className="page-list">
                      <li className="rbt-breadcrumb-item">
                        <Link to="/" className="text-decoration-none">
                          Home
                        </Link>
                      </li>
                      <li>
                        <div className="icon-right">
                          <MdOutlineKeyboardArrowRight />
                        </div>
                      </li>
                      <li className="rbt-breadcrumb-item">Faqs</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rbt-accordion-area accordion-style-1 bg-color-white rbt-section-gap">
            <div className="container">
              <div className="row g-5">
                <div className="col-lg-12">
                  <div className="rbt-accordion-style accordion">
                    {/* <div className="section-title text-start mb--30">
                      <h1 className="title fw-bold mt-4">FAQ's</h1>
                    </div> */}
                    <div className="rbt-accordion-style rbt-accordion-04 accordion">
                      <div className="accordion" id="accordionExamplec3">
                        {accordionData.map((item) => (
                          <div className="acc-border" key={item.id}>
                            <CardHeader id={`heading${item.id}`}>
                              <Button
                                className={`accordion-button ${
                                  openAccordionId === item.id ? "" : "collapsed"
                                }`}
                                color="link"
                                onClick={() => toggleAccordion(item.id)}
                                aria-expanded={
                                  openAccordionId === item.id ? "true" : "false"
                                }
                                aria-controls={`collapseThree${item.id}`}
                              >
                                {item.question}
                              </Button>
                            </CardHeader>
                            <Collapse
                              isOpen={openAccordionId === item.id}
                              aria-labelledby={`heading${item.id}`}
                              data-parent="#accordionExamplec3"
                            >
                              <CardBody className="card-acc">
                                {item.answer}
                              </CardBody>
                            </Collapse>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* <div className="rbt-accordion-style rbt-accordion-04 accordion">
                                        <div className="accordion" id="accordionExamplec3">
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree1"
                                                >
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree1"
                                                        aria-expanded="true"
                                                        aria-controls="collapseThree1"
                                                    >
                                                        Can i submit multiple questions at once?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree1"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree1"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        NO, in this regard, we pay tutors on a per-question basis; hence, we require each student
                                                        to submit only one question at a time. However, we do not discourage students from posing
                                                        as many questions as they want in the sake of enhancing their own education. It is possible
                                                        to publish all of the questions at once so that quick answers may be provided.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree2"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree2"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree2"
                                                    >
                                                        How earliest can i get solution of uploaded questions?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree2"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree2"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        A we realize the significance of responding promptly. Timeliness of submissions
                                                        is a major priority for all of our experts. Because of this, we promise that all
                                                        solutions will be delivered before the date and time specified by us. Depending on
                                                        the amount of work and the availability of the tutors, we may even be able to turn
                                                        around the solutions in a matter of hours. We promise to submit all solutions as
                                                        soon as possible.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree3"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree3"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree3"
                                                    >
                                                        What all services DOUBTQ provide?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree3"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree3"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        DOUBTQ provides a variety of options for online tutoring to meet your unique
                                                        requirements by asking your academic questions and doubts. Confer with the subject
                                                        matter experts of your choosing in real time to get your questions answered. provide
                                                        assignment solving for qualified tutors and receive answers by the due date you set.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree4"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree4"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree4"
                                                    >
                                                        How to contact customer care of DOUBTQ?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree4"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree4"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        At DOUBTQ, we offer round the clock customer assistance. you can also reach out
                                                        to us action
                                                        <Link className="mx-1" to="mailto:info@doubtq.com">
                                                            info@doubtq.com
                                                        </Link>
                                                        or our whatsApp number.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree5"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree5"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree5"
                                                    >
                                                        I forgot my password. How do I reset it?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree5"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree5"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        In order to access the Forgot password page, use the "Forgot your password?"
                                                        link on the login page. Simply type in your username or email address and we'll
                                                        send you a link to a page where you can change your password. Forgot your password?
                                                        Just follow the instructions in the email and click the link.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree6"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree6"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree6"
                                                    >
                                                        How can I change my PASSWORD and my account settings?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree6"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree6"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Log in to your account on www.DoubtQ.com and click on Profile. Go to the
                                                        Account Settings Page. Select account settings and make the changes as needed.
                                                        You can update your password there.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree7"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree7"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree7"
                                                    >
                                                        How do I create my account?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree7"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree7"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        To join, visit www.DoubtQ.com, and either choose "Register" in the
                                                        right-hand menu bar or "sign up now" in the white space just to the right
                                                        of the "sign in" button. If you click on it, you'll be redirected to a new
                                                        web page. Simply complete the necessary fields now.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree8"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree8"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree8"
                                                    >
                                                        Can anybody see my identity?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree8"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree8"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        No. We never reveal our customers' personal information to
                                                        outside parties. Your information will be kept confidential
                                                        and will not be shared with any third parties outside of tutor.doubtq.com.
                                                        Have a look at what we have to say about privacy if you're interested.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree9"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree9"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree9"
                                                    >
                                                        How do I delete my account?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree9"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree9"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Please contact us and ask that your account be removed.
                                                        You will no longer have access to your account and it will
                                                        be permanently removed from our system.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree10"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree10"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree10"
                                                    >
                                                        Can you help in exam or quiz?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree10"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree10"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        While we can't assist you during the actual exam, we can help you get ready for it.
                                                        Prepare for your exam with the help of our professional tutors and get access to 100%
                                                        of the questions that have already been answered. Moreover, you can consult with our
                                                        specialists in a given area and have any questions you have answered instantly.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree11"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree11"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree11"
                                                    >
                                                        How do I ensure that the solutions I receive are correct?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree11"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree11"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        All of DOUBTQ's online tutors are thoroughly vetted to ensure their
                                                        expertise in their respective fields. Before submitting the answers,
                                                        our tutors double-check them to make sure they are accurate.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree12"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree12"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree12"
                                                    >
                                                        What is the importance of reference material?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree12"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree12"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Tutors can get a head start on answering students' questions by reading up
                                                        on the topics and techniques relevant to those problems in advance. This gives
                                                        them a tool to make sure the answers they give are straightforward and presented
                                                        in a consistent manner.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree13"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree13"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree13"
                                                    >
                                                        How much does DOUBTQ charge for answering a question?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree13"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree13"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Our charges shift based on a number of factors. We set prices based
                                                        on the nature of the questions asked, how long they are, what category
                                                        they fall under, and how difficult they are.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree14"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree14"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree14"
                                                    >
                                                        Can I submit multiple questions at once?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree14"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree14"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        NO, in this regard, we pay tutors on a per-question basis; hence, we
                                                        require each student to submit only one question at a time. However,
                                                        we do not discourage students from posing as many questions as they want in the
                                                        sake of enhancing their own education. It is possible to publish all of the questions
                                                        at once so that quick answers may be provided.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree15"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree15"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree15"
                                                    >
                                                        How earliest can I get solution of uploaded questions?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree15"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree15"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        We realize the significance of responding promptly. Timeliness of submissions is
                                                        a major priority for all of our experts. Because of this, we promise that all
                                                        solutions will be delivered before the date and time specified by us. Depending
                                                        on the amount of work and the availability of the tutors, we may even be able to
                                                        turn around the solutions in a matter of hours. We promise to submit all solutions
                                                        as soon as possible.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree16"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree16"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree16"
                                                    >
                                                        Does DOUBTQ offer free trial?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree16"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree16"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        DOUBTQ doesn't offer any free trial as we value the time and the
                                                        efforts subjects matter experts put into your doubts and questions
                                                        to make them 100% unique. Our charges are affordable (Refer to pricing section).
                                                        We also offer exciting discounts and reward points to students to ensure they
                                                        don't have to pay a high price.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree17"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree17"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree17"
                                                    >
                                                        Does DOUBTQ have fixed price for questions?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree17"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree17"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Prices for all our services are variable. They depend upon the nature of
                                                        the service, the type of question category you ask, and the length of the queries.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree18"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree18"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree18"
                                                    >
                                                        Is it legal to take study help from DOUBTQ?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree18"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree18"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        No law forbids you from seeking out and using the services of
                                                        experts in the field, as academic assistance is a perfectly legal service.
                                                        No copied material was used in the creation of any of our answers. Our services
                                                        are intended solely to help students succeed in their studies.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree19"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree19"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree19"
                                                    >
                                                        What is the payment structure for students at DOUBTQ?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree19"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree19"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        DOUBTQ's payment options are versatile, allowing students to pay for answers
                                                        at their convenience. Payment for each inquiry is typically based on the question
                                                        category and the question's degree of difficulty.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree20"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree20"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree20"
                                                    >
                                                        Does DOUBTQ provide Free Service?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree20"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree20"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Since our staff consists of professionals with advanced degrees
                                                        in their fields, we cannot afford to offer our services for free.
                                                        We compensate them for the work they do. As a result, we have to
                                                        charge money for them, though the rates we offer are still reasonable for students.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree21"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree21"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree21"
                                                    >
                                                        Are DOUBTQ solutions affordable?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree4"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree21"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        DOUBTQ always aims to offer the most competitive pricing to its customers.
                                                        We keep our prices low because we know that students are our target audience.
                                                        To get answers to your homework questions and doubts, you need not spend a lot of
                                                        money. In any case, we are unable to promise that our prices are lower than those of
                                                        competing sites. If you give us your money, we'll give you solutions written by experts
                                                        in your field that are guaranteed to be correct and original.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree22"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree22"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree22"
                                                    >
                                                        What if I need 2 different copies of same submitted questions?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree4"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree22"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        When it comes to online tutoring, we at DOUBTQ want to make sure you're
                                                        covered no matter what. Just post the same question again if you require
                                                        two separate sets of answers to the same problem. If you have more than
                                                        one question, we'll pair you with two different experts who will each answer
                                                        your question independently and provide you with a plagiarism-free, fully accurate solution.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree23"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree23"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree23"
                                                    >
                                                        How long it will take to get solutions?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree23"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree23"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Before you submit, we will do everything we can to clear up any confusion
                                                        you may have. Because of this, we insist that all tutors complete their work
                                                        by the respective deadlines we set for each question type, and we guarantee
                                                        that you will receive the answers in a timely manner. We'll do our best to get
                                                        you the answers within a few minutes, but we can't promise we'll make the promised deadline.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree24"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree24"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree24"
                                                    >
                                                        Can I review and check previous work?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree24"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree24"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        We believe in 100% client satisfaction and thus, we encourage them
                                                        to review their work. We offer a period of 24hours where you can
                                                        put in any query for your subject matter expert through the chat
                                                        feature and review your doubt history in “your work” section.
                                                        Additionally, all solutions you receive via DOUBTQ will be available
                                                        on your dashboard for Life time for your reference.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree25"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree25"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree25"
                                                    >
                                                        Can I raise a query if my solution is not, correct?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree25"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree25"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        It is our goal to provide you with answers that are both original and
                                                        accurate. As a result of the depth of knowledge in their respective
                                                        fields possessed by all of our experts, we guarantee that you will
                                                        almost never receive inaccurate information. But if you think the
                                                        answers, you get are wrong, you will get a option of Re-answer.
                                                        Please review the answer within 12 hours. Post 48 hours, please feel
                                                        free to reach out to us at info@doubtq.com if there is any discrepancy
                                                        in the solutions you receive.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree26"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree26"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree26"
                                                    >
                                                        What to do if my question subjects are not listed in list?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree26"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree26"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        We've made an effort to provide information on a wide range of
                                                        topics on this portal for your own ease of use, but this is by
                                                        no means an exhaustive list. But if your topic isn't there, don't worry;
                                                        just use the "Other" option under "Miscellaneous" to send in your question.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree27"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree27"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree27"
                                                    >
                                                        How to pay or add money in my Wallet?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree27"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree27"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        First of all, it’s very simple to add money in the Wallet as you
                                                        have to pay through any of the payment’s applications or UPI or bank account.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree28"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree28"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree28"
                                                    >
                                                        What is the use of Wallet?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree28"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree28"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        The use of Wallet is that you don’t have to add money again and
                                                        again for getting your questions solved. Wallet has credits which
                                                        you can use to post questions which our subject matter expert will answer it.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree29"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree29"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree29"
                                                    >
                                                        What bonus I will get for adding money?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree29"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree29"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        You will get good and exciting bonus when you add money and your doubts asked.
                                                        We are having 20% additional bonus on $50 and 20% additional bonus on $250.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree30"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree30"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree30"
                                                    >
                                                        What is minimum and maximum number of deposits?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree30"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree30"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        As we know that students are coming here to learn something regarding educational
                                                        field and we don’t want any student to leave this platform due to Non –
                                                        affordability of higher prices. Thus, we set –
                                                        The minimum amount is $5
                                                        The Maximum amount is $250

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree31"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree31"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree31"
                                                    >
                                                        What is Redeemable Amount and Available Amount?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree31"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree31"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Redeemable amount is that amount which you will get through us by refer to
                                                        friend and posting steak which can be easily used in asking your doubts and question.
                                                        Available amount is that amount which can be used in asking questions and it
                                                        can be shown only when you will deposit minimum amount.

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree32"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree32"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree32"
                                                    >
                                                        Is it compulsory to give rating after my question answered?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree32"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree32"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        No, it is not compulsory to give rating to your answered question but we want
                                                        students to encourage our high qualified subject matter expert by giving their
                                                        valuable vote.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree33"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree33"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree33"
                                                    >
                                                        What would happen if my Redeemable amount is over?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree33"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree33"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        There is no need to worry if your Redeemable amount is over as it is just
                                                        like available amount which can be used to ask questions depends on the
                                                        category you select.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree34"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree34"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree34"
                                                    >
                                                        What I do if there is no balance in my student account?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree34"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree34"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Without any balance you can’t use the service of asking a question or
                                                        doubt but you can search questions which are there in our platform for your
                                                        good academic studies. Whenever you wish to ask a question simply just add
                                                        money in your Wallet as minimum amount, we charge is $5.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree35"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree35"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree35"
                                                    >
                                                        How to ask a question and how my money used in asking a question?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree35"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree35"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Simply, you just have to ask a question of your selected
                                                        subject and after that you have to select your question type of category.
                                                        Once you select the question type you will see a screen of Amount showing
                                                        the Rupees which automatically debit when you click on post the question from your Wallet.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree36"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree36"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree36"
                                                    >
                                                        What are redeemable vouchers and how it could be used?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree36"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree36"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Redeemable vouchers are the Amount you receive when you achieve some
                                                        targets which we made for our pride students. We want every student to
                                                        be part of this wonderful feature we made. These vouchers can be redeemed
                                                        or cash out through your redeemable amount section.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree37"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree37"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree37"
                                                    >
                                                        What is minimum Amount for asking a question?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree37"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree37"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        As we know that students are coming here to learn something
                                                        regarding educational field and we don’t want any student to leave this
                                                        platform due to high prices in questions. Thus, the minimum amount for
                                                        asking a question is just $0.18.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree38"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree38"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree38"
                                                    >
                                                        Can I post a question which belongs to different question type category?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree38"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree38"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        No, you can’t post a question which belongs to different question type
                                                        category. For instance, if question belongs to MCQs and you asked a question
                                                        of T/F then you will receive a notification of update the question type category.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree39"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree39"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree39"
                                                    >
                                                        Can I post a question in math’s subject who belongs to commerce field?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree39"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree39"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        No, you don’t have to post a question which belongs to different subjects
                                                        instead of my subject. If you do so then, you will receive a notification of
                                                        update the subject category. So, that you receive the answer from respective subject expert only.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree40"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree40"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree40"
                                                    >
                                                        Does DOUBTQ have Assured and safety of our added funds?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree40"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree40"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        Yes, DOUBTQ has 100% legal Assured and safety of your funds. As we know our
                                                        students are customers and they are like God. Thus, we treat every student as
                                                        customer and give 100% fully confidentially safety of your respective amount.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree41"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree41"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree41"
                                                    >
                                                        Till when I should use my amount added in Wallet?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree41"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree41"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        You should use your amount anytime till you close or delete your account.
                                                        Your amount can be used when you ask a question and you can ask question
                                                        whenever you need and you can use your redeemable amount as well as available amount.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree42"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree42"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree42"
                                                    >
                                                        Can I withdraw or refund my amount added in Wallet?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree42"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree42"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        No, you can’t withdraw your amount. We do not provide every
                                                        student to get their money back. You can’t able to withdraw money back.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree43"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree43"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree43"
                                                    >
                                                        Can I ask a question of Case study ($0.28) question category to MCQ ($0.18) category?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree43"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree43"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        No, you can’t do this thing as you are being using this platform legally
                                                        and if you post a question in Case study ($0.28) question category to MCQ ($0.18)
                                                        category, The tutor has rights to update information and you will receive a notification
                                                        to update the category.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree44"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree44"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree44"
                                                    >
                                                        How much time I will get to review my answer?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree44"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree44"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        It is our goal to provide you with answers that are both original
                                                        and accurate. As we offer you to review within 12 hours when question answered.
                                                        After that questions will move on to close question which you can refer for long
                                                        time access but cannot review for that.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree45"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree45"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree45"
                                                    >
                                                        How many times I can Re-answer my question?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree45"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree45"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        No other platforms give you the re-answer option, as we offer you to
                                                        re-answer and get new answer if you feel it seems to be wrong for 1st time.
                                                        After 1st time you can’t re-answer it again.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item card">
                                                <h2
                                                    className="accordion-header card-header"
                                                    id="headingThree46"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree46"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree46"
                                                    >
                                                        Can I try to give downvote to get new answer?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree46"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree46"
                                                    data-bs-parent="#accordionExamplec3"
                                                >
                                                    <div className="accordion-body card-body">
                                                        No, you cannot be too smart as in our system, once you downvote it will be
                                                        deleted from your history and you cannot get the same answer to see again and
                                                        again and by chance you can get another wrong answer but there is less chance
                                                        of wrong answer as we have highly professionalized experts with us. Thus, try to give less downvotes.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Container>
      <Footer />
      <ScrollTop />
    </>
  );
};

export default Faq;
