import jpg from '../../Assets/images/jpg.png'
import pdf from '../../Assets/images/pdf.png'
import ppt from '../../Assets/images/ppt.png'
import doc from '../../Assets/images/doc.png'
import xls from '../../Assets/images/xls.png'
import files from '../../Assets/images/files.png'
import { Link } from 'react-router-dom'

const baseURL = process.env.REACT_APP_FILE_BASE_URL

export default function ExportFile(arr) {
    if (!arr || !Array.isArray(arr)) {
        return null; // or return some default component or message
    }

    return arr.map(e => {
        const fileExtension = e.split('.').pop();
        let icon = files;
        switch (fileExtension.toLowerCase()) {
            case 'jpg':
                icon = jpg;
                break;
            case 'pdf':
                icon = pdf;
                break;
            case 'ppt':
                icon = ppt;
                break;
            case 'docx':
                icon = doc;
                break;
            case 'xls':
                icon = xls;
                break;
            default:
                break;
        }

        return (
            <div key={e}>
                <Link to={`${baseURL}${e}`} target="_blank">
                    <img src={icon} alt={fileExtension} className="ms-3 me-3" width={60} />
                </Link>
            </div>
        );
    });
}
