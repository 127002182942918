import axiosInstance from "../axiosInterceptor";

export async function createQuestionAssignmentHelp(body) {
  const { categoryId, questionPDF, referencePDF } = body;
  const formdata = new FormData();
  formdata.append("categoryId", categoryId);
  formdata.append("subCategoryId", body.subCategory);
  formdata.append("question", body.question);
  formdata.append("subjectId", body.subject);
  if (body.haveReferenceFile === "Yes") {
    formdata.append("isReference", true);
  } else {
    formdata.append("isReference", false);
  }
  if (body.referenceStyle === "Yes") {
    formdata.append("isReferenceStyle", true);
    formdata.append("selectReferenceStyle", body.referenceStyleType);
  } else {
    formdata.append("isReferenceStyle", false);
  }
  if (body.lineSpacing === "Yes") {
    formdata.append("isLineSpacing", true);
    formdata.append("selectLineSpacing", body.lineSpacingType);
  } else {
    formdata.append("isLineSpacing", false);
  }
  if (body.fontStyle === "Yes") {
    formdata.append("isFontStyle", true);
    formdata.append("selectFontStyle", body.fontStyleType);
  } else {
    formdata.append("isFontStyle", false);
  }
  if (body.fontSize === "Yes") {
    formdata.append("isFontSize", true);
    formdata.append("selectFontSize", body.fontSizeType);
  } else {
    formdata.append("isFontSize", false);
  }
  if(body.noOfWords){
    formdata.append("numerOfWords", body.noOfWords);
  } else{
    formdata.append("numerOfWords", 0);
  }
  formdata.append("deadlineHours", body.deadline);
  if (questionPDF) {
    questionPDF.forEach((file, index) => {
      formdata.append(`questionPDF`, file);
    });
  }
  if (referencePDF) {
    referencePDF.forEach((file, index) => {
      formdata.append(`referencePDF`, file);
    });
  }
  const response = await axiosInstance.post(
    `/student/assignmentHelp/createQuestion`,
    formdata,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  const data = response?.data;
  return data;
}
