import React from "react";
import { Container, Input } from "reactstrap";
import RangeSlider from "../../../Components/RangeSlider";

const LiveForm3 = ({ payload, handleChange, formData }) => {
  return (
    <Container>
      <h1 className="step-header text-center pb-5">Number Of Words</h1>
      <div className="row align-items-center justify-content-center">
        <div className="col-9 border-1 border">
          <div className="row align-items-center justify-content-center">
            <div className="col-7">
              <div className="no-of-words-container">
                <span>
                  #
                  <Input
                    type="number"
                    placeholder=""
                    name="noOfWords"
                    value={payload.noOfWords}
                    onChange={handleChange}
                    className="border-bottom-2"
                  ></Input>{" "}
                  Words
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 className="step-header text-center pb-5">Set Your Deadline(hours)</h1>
      <div className="row my-2 align-items-center justify-content-center">
        <div className="col-9 border-1 border">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <span className="row fs-1 justify-content-center align-items-center p-5">
                <span className="col-auto">Fast</span>
                <span className="fs-1 col-9">
                  <svg
                    className="w-100"
                    width="400"
                    height="27"
                    viewBox="0 0 675 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.5 18.6248L0.5 8.37478L661.497 8.37478L661.497 0.106445L674.891 13.4998L661.497 26.8931L661.497 18.6248L0.5 18.6248Z"
                      fill="#E9E9EA"
                    />
                  </svg>
                </span>
                <span className="col-auto">Fastest</span>
              </span>
              <span className="row">
                <span className="p-4 d-block">
                  <RangeSlider
                    name="deadline"
                    dynamicArray={
                      formData?.hours?.length ? formData?.hours : []
                    }
                    onChange={handleChange}
                  />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-5  align-items-center justify-content-center">
        <div className="col-9 border-1 border">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 p-5 d-flex justify-content-center">
              <span className="estimatedPriceLabel mx-2">Estimated Price:</span>
              <span className="estimatedPrice">
                $
                {formData.estimatedPrice
                  ? formData.estimatedPrice?.toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LiveForm3;
