import {
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "../Buttons/Button";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const redirectURL =
      localStorage.getItem("serviceType") === "HomeworkHelp"
        ? `${window.location.origin}/dashboard/questions`
        : `${window.location.origin}/dashboard/completion`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: redirectURL,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <div>
      
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        style={{
          width: "50%",
          background: "aliceBlue",
          padding: "20px",
          margin: "0 auto",
          borderRadius: "10px",
          height: "auto",
          border: "1px solid #ed2bfc",
          marginTop: "20px"
        }}
      >
        <h1 style={{ fontFamily: "sans-serif", paddingBottom: "17px", borderBottom: "1px solid #ed2bfc" }} className="mt-2 mb-4">Order Payment Information</h1>
        <LinkAuthenticationElement id="link-authentication-element" />
        <PaymentElement id="payment-element" />
        <Button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="mt-4"
          text={!isLoading ? "Pay now" : "Payment in process ..."}
        />
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
};

export default CheckoutForm;
