import Header from "../../../Components/Header";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import HelpSteps from "../../../Components/HelpSteps";
import BonusSection from "../../../Components/BonusSection";
import StudentStories from "../../../Components/StudentStories";
import ChatHelpExperts from "../../../Components/ChatHelpExperts";
import WorldWide from "../../../Components/WorldWide";
import Footer from "../../../Components/Footer";
import { getSubcategoryWiseContent } from "../../../services/getSubcategoryWiseContent";
import HelpService from "../../../Components/HelpService";
import { useNavigate } from "react-router-dom";
import { getAllSubject } from "../../../services/getAllSubject";
import { getCategoryWiseSubcategory } from "../../../services/getCategoryWiseSubcategory";
import { getSubcategoryWisePrice } from "../../../services/getSubcategoryWisePrice";
import { getPriceLivehelp } from "../../../services/getPriceLivehelp";
import { useEffect, useState } from "react";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";

const LiveSessionVideoHelp = () => {
  const subCategoryId = "65a17a03740c57094df25ee2";

  const [pageData, setPageData] = useState([]);
  const fetchData = async () => {
    await getSubcategoryWiseContent(subCategoryId)
      .then((data) => {
        setPageData(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);
  const defaultValue = {
    haveReferenceFile: "No",
    date: `${new Date(new Date()).getFullYear()}-${
      new Date(new Date()).getMonth() + 1
    }-${new Date(new Date()).getDate()}`,
    subCategory: subCategoryId,
    hours:
      new Date(new Date().setHours(new Date().getHours() + 4)).getHours() %
        12 || 12,
    minutes: new Date(new Date()).getMinutes(),
    meradian: new Date(new Date()).getHours() < 12 ? "AM" : "PM",
    durationMintues: 60,
  };
  const [categories, setCategories] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [payload, setPayload] = useState(defaultValue);
  const [formData, setFormData] = useState({});
  const [selectedAssignment, setSelectedAssignment] = useState(subCategoryId);
  const navigate = useNavigate();
  const [isQuestionSubmited, setIsQuestionSubmited] = useState(false);
  const [error, setError] = useState({});
  const [isError, setIsError] = useState(false);
  const toggle = () => {
    setIsQuestionSubmited(false);
    setIsModelOpen(!isModelOpen);
    setSelectedAssignment(null);
    setPayload(defaultValue);
  };
  const totalSteps = 4;

  const fetchSubjectData = async () => {
    const response = await getAllSubject();
    setFormData((prevstate) => ({ ...prevstate, subject: response.data }));
  };

  useEffect(() => {
    fetchData();
    fetchSubjectData();
    fetchCategories();
    // fetchHomeworkCategory();
  }, []);

  const fetchCategories = async () => {
    await getCategoryWiseSubcategory("65524e353c005ef3b51907c2")
      .then((data) => {
        setCategories(data.subCategory);
      })
      .catch((err) => console.log(err));
  };

  const fetchQuestionPricingData = async (id) => {
    const response = await getSubcategoryWisePrice(id);
    setPayload((prevstate) => ({
      ...prevstate,
      price: response?.price?.studentPrice,
    }));
  };

  const handleUploadFile = async (files, name) => {
    const uploaded = payload[name] ? [...payload[name]] : [];

    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    if (uploaded.length === 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    return uploaded;
  };
  // const handleChange = async (event) => {
  //   if (event.target?.files && event.target?.files[0]) {
  //     const data = await handleUploadFile(
  //       Array.prototype.slice.call(event.target?.files),
  //       event.target.name
  //     );
  //     setPayload((prevstate) => ({
  //       ...prevstate,
  //       [event.target.name]: data,
  //     }));
  //   } else if (event.target.name === "date") {
  //     const dateData = `${new Date(event.target.value).getFullYear()}-${
  //       new Date(event.target.value).getMonth() + 1
  //     }-${new Date(event.target.value).getDate()}`;
  //     setPayload((prevstate) => ({
  //       ...prevstate,
  //       [event.target.name]: dateData ?? "",
  //     }));
  //   } else if (event.target.name === "hours") {
  //     if (
  //       parseInt(event.target.value) > 0 &&
  //       parseInt(event.target.value) <= 12
  //     ) {
  //       setPayload((prevstate) => ({
  //         ...prevstate,
  //         [event.target.name]: event.target.value,
  //       }));
  //     }
  //   } else if (event.target.name === "minutes") {
  //     if (
  //       parseInt(event.target.value) >= 0 &&
  //       parseInt(event.target.value) <= 60
  //     ) {
  //       setPayload((prevstate) => ({
  //         ...prevstate,
  //         [event.target.name]: event.target.value,
  //       }));
  //     }
  //   } else if (event.target.name === "durationMintues") {
  //     const { value } = event.target;
  //     if (value === "" || value === 0) {
  //       setIsError(true);
  //       setError((prevstate) => ({
  //         ...prevstate,
  //         [event.target.name]: "Minutes must be greater than 1",
  //       }));
  //       setPayload((prevstate) => ({
  //         ...prevstate,
  //         [event.target.name]: event.target.value,
  //       }));
  //     } else if (
  //       parseInt(event.target.value) >= 0 &&
  //       parseInt(event.target.value) <= 2000
  //     ) {
  //       setPayload((prevstate) => ({
  //         ...prevstate,
  //         [event.target.name]: event.target.value,
  //       }));
  //       setIsError(false);
  //       setError((prevstate) => ({
  //         ...prevstate,
  //         [event.target.name]: "",
  //       }));
  //     }
  //   } else {
  //     setPayload((prevstate) => ({
  //       ...prevstate,
  //       [event.target.name]: event.target.value,
  //     }));
  //     if (event.target.value) {
  //       setIsError(false);
  //       setError((prevstate) => ({ ...prevstate, [event.target.name]: "" }));
  //     }
  //   }
  // };

  // const handleRemoveFile = (index, key) => {
  //   const payloadClone = payload;
  //   if (payloadClone[key]) {
  //     payloadClone[key]?.splice(index, 1);
  //   }
  //   setPayload({ ...payloadClone });
  // };

  const getLiveSessionPrice = async () => {
    const response = await getPriceLivehelp({
      durationMintues: payload.durationMintues,
    });
    if (response.isSuccess) {
      setFormData((prevstate) => ({
        ...prevstate,
        studentPrice: response?.price?.studentPrice,
        // studentPrice: response?.price,
      }));
    }
  };

  useEffect(() => {
    if (payload.durationMintues) {
      getLiveSessionPrice();
    }
  }, [payload.durationMintues]);

  // const handlePostQuestion = async () => {
  //   const response = await createQuestionLivesessionHelp(payload);
  //   if (response.isSuccess) {
  //     if (localStorage.getItem("token")) {
  //       const updatedQuestion = await updateLiveSessionQuestion(
  //         response.question._id,
  //         {
  //           discountPrice: 0,
  //           promoPrice: 0,
  //           promoCode: "",
  //         }
  //       );
  //       if (updatedQuestion.isSuccess) {
  //         localStorage.removeItem("questionId");
  //         navigate(`/orderStatus/${response.question._id}`);
  //       }
  //     }
  //     setIsQuestionSubmited(true);
  //     localStorage.setItem("questionId", response.question._id);
  //   }
  // };

  useEffect(() => {
    if (payload.questionType) {
      fetchQuestionPricingData(payload.questionType);
    }
  }, [payload.questionType]);

  return (
    <>
      {/* <Header />
            <MainBanner id={subCategoryId} />
            <AskSection />
            <HelpService
                helpServiceDescription="1-on-1 Video Help"
                helpServiceTitle="DoubtQ guarantees"
                subCategoryID={subCategoryId}
            />
            <HelpSteps
                subCategoryId={subCategoryId}
            />
            <BonusSection subCategoryId={subCategoryId} />
            <StudentStories />
            <ChatHelpExperts />
            <WorldWide />
            <Footer /> */}
      <Header />
      <MainBanner id={subCategoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection
        onSignUp={() => {
          setIsModelOpen(true);
          setIsQuestionSubmited(true);
        }}
      />
      <HelpService
        helpServiceDescription="1-on-1 chat Help"
        helpServiceTitle="DoubtQ guarantees"
        subCategoryID={subCategoryId}
      />
      <HelpSteps subCategoryId={subCategoryId} />
      <BonusSection
        subCategoryId={subCategoryId}
        onClick={() => {
          setIsModelOpen(true);
        }}
      />
      <StudentStories />
      <ChatHelpExperts />
      <WorldWide />
      <Footer />
      {/* {isModelOpen ? (
        <Popup
          key={subCategoryId}
          isOpen={isModelOpen}
          toggle={toggle}
          totalSteps={totalSteps}
          payload={payload}
          handleChange={handleChange}
          formData={formData}
          type="livesessionHelp"
          serviceType="LiveSessionHelp"
          handlePostQuestion={handlePostQuestion}
          isQuestionSubmited={isQuestionSubmited}
          categories={categories}
          setSelectedAssignment={setSelectedAssignment}
          selectedAssignment={selectedAssignment}
          setPayload={setPayload}
          handleRemoveFile={handleRemoveFile}
          isError={isError}
          setIsError={setIsError}
          error={error}
          setError={setError}
        />
      ) : (
        <></>
      )} */}
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "LiveSessionHelp",
            type: "livesessionHelp",
            totalSteps: 4,
          }}
          subCategory={subCategoryId}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
    </>
  );
};

export default LiveSessionVideoHelp;
