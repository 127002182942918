import React from 'react'
import './MultipleChoice.scss';
import MultipleCardImg1 from '../Assets/images/background/multiple-card-img-1.svg'
import MultipleCardImg2 from '../Assets/images/background/multiple-card-img-2.svg'
import MultipleCardImg3 from '../Assets/images/background/multiple-card-img-3.svg'
import MultipleCardImg4 from '../Assets/images/background/multiple-card-img-4.svg'

const arr = [MultipleCardImg1, MultipleCardImg2, MultipleCardImg3, MultipleCardImg4]

const MultipleChoice = ({ title = '' }) => {
    return (
        <div className='multiple-choice-container'>
            <h2>Advantages of <b>{title}</b></h2>
            <div className="multiple-card-img-container">
                {
                    arr.map((e) => {
                        return <MultipleChoiceCard data={e} />
                    })
                }
            </div>
        </div>
    )
}

export default MultipleChoice

const MultipleChoiceCard = (data) => {
    return (
        <div className='multiple-card-img'>
            <img src={data.data} key={data} />
        </div>
    )
}