import React from 'react'
import './AdvantagesOfQuestions.scss'

const AdvantagesOfQuestions = ({ title = '', arr = [], img = null }) => {
    // const arr = [
    //     "Can customize to use 'Yes' and 'No' or 'I Disagree' and 'I Agree'",
    //     "Can customize to use 'Yes' and 'No' or 'I Disagree' and 'I Agree'",
    //     "Can customize to use 'Yes' and 'No' or 'I Disagree' and 'I Agree'",
    //     "Can customize to use 'Yes' and 'No' or 'I Disagree' and 'I Agree'",
    //     "Can customize to use 'Yes' and 'No' or 'I Disagree' and 'I Agree'",
    //     "Can customize to use 'Yes' and 'No' or 'I Disagree' and 'I Agree'",
    // ]
    return (
        <div className='advantages-of-questions-container'>
            <div className='advantages-of-questions-header'>
                <span>Advantages of </span><b>{title}</b>
            </div>
            <div className='advantages-of-questions'>
                <div className="advantages">
                    <ul>
                        {arr.map(e => {
                            return (
                                <li>
                                    <sup>
                                        {e}
                                    </sup>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="advantages-of-questions-img">
                    <img src={img} alt="" />
                </div>
            </div>
        </div>
    )
}

export default AdvantagesOfQuestions