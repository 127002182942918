import React, { useEffect, useState } from "react";
import "./HelpSteps.scss";
import HelpStepsBG from "../Assets/images/background/HelpStepsBackground.png";
import PriceTagIcon from "../Assets/icons/pricetag.svg";
import Button from "./Buttons/Button";
import { getAllHelpSteps } from "../services/getAllHelpSteps";
import { ImageLink } from "../constants";
import axiosInstance from "../axiosInterceptor";

const arr = [
  {
    title: "Fill Details",
    description:
      "Fill in your details at VAIDIK register to complete the sign-up process.",
  },
  {
    title: "Sign Up",
    description:
      "On the dashboard, place your desired Question and upload your queries.",
  },
  {
    title: "Place Your Help",
    description:
      "Depending on your Question Type, you will get price of that Question and Submit it. ",
  },
  {
    title: "Make Payment",
    description:
      "Once payment is done, you will get Your Question answer done before mentioned deadline.",
  },
  {
    title: "Post Help",
    description:
      "Once payment is done, you will get Your Question answer done before mentioned deadline.",
  },
];

const HelpSteps = ({ subCategoryId }) => {
  const [pageData, setPageData] = useState([]);
  const fetchData = async () => {
    await getAllHelpSteps({
      subCategoryId,
    })
      .then((data) => {
        setPageData(data.data[0]);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [setting, setSetting] = useState([]);
  const fetchSettingData = async () => {
      try {
          const res = await axiosInstance.get(`/admin/setting/getSetting`);
          setSetting(res.data.data)
      } catch (error) {
          console.log(error);
      }
  };

  useEffect(() => {
      fetchSettingData();
  }, []);

  return (
    <div className="help-container">
      <div className="help-steps-container">
        <h2 className="title">
          {pageData?.mainTitle ?? "Complete Help in 5 Steps"}
        </h2>
        <div className="description">It’s THAT Simple.</div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              {pageData?.helpSteps?.map((item, index) => {
                return (
                  <HelpStepsCard {...item} index={index + 1} key={index} />
                );
              })}
            </div>
            <div className="d-none d-lg-block col-lg-6">
              <img src={ImageLink(setting.completeHelpImage)} alt="" className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HelpStepsCard = ({ title = "", description = "", index = 0 }) => {
  return (
    <div className="help-steps-card">
      <div className="help-steps-title-parent">
        <span className="help-steps-card-index">
          0{index}
          <span className="help-steps-card-down-arrow" />
        </span>
        <span className="help-steps-card-title">{title}</span>
      </div>
      <span className="help-steps-card-description">{description}</span>
    </div>
  );
};

export default HelpSteps;
