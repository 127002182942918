import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import ExportFile from "./Helper";

const Solution = ({ orderData, disable }) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion
      open={open}
      toggle={toggle}
      className="accordian-Wrapper content-card"
    >
      <AccordionItem className={disable ? "disabled" : ""}>
        <AccordionHeader targetId="1" className="heading">
          Solution
        </AccordionHeader>
        {!disable ? (
          <AccordionBody accordionId="1">
            <div className="d-flex">
              <div className="col-sm-6">
                {/* <Input type="file" /> */}
                <div className="d-flex align-items-center mt-4">
                  {ExportFile(orderData?.data?.getQuestion?.questionPDF)}
                </div>
              </div>
            </div>
          </AccordionBody>
        ) : (
          <></>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default Solution;
