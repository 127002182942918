import React, { useEffect, useState } from "react";
import { Card, Col, Container, Input, Row } from "reactstrap";

import search from "../../Assets/icons/Search.svg";
import Library from "./Library";
import { searchQuestion } from "../../services/searchQuestion";

const DashboardLibrary = () => {
  const [activeSubject, setActiveSubject] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDataFetching, setIsDataFetching] = useState(true);

  const fetchData = async () => {
    try {
      const data = await searchQuestion(
        searchTerm,
        currentPage,
        5,
        activeSubject
      );
      setPageData(data.data);
      setTotalPages(data.totalPages);
      setIsDataFetching(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearchQuestion = () => {
    setIsDataFetching(true);
    fetchData();
  };

  useEffect(() => {
    setIsDataFetching(true);
    fetchData();
  }, [currentPage, activeSubject]);

  return (
    <div>
      <div className="library-top py-5">
        <Container className="w-85 dashboard">
          <Row className="pt-5">
            <Col className="position-relative">
              <Input
                placeholder="Search, solutions, and more...."
                className="py-4 search"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              <div
                className="d-flex p-3 rounded-3 search-icon after-login-btn"
                onClick={handleSearchQuestion}
              >
                <img src={search} alt="" />
              </div>
            </Col>
          </Row>
          <Row className="mt-5 pb-5">
            <Col lg={6} className="mb-2">
              <Card className="library-top-card p-3 rounded-4">
                <h2 className="fw-bold">
                  <span className="card-number">97%</span> Students <br />
                  got better Grade
                </h2>
              </Card>
            </Col>
            <Col lg={6} className="mb-2">
              <Card className="library-top-card p-3 rounded-4">
                <h2 className="fw-bold">
                  Daily <span className="card-number">9709</span> <br />
                  people use Q&A
                </h2>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Library
        heading={"Access all questions by Subject"}
        pageData={pageData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        activeSubject={activeSubject}
        setActiveSubject={setActiveSubject}
        isDataFetching={isDataFetching}
      />
    </div>
  );
};

export default DashboardLibrary;
