import React, { useEffect, useState } from "react";
import "./HelpService.scss";
// import Button from "../Components/Buttons/Button";
import PriceTagIcon from '../Assets/icons/pricetag.svg'
import { getSubcategoryWiseContent } from "../services/getSubcategoryWiseContent";

// const arr = [
//     {
//         name: "MCQ",
//         icon: PriceTagIcon,
//         description:
//             "With VAIDIK's 100% unique, accurate, and around-the-clock homework assistance, you can meet even the most stringent of deadlines with ease.",
//     },
//     {
//         name: "MCQ",
//         icon: PriceTagIcon,
//         description:
//             "With VAIDIK's 100% unique, accurate, and around-the-clock homework assistance, you can meet even the most stringent of deadlines with ease.",
//     },
//     {
//         name: "MCQ",
//         icon: PriceTagIcon,
//         description:
//             "With VAIDIK's 100% unique, accurate, and around-the-clock homework assistance, you can meet even the most stringent of deadlines with ease.",
//     },
//     {
//         name: "Way You Prefer to Learn",
//         icon: PriceTagIcon,
//         description:
//             "With VAIDIK's 100% unique, accurate, and around-the-clock homework assistance, you can meet even the most stringent of deadlines with ease.",
//     },
//     {
//         name: "True False",
//         icon: PriceTagIcon,
//         description:
//             "Get the correct response every time with VAIDIK's aid that is guaranteed to be 100% unique, accurate, and true false.",
//     },
//     {
//         name: "Fill In The Blanks",
//         icon: PriceTagIcon,
//         description:
//             "Put away your patience and allow the professionals create fill-ins for you that are exact, well-structured, and completely correct.",
//     },
//     {
//         name: "Match The Following",
//         icon: PriceTagIcon,
//         description:
//             "If you want superior scores as well as acclaim, getting excellent and comprehensive matching from VAIDIK Matching Help is the way to go.",
//     },
//     {
//         name: "Definitions",
//         icon: PriceTagIcon,
//         description:
//             "With assistance from VAIDIK's compelling and effective Definitions, you may captivate your audience and get A+ marks in the process.",
//     },
//     {
//         name: "Other",
//         icon: PriceTagIcon,
//         description:
//             "With assistance from VAIDIK's compelling and effective Definitions, you may captivate your audience and get A+ marks in the process.",
//     },
// ];

const HelpService = ({ helpServiceTitle = 'DoubtQ Guarantees', helpServiceDescription = '', subCategoryID = '' }) => {
    const [pageData, setPageData] = useState([]);
    const fetchData = async () => {
        await getSubcategoryWiseContent(subCategoryID)
            .then((data) => {
                setPageData(data.multipleBenefitData);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [subCategoryID]);
    return (
        <div className="background-shadow help-service">
            <span>
                <span className="title">{helpServiceTitle} </span>
                <span className="description">
                    {helpServiceDescription}
                </span>
            </span>
            <div className="help-service-card-container">
                <div className="help-service-container">
                    {pageData?.benefitData?.map((item, index) => {
                        return <CategoryCard key={index} details={item} />;
                    })}
                </div>
            </div>
        </div>
    );
};

const CategoryCard = ({ details }) => {
    return (
        <div className="help-card">
            <img className="help-card-icon py-2" src={details.icon ?? PriceTagIcon} ></img>
            {/* <Button text="Explore" varient="outlined"></Button> */}
            {/* </div> */}
            <hr />
            <div className="help-card-title py-3 pt-2 m-0">{details.title}</div>
            <div className="help-card-description">{details.description}</div>
        </div>
    );
};

export default HelpService;
