import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Col, FormGroup, Input, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import { myquestions } from '../../services/myquestions';
import { getCategoryWiseSubcategory } from '../../services/getCategoryWiseSubcategory';
import axiosInstance from '../../axiosInterceptor';
import Question from '../../Assets/images/Question.png'
import axios from 'axios';

const DashboardQuestions = () => {
    const [pageData, setPageData] = useState([]);
    const [pageData1, setPageData1] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(false);

    const [questionsCount, setQuestionsCount] = useState({
        allQuestions: 0,
        inProgressQuestions: 0,
        openQuestions: 0,
        closeQuestions: 0
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get("student/myQuestionsCount");
                if (response.data.isSuccess) {
                    setQuestionsCount(response.data.data);
                } else {
                    console.error(response.data.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const navigator = useNavigate();

    const myquestionslist = async (pageNo, category) => {
        try {
            setLoading(true);
            const data = await myquestions(searchTerm, pageNo, 10, category, fromDate, toDate);
            setPageData(data);
            setTotalPages(data.totalPages);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const getCategory = async () => {
        try {
            const data = await getCategoryWiseSubcategory("65524e5e3c005ef3b51907c6");
            setPageData1(data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        myquestionslist(currentPage, selectedCategory);
    }, [currentPage, selectedCategory, fromDate, toDate]);

    useEffect(() => {
        getCategory();
    }, []);

    const handlePaginationClick = (page) => {
        setCurrentPage(page);
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    }

    const handleRoutes = (data) => {
        localStorage.setItem("serviceType", data.paymentSection);
        if (data.studentPaymentStatus === "Unpaid") {
            navigator(`/orderStatus/${data._id}`)
        }
        else {
            navigator('/dashboard/answer', { state: data })
        }
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const paymentIntent = searchParams.get("payment_intent");

    const updatePayment = useCallback(async () => {
        if (paymentIntent) {
            const token = localStorage.getItem("token");
            const apiEndPoint = process.env.REACT_APP_API;
            const response = await axios.post(
                `${apiEndPoint}student/payment/paymentDetails`,
                { paymentIntent },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = response?.data;
            if (data.isSuccess) {
                setSearchParams();
            }
        }
    }, [paymentIntent]);

    useEffect(() => {
        updatePayment();
    }, []);
    return (


        <div className='mt-4 p-4 d-md-flex'>
            <div className='Dashboard-Questions'>
                <h2 className='fw-semi-bold Dashboard-title'>My Questions</h2>
                <nav className="mainmenu-nav">
                    <ul className="dashboard-mainmenu rbt-default-sidebar-list p-0">
                        <li className='myquestions sidenav'>
                            <Link to="#" className="text-decoration-none">
                                <i className="feather-arrow-right" />
                                <span>All</span>
                            </Link>
                            <small className="badge color-body">{questionsCount?.allQuestions}</small>
                        </li>
                        <li className='myquestions sidenav'>
                            <Link to="#" className='text-decoration-none'>
                                <i className="feather-arrow-right" />
                                <span>In Progress</span>
                            </Link>
                            <small className="badge color-body">{questionsCount?.inProgressQuestions}</small>
                        </li>
                        <li className='myquestions sidenav'>
                            <Link to="#" className='text-decoration-none'>
                                <i className="feather-arrow-right" />
                                <span>Open Question</span>
                            </Link>
                            <small className="badge color-body">{questionsCount?.openQuestions}</small>
                        </li>
                        <li className='myquestions sidenav'>
                            <Link to="#" className='text-decoration-none'>
                                <i className="feather-arrow-right" />
                                <span>Close Question</span>
                            </Link>
                            <small className="badge color-body">{questionsCount?.closeQuestions}</small>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className='questions-list'>
                <h2 className='fw-semi-bold mx-4 mt-4'>My Questions</h2>
                <div className='mx-4'>
                    <h3 className='py-2 mt-4 fw-bold'>
                        Filter
                    </h3>
                    <Row>
                        <Col md="6" className='mb-4'>
                            <Input
                                type="date"
                                className="form-control date_padding"
                                id="inputFromDate"
                                value={fromDate}
                                onChange={(e) => {
                                    setFromDate(e.target.value);
                                    setToDate(e.target.value);
                                }}
                            />
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Input
                                    id="exampleSelect"
                                    name="subCategoryId"
                                    type="select"
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                >
                                    <option value="" disabled> Question Type </option>
                                    {pageData1.subCategory?.map((category, index) => (
                                        <option key={index} value={category.name}>{category.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='student-question-list mb-4'>
                        <Col md="12">
                            {loading && (
                                <h2 className="text-center">Loading...</h2>
                            )}
                            {!loading && pageData?.data && pageData?.data?.length === 0 && (
                                <div className='text-center mt-4'>
                                    <img className='no-question-found' src={Question} alt='No Question Found' />
                                    <p className="text-center mt-4">No Question Found</p>
                                </div>
                            )}
                            {!loading && pageData?.data?.map((item, index) => (
                                (selectedCategory === '' || selectedCategory === item.subCategoryId.name) && (
                                    <div className='question-border' key={index}>
                                        {/* <p className='mt-4 d-flex cursor-pointer' dangerouslySetInnerHTML={{ __html: `${index + 1}. ${item.question}` }} onClick={() => handleRoutes(item)} /> */}
                                        <p className='mt-4 d-flex cursor-pointer mb-4' dangerouslySetInnerHTML={{ __html: `${index + 1}. ${item.transcriptText}` }} onClick={() => handleRoutes(item)} />
                                        <div className='d-xl-flex'>
                                            <span className='subject-name'>{item.subjectId.questionSubject}</span>
                                            <span className='question-type'>{item.subCategoryId.name}</span>
                                        </div>
                                    </div>
                                )
                            ))}
                        </Col>
                    </Row>
                    <Row className="pt-3 float-end">
                        <Col className='text-center'>
                        </Col>
                        <Pagination className="mt-4">
                            <PaginationItem disabled={currentPage === 1}>
                                <PaginationLink previous onClick={() => handlePaginationClick(currentPage - 1)} />
                            </PaginationItem>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <PaginationItem key={i + 1} active={i + 1 === currentPage}>
                                    <PaginationLink onClick={() => handlePaginationClick(i + 1)}>
                                        {i + 1}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}
                            <PaginationItem disabled={currentPage === totalPages}>
                                <PaginationLink next onClick={() => handlePaginationClick(currentPage + 1)} />
                            </PaginationItem>
                        </Pagination>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default DashboardQuestions