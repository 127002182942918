import React, { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { updatePaymentDetails } from "./../../services/updatePaymentDetails";

import oops from "../../Assets/images/oops.png";
import CompletionRequirements from "./CompletionRequirements";

import "./OrderHistory.scss";
import Button from "../Buttons/Button";

const OopsError = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentIntent = searchParams.get("payment_intent");

  // const updatePayment = useCallback(async () => {
  //   if (paymentIntent) {
  //     const response = await updatePaymentDetails({ paymentIntent });
  //     if (response.isSuccess) {
  //       setSearchParams();
  //     }
  //   }
  // }, [paymentIntent]);

  // useEffect(() => {
  //   updatePayment();
  // }, []);
  return (
    <>
      {/* <div>Payment Intent {paymentIntent}</div> */}
      <div className="wrapper">
        <div>
          <img src={oops} alt="" style={{ height: "250px" }} />
        </div>
        <div className="pt-5 content-wrapper">
          <h1 className="fw-bold">Order Number</h1>
          <h1 className="pt-2 pb-5">Assignment Help - Article Writing</h1>
          <div className="bg-white py-5 rounded-4 text-start ps-5 mb-5 text-center border-2 border">
            <h1 className="fw-bold mb-5">
              Your order amount - <span className="oops-heading">$20</span>
            </h1>
            <Button text="Try Again" varient="linear-gradient-primary" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OopsError;
