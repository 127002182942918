import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "./search.scss";
import { searchQuestion } from "../../services/searchQuestion";
import logo from "../../Assets/logo/logo.png";
import Group from "../../Assets/images/Group 616.png";
import Layer from "../../Assets/images/Layer_x0020_1.png";
import noques from "../../Assets/images/EFEFEFW 1.png";
import { getAllSubject } from "../../services/getAllSubject";
import Button from "../../Components/Buttons/Button";
import library from "../../Assets/icons/library.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import Smile_Icon from "../../Assets/images/Smile_Icon.svg";
import CommonForm from "../HomePage/Form/CommonForm";
import ScrollTop from "../../Components/Buttons/ScrollTop";
const baseURL = process.env.REACT_APP_FILE_BASE_URL;

const Search = () => {
  const [subjectId, setSubjectId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [formData, setFormData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDataFetching, setIsDataFetching] = useState(true);
  const navigate = useNavigate();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const fetchData = async (pageNo) => {
    try {
      const search = searchParams.get("query");
      const data = await searchQuestion(search, currentPage, 10, subjectId);
      setPageData(data.data);
      setTotalPages(data.totalPages);
      setIsDataFetching(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSubjectData = async () => {
    try {
      const response = await getAllSubject();
      setFormData(response.data);
    } catch (error) {
      console.log("Error fetching subject data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    setIsDataFetching(true);
  }, [searchParams]);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, subjectId]);

  useEffect(() => {
    fetchSubjectData();
  }, []);

  const handlePaginationClick = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const handleViewAnswer = (text = "") => {
    navigate(`/homework-help/question-and-answer/${text}`);
  };

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handlePopup = (serviceType, type, totalSteps) => {
    setIsModelOpen(true);
    setServiceType({ serviceType, type, totalSteps });
    localStorage.setItem("serviceType", serviceType);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Header />
      <div className="d-md-flex">
        <div className="mt-4 searchmobile">
          <>
            {pageData && pageData.length > 0 ? (
              pageData.map((item, index) => (
                <div key={index} className="search-que">
                  <div
                    className={`library-card d-grid card-${index % 2 === 0 ? "even" : "odd"
                      }`}
                  >
                    <div className="que">
                      <div className="text-start d-flex">
                        <span>Q:</span>
                        <p className="mx-2 text-start">
                          {item?.transcriptText && item.transcriptText.slice(0, 150) + (item.transcriptText.length > 150 ? '...' : '')}
                        </p>
                      </div>
                    </div>
                    {item?.answer && (
                      <div className="que mt-4">
                        <div className="text-start d-flex">
                          <span>A:</span>
                          <div
                            className="mx-2 text-gray text-start"
                            dangerouslySetInnerHTML={{
                              __html: `${item?.answer.slice(0, 60)}${item?.answer.length > 60 ? "..." : ""}`,
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <Button
                      text="View answer"
                      varient="search"
                      className="view-button mt-4 p-0 mx-4"
                      onClick={() => handleViewAnswer(item?.transcriptUrl)}
                    />
                  </div>
                </div>
              ))
            ) : isDataFetching ? (
              <div className="fs-4">{`Loading ...`}</div>
            ) : (
              <div>
                <div className="no-que mt-4">
                  <img src={noques} alt="no" />
                </div>
                <h2 className="no-data-message mt-4">NO SOLUTION FOUND</h2>
                <div
                  style={{
                    backgroundColor: "#f0f0f0",
                    width: "90%",
                    padding: "10px",
                    margin: "50px auto 0",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  <div className="row align-items-center justify-content-between">
                    <div className="col-md-2 mb-2">
                      <img
                        src={Smile_Icon}
                        alt="smile"
                        style={{ width: "80px" }}
                      />
                    </div>
                    <div
                      className="col-md-3  d-flex align-items-center"
                      style={{
                        position: "relative",
                        textAlign: "left",
                        height: "100%",
                      }}
                    >
                      <h1 style={{ margin: 0, fontWeight: "800" }}>
                        <span style={{ color: "black" }}>Don't</span>{" "}
                        <span style={{ color: "blue" }}>worry</span>
                      </h1>
                      <div
                        style={{
                          position: "absolute",
                          top: "-10px",
                          bottom: "-10px",
                          right: "-10px",
                          borderRight: "2px solid #CCCCCC",
                        }}
                      ></div>
                    </div>
                    <div className="col-md-3 mb-3 px-3">
                      <h1
                        style={{
                          fontSize: "15px",
                          color: "#000000",
                          textAlign: "start",
                        }}
                      >
                        Ask an expert.
                      </h1>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "black",
                          fontWeight: "bold",
                          textAlign: "start",
                        }}
                      >
                        Get a step-by-step solution in as little as 15 minutes!*
                      </div>
                    </div>
                    <div className="col-md-3 mb-3 px-3">
                      <button
                        className="do-my-ass"
                        style={{
                          background:
                            "linear-gradient(90deg, #5956e9 5.64%, #e541ed 96.39%)",
                          boxShadow: "0px 10px 20px rgba(74, 74, 112, 0.1)",
                          border: "2px solid #FFFFFF", // White border
                          fontSize: "14px",
                          color: "#FFF",
                          borderRadius: "9px",
                          padding: "1rem",
                        }}
                        onClick={() =>
                          handlePopup("HomeworkHelp", "mcqHelp", 3)
                        }
                      >
                        Ask a Question
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="float-end">
              <Pagination className="mt-4">
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePaginationClick(currentPage - 1)}
                  />
                </PaginationItem>
                {Array.from({ length: totalPages || 0 }, (_, i) => (
                  <PaginationItem key={i + 1} active={i + 1 === currentPage}>
                    <PaginationLink
                      onClick={() => handlePaginationClick(i + 1)}
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === (totalPages || 1)}>
                  <PaginationLink
                    next
                    onClick={() => handlePaginationClick(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </>
          <div className="all-subjects mt-4">
            <h3 className="text-start fw-bold">
              Access all questions by Subject
            </h3>
            <Row className="justify-content-between mt-4">
              <Col xl={3} lg={6} md={6} xs={6} className="mb-3">
                <Card
                  className={`library-top-card ${subjectId === "" ? "subject-active" : ""
                    }`}
                  onClick={(e) => setSubjectId("")}
                >
                  <img
                    src={library}
                    alt=""
                    style={{ width: "70px", height: "70px" }}
                  />
                  <h4 className="m-0 pt-3">All Subjects</h4>
                </Card>
              </Col>
              {formData?.map((subject, index) => (
                <Col xl={3} lg={6} md={6} xs={6} className="mb-3" key={index}>
                  <Card
                    className={`library-top-card ${subject._id === subjectId ? "subject-active" : ""
                      }`}
                    onClick={(e) => {
                      setSubjectId(subject._id);
                      scrollToTop(); // Scroll to top when a subject is clicked
                    }}
                  >
                    <img
                      src={`${baseURL}${subject.image}`}
                      alt=""
                      style={{ width: "70px", height: "70px" }}
                    />
                    <h4 className="m-0 pt-3">{subject.questionSubject}</h4>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <div className="search-width">
          <div className="mt-4 search-sidebar">
            <div className="main-class">
              <div className="logo mt-4 pt-4">
                <img src={logo} alt="" />
              </div>
              <div className="side-cont mt-4">
                <p className="side-head">
                  NEVER PAY EXTRA FOR YOUR ASSIGNMENTS
                </p>
              </div>
              <div className="side_24*7">
                <img src={Group} alt="" />
              </div>
              <div className="side-help">
                <span>
                  ARE YOU <span className="looking">LOOKING</span> FOR{" "}
                </span>
                <br />
                <span>
                  ASSIGNMENT <span className="help">HELP?</span>
                </span>
              </div>
              <div className="ass-button">
                <button
                  className="do-my-ass"
                  // onClick={() => navigate("/dashboard/home")}
                  onClick={() =>
                    handlePopup("AssignmentHelp", "assignmentHelp", 4)
                  }
                >
                  Do my Assignment
                </button>
              </div>
            </div>
          </div>
          <div className="mt-4 search-sidebar">
            <div className="main-class Layer">
              <div className="d-flex">
                <div className="Layer">
                  <img src={Layer} alt="" />
                </div>
                <div>
                  <p className="side-title">Ask an expert</p>
                  <strong className="side-subtitle">
                    Get a step-by-step solution in <br />{" "}
                    <span className="side-subtitle">
                      as little as 15 minutes!*
                    </span>
                  </strong>
                </div>
              </div>
              <div className="ass-button Layer-btn">
                <button
                  className="do-my-ass"
                  onClick={() => handlePopup("HomeworkHelp", "mcqHelp", 3)}
                >
                  Ask a Question
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={serviceType}
        />
      ) : (
        <></>
      )}
      <Footer />
      <ScrollTop />
    </>
  );
};

export default Search;
