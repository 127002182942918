import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Button from "../../Components/Buttons/Button";
import {
  orderHistoryForAssignmentHelp,
  orderHistoryForLiveSessionHelp,
} from "../../services/orderHistory";
import { dateFormat } from "./dateFormat";

const DashboardOrderHistory = () => {
  const [pageData, setPageData] = useState([]);

  const [paymentSection, setPaymentSection] = useState("AssignmentHelp");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Unpaid");
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      let data;
      if (paymentSection === "AssignmentHelp") {
        data = await orderHistoryForAssignmentHelp("", currentPage, 10, status);
      } else {
        data = await orderHistoryForLiveSessionHelp(
          "",
          currentPage,
          10,
          status
        );
      }
      setPageData(data?.data || []);
      setTotalPages(data?.totalPages || 1);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [paymentSection, currentPage, status]);

  const handleSectionClick = (section) => {
    setPaymentSection(section);
    setCurrentPage(1);
  };

  const handlePaginationClick = (page) => {
    setCurrentPage(page);
  };

  // const formatDate = (dateString) => {
  //   const options = { month: "short", day: "numeric", year: "numeric" };
  //   return new Date(dateString).toLocaleDateString("en-US", options);
  // };

  const handleViewButtonClick = (order) => {
    localStorage.setItem("serviceType", order.paymentSection);
    if (order.studentPaymentStatus === "Unpaid") {
      navigate(`/orderStatus/${order._id}`);
    } else {
      const route =
        paymentSection === "AssignmentHelp"
          ? `/dashboard/viewanswer/${order._id}`
          : `/dashboard/viewanswer-live/${order._id}`;
      navigate(route, {
        state: order,
      });
    }
  };

  const formatTime = (time) => {
    return time < 10 ? "0" + time : time;
  };

  const calculateRemainingTime = (deadlineDate) => {
    const now = new Date().getTime();
    const difference = deadlineDate - now;
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      hours,
      minutes,
      seconds,
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedPageData = pageData.map((order) => {
        const remainingTime = calculateRemainingTime(
          new Date(order.deadlineDate).getTime()
        );
        return { ...order, remainingTime };
      });
      setPageData(updatedPageData);
    }, 1000);

    return () => clearInterval(interval);
  }, [pageData]);

  return (
    <div>
      <Container className="py-5 w-85 dashboard dash-mobile">
        <Row>
          <Col md="6">
            <Button
              className={`mt-3 w-100 order-btn ${paymentSection === "AssignmentHelp" ? "actives" : ""
                }`}
              text={"Assignment Help"}
              onClick={() => handleSectionClick("AssignmentHelp")}
              varient={
                paymentSection === "AssignmentHelp"
                  ? "linear-gradient-primary"
                  : ""
              }
            ></Button>
          </Col>
          <Col md="6">
            <Button
              className={`mt-3 w-100 order-btn ${paymentSection === "LiveSessionHelp" ? "actives" : ""
                }`}
              text={"Live Session Help"}
              onClick={() => handleSectionClick("LiveSessionHelp")}
              varient={
                paymentSection === "LiveSessionHelp"
                  ? "linear-gradient-primary"
                  : ""
              }
            ></Button>
          </Col>
        </Row>

        <Row className="pt-5 mb-4">
          <Col md="6">
            <h1 className="text-start mb-5">Order History</h1>
          </Col>
          <Col md="6" className="text-end">
            <span
              className={`payment-status paid ${status === "Unpaid" ? "active-pay" : ""
                }`}
              onClick={() => setStatus("Unpaid")}
            >
              Unpaid
            </span>
            <span
              className={`payment-status unpaid ${status === "Paid" ? "active-pay" : ""
                }`}
              onClick={() => setStatus("Paid")}
            >
              Paid
            </span>
          </Col>
        </Row>

        {loading ? (
          <h2 className="">Loading...</h2>
        ) : pageData.length > 0 ? (
          pageData.map((order, index) => (
            <Row key={index} className="pt-3">
              <Col md="12" className="w-100">
                <Card className="history-card p-0">
                  <div
                    className="card-hed w-100 d-xl-flex justify-content-between rounded-top-4 px-4 py-4"
                    style={{ backgroundColor: "#D5D9F9" }}
                  >
                    <div className="text-start">
                      <h4>Order Id - {order.displayId} </h4>
                    </div>
                    {order.questionStatus === "Solved" ? (
                      <h4 style={{ color: '#25a53b' }}>Solved</h4>
                    ) : (
                      <div className="d-flex">
                        <h4 className="mx-2">Hrs Left - </h4>
                        <h4>
                          {order.remainingTime
                            ? formatTime(order.remainingTime.hours)
                            : "00"}
                        </h4>
                        <h4 className="mx-2">:</h4>
                        <h4>
                          {order.remainingTime
                            ? formatTime(order.remainingTime.minutes)
                            : "00"}
                        </h4>
                        <h4 className="mx-2">:</h4>
                        <h4>
                          {order.remainingTime
                            ? formatTime(order.remainingTime.seconds)
                            : "00"}
                        </h4>
                      </div>
                    )}

                  </div>
                  <div
                    className="justify-content-between w-100 px-5 py-3"
                    style={{ zIndex: 11111 }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="text-start mb-3 mb-md-0">
                          <h4>Service type</h4>
                          <h5>{order?.subCategoryId?.name}</h5>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="text-start mb-3 mb-md-0">
                          <h4>Amount</h4>
                          <h5>${order?.totalPrice?.toFixed(2)}</h5>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="text-start mb-3 mb-md-0">
                          <h4>Order Date</h4>
                          <h5>{dateFormat(order.updatedAt)}</h5>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="text-end justify-content-between gap-1">
                          {/* <Button
                            varient="outline-secondary"
                            className="p-2 px-4 cancel-order"
                            text={"Cancel"}
                          ></Button> */}
                          <Button
                            onClick={() => handleViewButtonClick(order)}
                            varient="linear-gradient-primary"
                            className="p-2 px-4"
                            text={"View"}
                          ></Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          ))
        ) : (
          <h2 className="">No data found.</h2>
        )}

        <Row className="pt-3 float-end">
          <Col className="text-center">
            <Pagination className="mt-4">
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink
                  previous
                  onClick={() => handlePaginationClick(currentPage - 1)}
                />
              </PaginationItem>
              {Array.from({ length: totalPages || 0 }, (_, i) => (
                <PaginationItem key={i + 1} active={i + 1 === currentPage}>
                  <PaginationLink onClick={() => handlePaginationClick(i + 1)}>
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage === (totalPages || 1)}>
                <PaginationLink
                  next
                  onClick={() => handlePaginationClick(currentPage + 1)}
                />
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>

      </Container>
    </div>
  );
};

export default DashboardOrderHistory;
