import React from "react";
import { Container, FormGroup, Input, Row } from "reactstrap";
import Select from "react-select";

const HomeWorkForm2 = ({
  texteditorRef,
  payload,
  modules,
  formats,
  handlePaste,
  handleChange,
  formData,
  error,
  isError,
}) => {
  const subjectOptions = formData?.subject?.map((data) => ({
    value: data._id,
    label: data.questionSubject,
  }));

  const categoryOptions = formData?.subcategory?.map((data) => ({
    value: data._id,
    label: data.name,
  }));
  return (
    <Container>
      <h1 className="step-header text-center">Select Your Subject</h1>
      <Row>
        <FormGroup>
          {/* <Input
            type="select"
            name="subject"
            value={payload.subject}
            className="question-select  fs-2"
            onChange={handleChange}
          >
            <option value="" disabled selected>
              Select your subject
            </option>
            {formData.subject.map((sub) => {
              return (
                <option key={sub._id} value={sub._id}>
                  {sub.questionSubject}
                </option>
              );
            })}
          </Input> */}
          <Select
            placeholder="Select your subject"
            value={subjectOptions?.find((x) => x.value === payload?.subject)}
            onChange={(event) =>
              handleChange({
                target: {
                  name: "subject",
                  value: event.value,
                },
              })
            }
            name="subject"
            className="question-select mt-5 select-subject"
            options={subjectOptions}
          />
          {isError && error.subject && (
            <span className="text-danger">{error.subject}</span>
          )}
        </FormGroup>
        <FormGroup>
          {/* <Input
            type="select"
            name="questionType"
            className="type-select fs-2"
            value={payload.questionType}
            onChange={handleChange}
          >
            <option value="" disabled selected>
              Select your question type
            </option>
            {formData.subcategory.map((sub) => {
              return (
                <option key={sub._id} value={sub._id}>
                  {sub.name}
                </option>
              );
            })}
          </Input> */}
          <Select
            placeholder="Select your question type"
            value={categoryOptions.find(
              (x) => x.value === payload?.questionType
            )}
            onChange={(event) =>
              handleChange({
                target: {
                  name: "questionType",
                  value: event.value,
                },
              })
            }
            name="questionType"
            className="question-select mt-1 select-subject"
            options={categoryOptions}
          />
          {isError && error.questionType && (
            <span className="text-danger">{error.questionType}</span>
          )}
        </FormGroup>
      </Row>
    </Container>
  );
};

export default HomeWorkForm2;
