import React, { useContext, useEffect, useState } from "react";
import MainBanner from "../../Components/MainBanner";
import AskSection from "../../Components/AskSection";
import HelpSection from "../../Components/HelpSection";
import AssignmentSection from "../../Components/AssignmentSection";
import LiveHelpSection from "../../Components/LiveHelpSection";
import BonusOffersSection from "../../Components/BonusOffersSection";
import HelpSteps from "../../Components/HelpSteps";
import BenefitsSection from "../../Components/BenefitsSection";
import { getSubcategoryWiseContent } from "../../services/getSubcategoryWiseContent";
import BonusSection from "../../Components/BonusSection";
import BecomeExpert from "../../Components/BecomeExpert";
// import DownloadVaidikApp from "../../Components/DownloadVaidikApp";
import WorldWide from "../../Components/WorldWide";
import ReadMoreSection from "../../Components/ReadMoreSection";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { CategoryContext } from "../../categoryContext";
import CommonForm from "./Form/CommonForm";
import ScrollTop from "../../Components/Buttons/ScrollTop";

const HomePage = () => {
  const { categoryId } = useContext(CategoryContext);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [typeData, setTypeData] = useState("");
  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  return (
    <>
      <Header />
      <MainBanner id={categoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection />
      <HelpSection setIsModelOpen={setIsModelOpen} setTypeData={setTypeData} />
      <AssignmentSection
        setIsModelOpen={setIsModelOpen}
        setTypeData={setTypeData}
      />
      <LiveHelpSection
        setIsModelOpen={setIsModelOpen}
        setTypeData={setTypeData}
      />
      <BonusOffersSection />
      <HelpSteps subCategoryId={categoryId} />
      <BonusSection subCategoryId={categoryId} />
      <BenefitsSection subCategoryId={categoryId} />
      {/* <BecomeExpert /> */}
      {/* <DownloadVaidikApp /> */}
      <WorldWide />
      <ReadMoreSection />
      <Footer />
      {isModelOpen ? (
        <CommonForm isOpen={isModelOpen} toggle={toggle} typeData={typeData} />
      ) : (
        <></>
      )}
      <ScrollTop />
    </>
  );
};

export default HomePage;
