import React, { useEffect, useState } from "react";
import "./AssignmentSection.scss";
import Button from "../Components/Buttons/Button";
import CategoryCard from "./Card/CategoryCard";
import { getCategoryWiseSubcategory } from "../services/getCategoryWiseSubcategory";

const arr = [
  {
    name: "Article-Writing",
    description:
      "With VAIDIK's 100% unique, accurate, and around-the-clock homework assistance, you can meet even the most stringent of deadlines with ease.",
  },
  {
    name: "Assignment-Writing",
    description:
      "Get the correct response every time with VAIDIK's aid that is guaranteed to be 100% unique, accurate, and true false.",
  },
  {
    name: "Essay-Writing",
    description:
      "Put away your patience and allow the professionals create fill-ins for you that are exact, well-structured, and completely correct.",
  },
  {
    name: "Presentation-Writing",
    description:
      "If you want superior scores as well as acclaim, getting excellent and comprehensive matching from VAIDIK Matching Help is the way to go.",
  },
  {
    name: "Project-Lab-Report-Writing",
    description:
      "With assistance from VAIDIK's compelling and effective Definitions, you may captivate your audience and get A+ marks in the process.",
  },
  {
    name: "Speech-Writing",
    description:
      "With assistance from VAIDIK's effective and compelling short response writing service, you can easily win over your audience and get A+ scores.",
  },
];

const AssignmentSection = ({ setTypeData, setIsModelOpen }) => {
  const [pageData, setPageData] = useState([]);
  const fetchData = async () => {
    await getCategoryWiseSubcategory("65524e633c005ef3b51907c9")
      .then((data) => {
        setPageData(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="background-shadow assignmentsection">
      <div className="container">
      <div className="title">Assignment Help</div>
      <div className="description">
        Variety of Assignment call for variety of Assignment solutions.
      </div>
      <div className="card-wrapper">
        {pageData?.subCategory?.map((item, index) => {
          return <CategoryCard key={index} details={item} />;
        })}
      </div>
      <Button
        text="Place Your Assignment Help"
        varient="primary"
        onClick={() => {
          setTypeData({
            serviceType: "AssignmentHelp",
            type: "assignmentHelp",
            totalSteps: 4,
          });
          setIsModelOpen(true);
        }}
      ></Button>
    </div>
    </div>
  );
};

export default AssignmentSection;
